
const env = {
    ENVIRONMENT : "staging",

    CAMPAIGN_BASE_URL : "https://staging.app.aryel.io",
    DOUBLE_AUTHENTICATION_LAYER : false,
    LOCAL_STORAGE_KEY : "aryel-cms",
    SESSION_STORAGE_KEY : "aryel-cms",

    API_PROTOCOL : "https",
    API_DOMAIN : "staging.api.aryel.io",
    API_PORT : 443,
    API_BASEPATH : "/v1",

    STRIPE_PUBLIC_KEY : "pk_test_51HOjZ7KHQs18grFT1np2McXLXxA6LBf3YSnE1TafgrHEai0qLw4ITHz6ZSU74uSqqjMMAW5pvYQkBHLXCV3o1xQx00oYgpZeom",

    RECAPTCHA_V3_PUBLIC : "6LfLWUUdAAAAAL0RcM8eKeyhhtvyx4F9NftpJJr_",

    SENTRY_DSN : "https://2193680379d740ba93ceb73ba6d813ce@o1075000.ingest.sentry.io/6075051",

    EMBED_VIEWER_SCRIPT_URL: "https://staging-aryel-assets.ams3.digitaloceanspaces.com/embed-viewer/aryel-loader.js",

    TEMPLATES_CATEGORIES_ICONS_BASE_URL: "https://staging-aryel-assets.ams3.digitaloceanspaces.com/templates/categories",

    CUSTOMERLY_ENABLE: false,
    CUSTOMERLY_KEY: "",
    ADMIN_PANEL_PLUGIN_PRESTASHOP: "https://plugins.aryel.io/prestashop-plugin/v1.0.0/aryel_prestashop_plugin.zip"
};
export default env;
