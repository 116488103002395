import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { ForgotPasswordCardModule } from 'src/app/components/compounds/forgot-password-card/forgot-password-card.module';
import { ForgotUsernameCardModule } from 'src/app/components/compounds/forgot-username-card/forgot-username-card.module';
import { FormFieldModule } from 'src/app/components/form/form-field/form-field.module';
import { InputModule } from 'src/app/components/form/input/input.module';
import { CardModule } from 'src/app/components/layout/card/card.module';
import { LoadingModule } from 'src/app/components/layout/loading/loading.module';
import { RequirementsModule } from 'src/app/components/layout/requirements/requirements.module';
import { ButtonModule } from 'src/app/components/ui/button/button.module';
import { LinkModule } from 'src/app/components/ui/link/link.module';
import { FwComponent } from './fw.component';

@NgModule({
  declarations: [FwComponent],
  imports: [
    CommonModule,
    CardModule,
    LinkModule,
    InputModule,
    ButtonModule,
    LoadingModule,
    RequirementsModule,
    ForgotPasswordCardModule,
    ForgotUsernameCardModule,
    FormFieldModule,
    ReactiveFormsModule,
  ],
  exports: [FwComponent],
})
export class FwModule {}
