import { Component, DestroyRef, OnInit, inject } from '@angular/core';
import { Subscription, interval, of } from 'rxjs';
import { AccountService } from 'src/app/services/account.service';
import { TriggerService } from 'src/app/services/account/trigger.service';
import { ITTargetGeneratorService } from 'src/app/services/image-tracking/it-target-generator.service';
import { LockedFeatureService } from 'src/app/services/locked-feature.service';
import { ModalService } from 'src/app/services/modal.service';
import { ToastService } from 'src/app/services/toast.service';
import { UploadImageTrackingTriggerService } from 'src/app/services/upload/upload-image-tracking-trigger.service';
import { ResourceInterface } from '../../../../types/entities/resource';
import { CompileOutput } from '../../../services/image-tracking/it-target-generator.abstract';
import { concatMap, flatMap, map } from 'rxjs/operators';

@Component({
  selector: 'aryel-it-target-generator-v2',
  templateUrl: './it-target-generator-v2.component.html',
  styleUrls: ['./it-target-generator-v2.component.scss'],
})
export class ItTargetGeneratorV2Component implements OnInit {
  progress: number = 0;
  loading: boolean = false;
  subscription: Subscription = null;
  modalId: string = 'it-target-generator-v2-modal';
  triggerToUpload: CompileOutput[] = [];
  private triggerProcessor = interval(1000);
  private currentTriggerUpload: CompileOutput = null;

  get currentAccount() {
    return this.accountService.currentAccount;
  }

  constructor(
    private iTTargetGeneratorService: ITTargetGeneratorService,
    private uploadImageTrackingTriggerService: UploadImageTrackingTriggerService,
    private triggerService: TriggerService,
    private accountService: AccountService,
    private toastService: ToastService,
    private modalService: ModalService,
    private lockedFeatureService: LockedFeatureService,
  ) {}

  ngOnInit(): void {
    this.subscribe();
    this.startTriggerProcessor();
  }

  private startTriggerProcessor() {
    this.triggerProcessor
      .pipe(
        concatMap(() => {
          if (this.currentTriggerUpload) {
            return of(null);
          }

          if (this.triggerToUpload.length === 0) {
            if (this.progress === 100) {
              this.complete();
            }
            return of(null);
          }

          this.currentTriggerUpload = this.triggerToUpload.shift();
          return this.saveTriggers(this.currentTriggerUpload);
        }),
      )
      .subscribe(() => {});
  }

  private subscribe() {
    this.subscription = this.iTTargetGeneratorService.getProcess('v2').subscribe(
      (process) => {
        if (!process) {
          return;
        }

        this.progress = process.totalProgress;

        if (process.complete) {
          this.triggerToUpload.push(process);
        }
      },
      (err) => {
        this.error('Error on getting process from it target generator service', err);
      },
    );
  }

  private saveTriggers(process: CompileOutput) {
    if (!this.modalService.isOpen(this.modalId)) {
      // Start loading
      this.loading = true;

      // Prepare data to create resource
      const formData = new FormData();
      formData.append('files', process.file);
      formData.append('account', this.currentAccount._id);

      // Create resource
      return this.uploadImageTrackingTriggerService.upload(formData).pipe(
        concatMap((res: { status: string; message: ResourceInterface }) => {
          if (res.status === 'done') {
            // Resource created
            const file = res.message as ResourceInterface;

            // Binding resource to all triggers of campaign
            return this.triggerService
              .imageTrackingResourceBind(
                process.triggers.map((trigger) => trigger.id),
                file._id,
              )
              .pipe(
                map((data) => {
                  this.currentTriggerUpload = null;
                  return data;
                }),
              );
          } else {
            return of(null);
          }
        }),
      );
    }
  }

  private complete() {
    this.toastService.addToast({
      title: 'Triggers processing complete!',
      status: 'success',
    });
    this.stopProcess();
  }

  private error(errorMsg: string, body: any) {
    console.error(errorMsg, body);

    if (body?.status === 403) {
      this.lockedFeatureService.openPreset('enterprise-out-of-storage');
    }
    this.toastService.addToast({
      title: 'Triggers processing failed!',
      text: 'An error occurred while processing your trigger. Try again or reach us in the help center.',
      status: 'error',
    });
    this.stopProcess();
  }

  stopProcess() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }

    this.loading = false;
    this.progress = 0;
    this.iTTargetGeneratorService.getProcess('v2').next(null);
    this.subscribe();
  }

  cancel() {
    this.modalService.openModal(this.modalId);
  }

  confirmCancel() {
    window.location.reload();
  }

  checkStatus() {
    console.log('checkStatus');
  }

  closeModal() {
    this.modalService.closeModal(this.modalId);
    this.checkStatus();
  }
}
