import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ResourceInterface } from '../../types/entities/resource';
import { UserInterface } from '../../types/entities/user';

@Injectable({
  providedIn: 'root',
})
export class IntercomponentCommunicationService {
  private refreshDataSubject = new Subject<{ type: string; profile: UserInterface }>();
  public refreshDataObject = this.refreshDataSubject.asObservable(); // the observable that carries the changes

  constructor() {}

  refreshData(type: 'profile_thumb_update', profile: UserInterface) {
    this.refreshDataSubject.next({ type, profile });
  }
}
