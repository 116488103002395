<div class="bar" [ngClass]="['bar--' + size, 'bar--' + type]">
  <div class="bar__line">
    <div [style.width]="status + '%'" class="bar__completion"></div>
  </div>
  <div
    *ngIf="size === 'default'"
    [style.left]="status + '%'"
    class="bar__status"
  >
    <div class="bar__status-inner">
      <span>{{ status }}</span>
    </div>
  </div>
</div>
