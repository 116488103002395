import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

@Injectable()
export class ArPatternFileService {
  constructor() {}

  toCanvas(patternFile: string): Observable<any> {
    console.assert(false, 'not yet implemented');
    return of(null);
  }

  encodeImage(image: HTMLImageElement) {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');

    canvas.width = 16;
    canvas.height = 16;

    let patternFile: string = '';

    for (let orientation = 0; orientation > -2 * Math.PI; orientation -= Math.PI / 2) {
      context.save();

      context.clearRect(0, 0, canvas.width, canvas.height);
      context.translate(canvas.width / 2, canvas.height / 2);
      context.rotate(orientation);
      context.drawImage(image, -canvas.width / 2, -canvas.height / 2, canvas.width, canvas.height);
      context.restore();

      const imageData = context.getImageData(0, 0, canvas.width, canvas.height);

      // generate the patternFileString for this orientation
      if (orientation !== 0) {
        patternFile += '\n';
      }

      // NOTE bgr order and not rgb!!! so from 2 to 0
      for (let channelOffset = 2; channelOffset >= 0; channelOffset--) {
        for (let y = 0; y < imageData.height; y++) {
          for (let x = 0; x < imageData.width; x++) {
            if (x !== 0) {
              patternFile += ' ';
            }

            const offset = y * imageData.width * 4 + x * 4 + channelOffset;
            const value = imageData.data[offset];

            patternFile += String(value).padStart(3);
          }
          patternFile += '\n';
        }
      }
    }

    return patternFile;
  }

  encodeImageURL(imageURL: string): Observable<string> {
    return new Observable<string>((observer) => {
      const image: HTMLImageElement = new Image();
      image.onload = () => {
        const patternFile: string = this.encodeImage(image);
        observer.next(patternFile);
      };
      image.onerror = (error) => {
        observer.error(error);
      };
      image.src = imageURL;
    });
  }

  triggerDownload(patternFile: string, fileName: string = 'pattern-marker.patt') {
    const domElement = window.document.createElement('a');
    domElement.href = window.URL.createObjectURL(new Blob([patternFile], { type: 'text/plain' }));
    domElement.download = fileName;
    document.body.appendChild(domElement);
    domElement.click();
    document.body.removeChild(domElement);
  }

  buildFullMarker(innerImageURL: string, pattRatio: number, size: number, color: string): Observable<string> {
    const whiteMargin: number = 0.1;
    const blackMargin: number = (1 - 2 * whiteMargin) * ((1 - pattRatio) / 2);

    const innerMargin: number = whiteMargin + blackMargin;

    const canvas: HTMLCanvasElement = document.createElement('canvas');
    const context: CanvasRenderingContext2D = canvas.getContext('2d');
    canvas.width = canvas.height = size;

    context.fillStyle = 'white';
    context.fillRect(0, 0, canvas.width, canvas.height);

    context.fillStyle = color;
    context.fillRect(
      whiteMargin * canvas.width,
      whiteMargin * canvas.height,
      canvas.width * (1 - 2 * whiteMargin),
      canvas.height * (1 - 2 * whiteMargin)
    );

    context.fillStyle = 'white';
    context.fillRect(
      innerMargin * canvas.width,
      innerMargin * canvas.height,
      canvas.width * (1 - 2 * innerMargin),
      canvas.height * (1 - 2 * innerMargin)
    );

    return new Observable<string>((observer) => {
      const innerImage: HTMLImageElement = document.createElement('img');

      innerImage.addEventListener('load', () => {
        // draw innerImage
        context.drawImage(
          innerImage,
          innerMargin * canvas.width,
          innerMargin * canvas.height,
          canvas.width * (1 - 2 * innerMargin),
          canvas.height * (1 - 2 * innerMargin)
        );

        const imageUrl: string = canvas.toDataURL();
        observer.next(imageUrl);
      });

      innerImage.onerror = (error) => {
        observer.error(error);
      };

      innerImage.src = innerImageURL;
    });
  }
}
