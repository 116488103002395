<aryel-page-container [enableHead]="true">
  <ng-container head>
    <div class="grid-x align-middle">
      <div class="cell auto">
        <aryel-breadcrumbs active="Ask for content"></aryel-breadcrumbs>
      </div>
    </div>
  </ng-container>
  <aryel-loading [loading]="loading">
    <form [formGroup]="form" (submit)="send()">
      <aryel-card size="square">
        <p class="small weight-500 text-center">
          Upload your product photos here
        </p>
        <div class="grid-x grid-margin-x">
          <div class="cell large-6">
            <aryel-file-chooser
              accept=".jpg, .jpeg, .png"
              [sizeLimit]="1024 * 1024 * 2"
              sizeLimitLabel="2MB per image"
              (onSelect)="onImageSelect($event)"
            >
            </aryel-file-chooser>
            <ng-container *ngIf="validate && files.length < minImages">
              <div class="spacer spacer--v spacer--small"></div>
              <small
                class="ask__upl-error input__error color-error weight-700 margin margin--none"
              >
                At least {{ minImages }}
                {{ minImages === 1 ? 'image is' : 'images are' }} required
              </small>
            </ng-container>
          </div>
          <div class="cell large-6">
            <div class="grid-x grid-margin-x ask__tips">
              <div class="cell large-6 ask__tip" *ngFor="let tip of imageTips">
                <p class="small">
                  <ng-container
                    [ngTemplateOutlet]="checkTemplate"
                  ></ng-container>
                  {{ tip }}
                </p>
              </div>
            </div>
            <ng-container *ngIf="images.length">
              <div class="grid-x grid-margin-x not-selectable">
                <div
                  class="cell large-4"
                  *ngFor="let image of images; let index = index"
                >
                  <aryel-tooltip [text]="files[index].name">
                    <div
                      class="ask__image"
                      [style.background-image]="'url(' + image + ')'"
                    >
                      <div
                        class="ask__remove"
                        (click)="
                          images.splice(index, 1); files.splice(index, 1)
                        "
                      >
                        <span class="material-symbols-outlined"> close </span>
                      </div>
                    </div>
                  </aryel-tooltip>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </aryel-card>
      <aryel-card size="square">
        <p class="small weight-500 text-center margin margin--none">
          Describe in a few words your needs
        </p>
        <div class="spacer spacer--v spacer--small"></div>
        <p class="small text-center">
          Please provide all important details and be as precise as possible.
        </p>
        <div class="grid-x grid-margin-x">
          <div class="cell large-6">
            <aryel-form-field
              placeholder="Message"
              [errors]="form.controls.message.errors"
              [validate]="validate"
            >
              <textarea
                aryelFormField
                [formControl]="form.controls.message"
              ></textarea>
            </aryel-form-field>
          </div>
          <div class="cell large-6">
            <div class="grid-x grid-margin-x">
              <div
                class="cell large-6 ask__tip"
                *ngFor="let tip of messageTips"
              >
                <p class="small">
                  <ng-container
                    [ngTemplateOutlet]="checkTemplate"
                  ></ng-container>
                  {{ tip }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </aryel-card>
      <aryel-card size="square">
        <p class="small weight-500 text-center">
          Now, when do you need your custom model?
        </p>
        <div class="grid-x align-center">
          <div class="cell large-4">
            <aryel-datepicker
              [(model)]="date"
              (modelChange)="form.controls.date.setValue($event)"
              [min]="minDate"
              [validate]="validate"
              [errors]="form.controls.date.errors"
              placeholder="Choose a date"
            ></aryel-datepicker>
          </div>
        </div>
        <div class="spacer spacer--v spacer--mlarge"></div>
        <div class="grid-x align-center">
          <div class="cell large-6">
            <p
              class="color-gray small weight-500 ask__text text-center margin margin--none"
            >
              The price depends on the complexity of the 3D model and the
              urgency you have. We’ll take your request into evaluation and
              inform you ASAP with our best quotation.
            </p>
          </div>
        </div>
        <div class="spacer spacer--v spacer--xlarge"></div>
        <div class="text-center">
          <aryel-button (click)="send()"> Submit </aryel-button>
        </div>
      </aryel-card>
    </form>
  </aryel-loading>
</aryel-page-container>

<ng-template #checkTemplate>
  <svg
    width="15px"
    height="12px"
    viewBox="0 0 15 12"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <title></title>
    <g
      id="SaaS-/-Base"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g
        id="Ask-a-content"
        transform="translate(-317.000000, -430.000000)"
        fill="#0855ff"
        fill-rule="nonzero"
        stroke="#0855ff"
      >
        <g id="Group" transform="translate(318.000000, 431.000000)">
          <path
            d="M3.37706342,10 C3.56907037,10 3.72719374,9.93442623 3.85143354,9.80327869 L3.85143354,9.80327869 L3.88531712,9.7704918 L12.7966985,1.1147541 C12.9322328,0.994535519 13,0.841530055 13,0.655737705 C13,0.469945355 12.9350565,0.31420765 12.8051694,0.18852459 C12.6752824,0.0628415301 12.5143354,0 12.3223284,0 C12.1303215,0 11.9721981,0.0655737705 11.8479583,0.196721311 L11.8479583,0.196721311 L3.52953953,8.26229508 L1.27628149,4.44262295 C1.17463076,4.28961749 1.03344917,4.18852459 0.852736751,4.13934426 C0.672024327,4.09016393 0.499782798,4.10928962 0.336012163,4.19672131 C0.172241529,4.28415301 0.067767159,4.41530055 0.022589053,4.59016393 C-0.022589053,4.76502732 0,4.93442623 0.090356212,5.09836066 L0.090356212,5.09836066 L2.78410078,9.6557377 C2.89704605,9.85245902 3.06646394,9.96174863 3.29235447,9.98360656 C3.31494353,9.99453552 3.34317984,10 3.37706342,10 Z"
            id=""
          ></path>
        </g>
      </g>
    </g>
  </svg>
</ng-template>
