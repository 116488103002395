import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { Injector, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { UIRouter, UIRouterModule } from '@uirouter/angular';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { environment } from 'src/environments/environment';
import { AuthInterceptor } from 'src/interceptors/auth/auth-interceptor';
import { AUTH_INTERCEPTOR_OPTIONS } from 'src/interceptors/auth/auth-interceptor-options';
import { AppComponent } from './app.component';
import { ArPatternFileService } from './components/compounds/marker-generator-manager/ar-pattern-file.service';
import { ToastsContainerModule } from './components/layout/toasts-container/toasts-container.module';
import { AccountStateRouteGuard } from './guards/account-state';
import { CanResetPasswordRouteGuard } from './guards/can-reset-password';
import { HasDebtsRouteGuard } from './guards/has-debts';
import { HasFeatureRouteGuard } from './guards/has-feature-guard';
import { IsGuestRouteGuard } from './guards/is-guest';
import { IsLoggedInRouteGuard } from './guards/is-logged-in';
import { IsRoleRouteGuard } from './guards/is-role-guard';
import { PermissionsRouteGuard } from './guards/permissions-guard';
import { FwModule } from './pages/fw/fw.module';
import { JoinComponent } from './pages/join/join.component';
import { JoinModule } from './pages/join/join.module';
import { PrivateModule } from './pages/private/private.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    CommonModule,
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    UIRouterModule.forRoot({
      states: [
        {
          name: 'auth.**',
          url: '/auth',
          loadChildren: () => import('./pages/auth/auth.module').then((mod) => mod.AuthModule),
        },
        {
          name: 'backoffice.**',
          url: '/backoffice',
          loadChildren: () => import('./pages/backoffice/backoffice.module').then((mod) => mod.BackofficeModule),
        },
        {
          name: 'join',
          url: '/join',
          redirectTo: 'auth.sign-in',
          component: JoinComponent,
        },
      ],
      otherwise: {
        state: 'auth.sign-in',
      },
      config: function (router: UIRouter, _: Injector) {
        const isLogged = new IsLoggedInRouteGuard();
        isLogged.applyWith(router.transitionService);

        const isGuest = new IsGuestRouteGuard();
        isGuest.applyWith(router.transitionService);

        const accountState = new AccountStateRouteGuard();
        accountState.applyWith(router.transitionService);

        const canResetPassword = new CanResetPasswordRouteGuard();
        canResetPassword.applyWith(router.transitionService);

        const isRole = new IsRoleRouteGuard();
        isRole.applyWith(router.transitionService);

        const permissionsRouteGuard = new PermissionsRouteGuard();
        permissionsRouteGuard.applyWith(router.transitionService);

        const hasFeature = new HasFeatureRouteGuard();
        hasFeature.applyWith(router.transitionService);

        const hasDebts = new HasDebtsRouteGuard();
        hasDebts.applyWith(router.transitionService);
      },
    }),
    RecaptchaV3Module,
    PrivateModule,
    JoinModule,
    ToastsContainerModule,
  ].concat(environment.doubleAuthenticationLayer ? [FwModule] : []),
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: AUTH_INTERCEPTOR_OPTIONS,
      useValue: {
        whitelist: [],
        blacklist: [],
      },
    },
    { provide: Window, useValue: window },
    ArPatternFileService,
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: environment.recaptcha.v3,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
