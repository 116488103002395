import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LoadingModule } from '../../layout/loading/loading.module';
import { ModalModule } from '../../layout/modal/modal.module';
import { ButtonModule } from '../../ui/button/button.module';
import { LinkModule } from '../../ui/link/link.module';
import { TermsAndConditionsAcceptanceModalComponent } from './terms-and-conditions-acceptance-modal.component';

@NgModule({
  declarations: [TermsAndConditionsAcceptanceModalComponent],
  imports: [CommonModule, ModalModule, ButtonModule, LinkModule, LoadingModule],
  exports: [TermsAndConditionsAcceptanceModalComponent],
})
export class TermsAndConditionsAcceptanceModalModule {}
