import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UIRouterModule } from '@uirouter/angular';
import { ButtonModule } from '../../ui/button/button.module';
import { LinkModule } from '../../ui/link/link.module';
import { LockedFeatureModalComponent } from './locked-feature-modal.component';

@NgModule({
  declarations: [LockedFeatureModalComponent],
  imports: [CommonModule, ButtonModule, UIRouterModule, LinkModule],
  exports: [LockedFeatureModalComponent],
})
export class LockedFeatureModalModule {}
