import { animate, style, transition, trigger } from '@angular/animations';
import { Component, DestroyRef, EventEmitter, Input, OnDestroy, OnInit, Output, inject } from '@angular/core';
import { ModalService } from 'src/app/services/modal.service';

@Component({
  selector: 'aryel-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  animations: [
    trigger('overlay', [
      transition(':enter', [style({ opacity: 0 }), animate('.200s ease-in-out', style({ opacity: 1 }))]),
      transition(':leave', [style({ opacity: 1 }), animate('.0s', style({ opacity: 0 }))]),
    ]),
    trigger('content', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(100px)' }),
        animate('.200s ease-in-out', style({ opacity: 1, transform: 'translateY(0)' })),
      ]),
      transition(':leave', [
        style({ opacity: 1, transform: 'translateY(0)' }),
        animate('0s', style({ opacity: 0, transform: 'translateY(100px)' })),
      ]),
    ]),
  ],
})
export class ModalComponent implements OnInit, OnDestroy {
  @Input() id: string;
  @Input() heading: string = '';
  @Input() size: 'small' | 'mlarge' | 'medium' | 'large' | 'xlarge' | 's' | 'm' | 'l' | 'xl' = 'medium';
  @Input() padding: 'small' | 'default' | 'none' = 'default';
  @Input() headingAlign: 'left' | 'center' | 'none' = 'center';
  @Input() bodyAlign: 'left' | 'center' | 'none' = 'left';
  @Input() accent: 'default-accent' | 'dark' = 'default-accent';
  @Input() width: number = null;
  @Input() disableClose: boolean;
  @Input() disableHeading: boolean = false;
  @Input() type: 'modal' | 'sheet' | 'full' = 'modal';
  @Input() closerColor: 'default' | 'gray' = 'default';
  @Input() animations: boolean = true;
  @Output() modalClose: EventEmitter<void> = new EventEmitter();
  @Output() modalOpen: EventEmitter<void> = new EventEmitter();

  _isOpen: boolean = false;

  isAnimating: boolean = false;

  get isOpen() {
    if (!this._isOpen && this.modalService.isOpen(this.id)) {
      this._isOpen = true;
      this.modalOpen.emit();
    }
    if (this._isOpen && !this.modalService.isOpen(this.id)) {
      this._isOpen = false;
    }
    return this.modalService.isOpen(this.id);
  }

  get isPersistent() {
    return this.modalService.isPersistent(this.id);
  }

  get isClosedDisabled() {
    return this.modalService.isClosedDisabled(this.id) || this.disableClose;
  }

  get isLoading() {
    return this.modalService.isLoading(this.id);
  }

  get isExpanded() {
    return this.modalService.isExpanded(this.id);
  }

  get contentClasses() {
    let classes = `modal__content--${this.size} modal__content--padding-${this.padding} modal__content--${this.type} modal__content--${this.accent}`;
    if (this.type === 'sheet') {
      classes += ' scrollbar scrollbar--v';
    }
    if (this.isExpanded) {
      classes += ' modal__content--expand';
    }
    return classes;
  }

  get contentStyles() {
    const styles: { [x: string]: string } = {};
    if (this.width) {
      styles['max-width'] = `${this.width}rem`;
    }
    return styles;
  }

  private destroyRef = inject(DestroyRef);

  constructor(private modalService: ModalService) {}

  ngOnInit(): void {
    this.modalService.registerModal(this.id);
  }

  ngOnDestroy(): void {
    this.modalService.unregisterModal(this.id);
  }

  close() {
    this.modalService.closeModal(this.id);
    this.modalClose.emit();
  }
}
