import { animate, style, transition, trigger } from '@angular/animations';
import {
  AfterContentInit,
  Component,
  DestroyRef,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
  inject,
} from '@angular/core';
import { StateService, TransitionService } from '@uirouter/core';
import { SubMenuInterface } from 'src/types/components/layout/submenu';

@Component({
  selector: 'aryel-submenu[options]',
  templateUrl: './submenu.component.html',
  styleUrls: ['./submenu.component.scss'],
  animations: [
    trigger('openClose', [
      transition(':enter', [style({ opacity: 0 }), animate('.200s ease-out', style({ opacity: 1 }))]),
      transition(':leave', [style({ opacity: 1 }), animate('.200s ease-in', style({ opacity: 0 }))]),
    ]),
  ],
})
export class SubmenuComponent implements OnInit, AfterContentInit {
  @Input() width = 180;
  @Input() height = null;
  @Input() options: SubMenuInterface[] = [];
  @Input() secondaryOptions: SubMenuInterface[] = [];
  @Input() footerOptions: SubMenuInterface[] = [];
  @Input() anchor: 'left' | 'center' | 'right' | 'middle' | 'top' | 'top-right' = 'left';
  @Input() large = false;
  @Input() far: boolean;
  @Input() disabled: boolean = false;
  @Output() selection: EventEmitter<string> = new EventEmitter();

  @ViewChild('content', { static: true }) content: ElementRef;
  hasContent = false;

  showSubMenu = false;

  timeout: any;
  canOperate = true;

  private destroyRef = inject(DestroyRef);

  constructor(private transitionService: TransitionService, private stateService: StateService, private _eref: ElementRef) {
    this.transitionService.onStart({}, () => {
      this.showSubMenu = false;
    });
  }

  ngOnInit(): void {}

  ngAfterContentInit() {
    if (this.content.nativeElement.innerHTML || this.content.nativeElement.text) {
      this.hasContent = true;
    }
  }

  async select(option: SubMenuInterface) {
    this.showSubMenu = false;
    if (option.ref) {
      const params = {};
      for (const [key, value] of Object.entries(option.params || {})) {
        if (typeof value === 'function') {
          params[key] = await (value as any)();
        } else {
          params[key] = value;
        }
      }

      this.stateService.go(option.ref, params);
    } else {
      this.selection.emit(option.value || '');
    }
  }

  subMenuTrigger() {
    if (this.canOperate && !this.disabled) {
      this.showSubMenu = !this.showSubMenu;
      clearTimeout(this.timeout);
      this.canOperate = false;
      this.timeout = setTimeout(() => {
        this.canOperate = true;
      }, 600);
    }
  }

  @HostListener('document:click', ['$event'])
  handleKeyDown(event: MouseEvent) {
    if (this.showSubMenu && !this._eref.nativeElement.contains(event.target)) {
      this.showSubMenu = false;
    }
  }
}
