import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UIRouterModule } from '@uirouter/angular';
import { ButtonModule } from '../../ui/button/button.module';
import { ToastComponent } from './toast/toast.component';
import { ToastsContainerComponent } from './toasts-container.component';

@NgModule({
  declarations: [ToastsContainerComponent, ToastComponent],
  imports: [CommonModule, ButtonModule, UIRouterModule],
  exports: [ToastsContainerComponent, ToastComponent],
})
export class ToastsContainerModule {}
