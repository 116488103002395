import { animate, style, transition, trigger } from '@angular/animations';
import { Component, DestroyRef, Input, OnDestroy, OnInit, inject } from '@angular/core';

@Component({
  selector: 'aryel-loading[loading]',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
  animations: [
    trigger('showHide', [
      transition(':enter', [style({ opacity: 0 }), animate('.200s ease-out', style({ opacity: 1 }))]),
      transition(':leave', [style({ opacity: 1 }), animate('.200s ease-in', style({ opacity: 0 }))]),
    ]),
  ],
})
export class LoadingComponent implements OnInit, OnDestroy {
  @Input() position: 'absolute' | 'inline' | 'fixed' = 'absolute';
  @Input() slow: boolean = false;
  @Input() dark: boolean = false;
  @Input() size: 'default' | 'small' = 'default';

  _loading: boolean;
  get loading(): boolean {
    return this._loading;
  }
  @Input() set loading(value: boolean) {
    setTimeout(() => {
      if (value && value !== this._loading && this.slow) {
        this.queueMessage1();
        this.queueMessage2();
      } else if (!value) {
        this.clearQueues();
      }

      this._loading = value;
    });
  }

  timeout1: any;
  timeout2: any;
  message: { title: string; description: string } = null;

  private destroyRef = inject(DestroyRef);

  constructor() {}

  ngOnInit(): void {}

  queueMessage1() {
    this.timeout1 = setTimeout(() => {
      this.message = {
        title: "Ops, it's taking a while!",
        description: 'We’re taking care of this, keep this window open and feel free to have a coffee. ☕',
      };
    }, 5000);
  }

  queueMessage2() {
    this.timeout2 = setTimeout(() => {
      this.message = {
        title: 'Are you still there?',
        description: 'The process is taking longer than usual; we are on it! 💪🏼',
      };
    }, 20000);
  }

  clearQueues() {
    this.message = null;

    clearTimeout(this.timeout1);
    clearTimeout(this.timeout2);
  }

  ngOnDestroy(): void {
    this.clearQueues();
  }
}
