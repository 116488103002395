import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export type ThemeType = 'color' | 'dark';

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  private renderer: Renderer2;
  private themeStorage = 'aryel-theme';

  private themeClasses = {
    color: 'color-mode',
    dark: 'dark-mode',
  };

  private activeMode: BehaviorSubject<ThemeType> = new BehaviorSubject('color');

  constructor(private rendererFactory: RendererFactory2) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
    const mode: any = localStorage.getItem(this.themeStorage);
    this.setMode(mode || 'color');
  }

  initMode() {
    const darkMode = window.matchMedia('(prefers-color-scheme: dark)');
    if (darkMode) {
      if (localStorage?.getItem(this.themeStorage) && this.themeClasses[localStorage.getItem(this.themeStorage)]) {
        this.renderer.addClass(document.body, this.themeClasses[localStorage.getItem(this.themeStorage)]);
        this.activeMode.next(localStorage.getItem(this.themeStorage) as ThemeType);
      } else {
        if (window.matchMedia && darkMode.matches) {
          this.renderer.addClass(document.body, this.themeClasses['dark']);
          this.activeMode.next('dark');
        }
      }
    }
  }

  setMode(mode: ThemeType) {
    if (this.activeMode.value) {
      this.renderer.removeClass(document.body, this.themeClasses[this.activeMode.value]);
    }
    this.activeMode.next(mode);
    this.renderer.addClass(document.body, this.themeClasses[mode]);
    this.renderer.addClass(document.body, 'changing-theme');
    localStorage.setItem(this.themeStorage, mode);
    setTimeout(() => {
      this.renderer.removeClass(document.body, 'changing-theme');
    }, 1000);
  }

  get currentMode() {
    return this.activeMode.value;
  }

  get logo() {
    if (this.activeMode.value === 'color') {
      return '/assets/img/brand/logo.png';
    } else {
      return '/assets/img/brand/logo-white.png';
    }
  }

  get iconLogo() {
    if (this.activeMode.value === 'color') {
      return '/assets/img/brand/icon.png';
    } else {
      return '/assets/img/brand/icon-white.png';
    }
  }
}
