import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import {
  LockedFeatureModalContentInterface,
  LockedFeatureModalPreset,
  LockedFeatureModalType,
} from 'src/types/components/layout/locked-feature-modal';
import { presets } from '../global/paywalls';
import { TrackingService } from './tracking.service';

@Injectable({
  providedIn: 'root',
})
export class LockedFeatureService {
  private _content: BehaviorSubject<LockedFeatureModalContentInterface> = new BehaviorSubject(null);
  private presets: LockedFeatureModalPreset = presets;

  get getContent(): Subject<LockedFeatureModalContentInterface> {
    return this._content;
  }

  get isOpen(): boolean {
    if (!this._content?.value) {
      return false;
    }

    return !!this._content?.value?.left?.title && !!this._content?.value?.left?.text;
  }

  constructor(private trackingService: TrackingService) {}

  open(content: LockedFeatureModalContentInterface) {
    this._content.next(content);
  }

  openPreset(preset: LockedFeatureModalType, override: Partial<LockedFeatureModalContentInterface> = {}) {
    const { left, right, onAccept, onDismiss, redirect } = override;

    const content: LockedFeatureModalContentInterface = {
      ...this.presets[preset],
      type: preset,
      ...(onAccept && { onAccept }),
      ...(onDismiss && { onDismiss }),
      ...(redirect && { redirect }),
    };

    if (left) {
      Object.assign(content.left, left);
    }
    if (right) {
      Object.assign(content.right, right);
    }
    this.trackingService.trackPaywall({ type: content.type, title: content.left.title }).subscribe();
    this.open(content);
  }

  close() {
    this._content.next(null);
  }
}
