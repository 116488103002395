import { Directive, ElementRef, HostBinding } from '@angular/core';

@Directive({
  selector: '[aryelFormField]',
})
export class FormFieldDirective {
  element: ElementRef<HTMLInputElement>;

  checker: any;

  constructor(el: ElementRef) {
    this.element = el;
  }

  @HostBinding('class.not-empty') get notEmpty() {
    return this.element.nativeElement.value;
  }
}
