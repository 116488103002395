import { RouteGuard } from './route-guard';
import { Transition } from '@uirouter/core';
import { MeService } from '../services/me.service';

export class IsGuestRouteGuard extends RouteGuard {
  on: 'onBefore' = 'onBefore';

  hook = {
    to: (state) => {
      return (
        state.name.indexOf('auth') === 0 && state.name !== 'auth.activate' && state.name !== 'auth.activate-with-account'
      );
    },
  };

  options = {
    priority: 19,
  };

  callback = async (transition: Transition) => {
    const meService: MeService = transition.injector().get(MeService);
    const { stateService } = transition.router;

    if (meService.me) {
      return stateService.target('private');
    }
  };
}
