import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { flatMap, tap } from 'rxjs/operators';
import { AryelAccountProvider, AryelAccountProviderStripeInterface } from 'src/modules/type-definitions/account';
import { PaymentProvider } from 'src/modules/type-definitions/generics';
import { SubscriptionInterface } from 'src/types/entities/subscription';
import { AccountService } from '../account.service';
import { ApiService } from '../api/api.service';
import { CustomerlyService } from '../integrations/customerly.service';
import { LocalStorageService } from '../local-storage.service';
import { MeService } from '../me.service';
import { DiscountService } from './discount.service';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionService {
  private _basePath: string = '/accounts/:account/subscriptions';

  get currentSubscription(): SubscriptionInterface {
    return this.localStorageService.getItem('currentSubscription') || null;
  }

  get currentProvider(): AryelAccountProvider {
    if (this.currentSubscription?.provider?.name) {
      return this.accountService.currentAccount.providers.find(
        (provider) => provider.name === this.currentSubscription?.provider.name
      );
    }
    return null;
  }

  get card(): {
    last4: string;
    brand: string;
    payment_method_id?: string;
  } {
    const provider = this.accountService.currentAccount?.providers?.find(
      (provider) => provider.name === 'stripe'
    ) as AryelAccountProviderStripeInterface;
    return provider?.card || null;
  }

  get isDefaultPlan() {
    return this.currentSubscription?.plan?.default || false;
  }

  get planType() {
    return this.currentSubscription?.plan?.type || false;
  }

  private get basePath() {
    return this._basePath.replace(':account', this.accountService.currentAccount._id);
  }

  constructor(
    protected apiService: ApiService,
    protected accountService: AccountService,
    protected discountService: DiscountService,
    private localStorageService: LocalStorageService,
    private customerlyService: CustomerlyService,
    private meService: MeService
  ) {}

  setCurrentSubscription(_id?: string): Observable<SubscriptionInterface> {
    _id = _id || this.accountService.currentAccount.subscription;
    if (!_id) {
      return of(null);
    }

    let subscriptionDocument = null;
    return this.apiService.get(`${this.basePath}/${_id}`).pipe(
      flatMap((sub) => {
        subscriptionDocument = sub;

        return this.plan(sub._id);
      }),
      tap((plan) => {
        subscriptionDocument.plan = plan;
      }),
      flatMap(() => {
        if (!subscriptionDocument.discount) {
          return of(null);
        }

        return this.discountService.get(subscriptionDocument.discount);
      }),
      flatMap((discount) => {
        if (discount) {
          subscriptionDocument.discount = discount;
        }

        return of(null);
      }),
      tap(() => {
        this.localStorageService.setItem('currentSubscription', subscriptionDocument);
      }),
      tap(() => {
        if (this.meService.me) {
          this.customerlyService.updateAccounts(
            this.meService.me,
            this.accountService.currentAccount,
            this.currentSubscription
          );
        }
      }),
      flatMap(() => of(subscriptionDocument))
    );
  }

  refreshCurrentSubscription() {
    if (this.accountService.currentAccount?.subscription) {
      return this.setCurrentSubscription();
    }
    return of(null);
  }

  update(subscription: {
    plan?: string;
    provider?: PaymentProvider;
    coupon?: string;
    subscription?: string;
  }): Observable<SubscriptionInterface> {
    return this.apiService.patch(`${this.basePath}`, subscription);
  }

  create(plan: any, provider?: PaymentProvider): Observable<SubscriptionInterface> {
    const p = provider || plan.providers[0];
    if (!plan.providers.find((prov) => prov === p)) {
      console.warn('Provider unavailable in selected plan');
      return;
    }
    return this.apiService.post(`${this.basePath}`, {
      plan: plan._id,
      provider: p,
    });
  }

  delete() {
    return this.apiService.delete(this.basePath, { params: !this.currentSubscription.until ? { immediately: true } : {} });
  }

  recover() {
    return this.apiService.patch(`${this.basePath}/undo-delete`);
  }

  plan(subscription: string) {
    return this.apiService.get(`${this.basePath}/${subscription}/plan`);
  }
}
