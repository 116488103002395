<aryel-card>
  <ng-container head>
    <div class="text-center">
      <img src="assets/img/brand/logo.png" class="logo" />
    </div>
  </ng-container>
  <h2 class="text-center view__title">Join your team</h2>
  <aryel-loading [loading]="loading">
    <h5 class="text-center">
      You have been invited to join the {{ invitation.companyName }} team on
      Aryel. 💫 Jump aboard and start collaborating on new amazing AR campaigns.
    </h5>
    <aryel-button (click)="submit()" [full]="true"
      >Accept invitation</aryel-button
    >
    <!-- <div class="spacer spacer--v spacer--large"></div>
    <div class="grid-x align-middle align-center grid-margin-x">
      <div class="cell shrink">
        <aryel-link (click)="switch.emit('sign-in')" size="small"
          >Sign in</aryel-link
        >
      </div>
      <div class="cell shrink">
        <aryel-link (click)="switch.emit('sign-up')" size="small"
          >Sign up</aryel-link
        >
      </div>
    </div> -->
  </aryel-loading>
</aryel-card>
