import { Component, DestroyRef, Input, OnInit, inject } from '@angular/core';

@Component({
  selector: 'aryel-badge',
  templateUrl: './badge.component.html',
  styleUrls: ['./badge.component.scss'],
})
export class BadgeComponent implements OnInit {
  @Input() color: 'primary' | 'secondary' | 'blue' | 'success' | 'warning' | 'error' = 'primary';
  @Input() size: 'default' | 'medium' = 'default';
  @Input() disableMargins: boolean;
  @Input() capitalize: boolean = false;

  private destroyRef = inject(DestroyRef);

  constructor() {}

  ngOnInit(): void {}
}
