<div class="view">
  <div class="view__card">
    <ng-container *ngIf="currentStateName === 'join.team'">
      <aryel-join-sign-in
        [user]="user"
        [invitation]="invitation"
        [accountId]="accountId"
        [emailHash]="emailHash"
        [invitationToken]="invitationToken"
        *ngIf="view === 'sign-in'"
        (switch)="view = $event"
      ></aryel-join-sign-in>
      <aryel-join-sign-up
        [user]="user"
        [invitation]="invitation"
        [accountId]="accountId"
        [emailHash]="emailHash"
        [invitationToken]="invitationToken"
        *ngIf="view === 'sign-up'"
        (switch)="view = $event"
      ></aryel-join-sign-up>
      <aryel-join-logged
        [user]="user"
        [invitation]="invitation"
        [accountId]="accountId"
        [invitationToken]="invitationToken"
        [emailHash]="emailHash"
        *ngIf="view === 'logged' && user"
        (switch)="view = $event"
      ></aryel-join-logged>
    </ng-container>
    <ui-view></ui-view>
  </div>
</div>
