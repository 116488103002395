import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MobileLockComponent } from './mobile-lock.component';

@NgModule({
  declarations: [MobileLockComponent],
  imports: [CommonModule],
  exports: [MobileLockComponent],
})
export class MobileLockModule {}
