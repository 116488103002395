import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { InvitationTicketInterface } from 'src/types/entities/account';
import { SignInUserInterface, UserSummaryInterface } from 'src/types/entities/user';
import { ApiService } from './api/api.service';
import { MeService } from './me.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private apiService: ApiService, private meService: MeService) {}

  signinWithActivationTicket(
    activationTicket: string,
    usernameHash: string,
    token: string
  ): Observable<UserSummaryInterface> {
    return this.apiService
      .post('/auth', { activationTicket, hash: usernameHash, recaptcha: token })
      .pipe(tap((user) => this.meService.save(user)));
  }

  signinWithImpersonationToken(token: string): Observable<UserSummaryInterface> {
    return this.apiService.post(`/backoffice/impersonate/${token}`).pipe(
      tap((user) => {
        return this.meService.save(user);
      })
    );
  }

  signin(user: SignInUserInterface, invitationTicket?: InvitationTicketInterface): Observable<UserSummaryInterface> {
    let body: SignInUserInterface & {
      invitationTicket?: InvitationTicketInterface;
    } = user;

    if (invitationTicket) {
      body = {
        ...user,
        invitationTicket,
      };
    }

    return this.apiService.post('/auth', body).pipe(tap((user) => this.meService.save(user)));
  }

  signout() {
    return this.apiService.delete('/auth').pipe(tap(() => this.meService.remove()));
  }

  validateInvitation(accountId: string, emailHash: string, token: string) {
    return this.apiService.get(`/accounts/${accountId}/invitations/${token}/user`);
  }
}
