import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { InvitationTicketInterface } from 'src/types/entities/account';
import { NewUserInterface, UserInterface } from 'src/types/entities/user';
import { ApiService } from './api/api.service';

@Injectable({
  providedIn: 'root',
})
export class SignupService {
  constructor(private apiService: ApiService) {}

  reserve(
    user:
      | (NewUserInterface & { recaptcha: string })
      | (NewUserInterface & { invitationTicket: InvitationTicketInterface; recaptcha: string })
  ): Observable<UserInterface> {
    return this.apiService.post('/users', user);
  }

  activate(
    usernameHash: string,
    emailHash: string,
    token: string,
    invitationTicket: InvitationTicketInterface
  ): Observable<UserInterface> {
    const body = {
      emailHash,
      token,
      invitationTicket: undefined,
    };

    if (invitationTicket) {
      body.invitationTicket = invitationTicket;
    }

    return this.apiService.patch(`/users/${usernameHash}/status`, body);
  }
}
