<div class="view" [hidden]="hide">
  <div class="view__head grid-container grid-container--header">
    <img src="assets/img/brand/logo-white.png" class="logo" alt="Aryel" />
  </div>
  <div class="view__inner">
    <div class="view__content">
      <h1
        *ngIf="me"
        class="title color-white text-center"
        [class.miss-owned]="!accounts.ownedAccount"
      >
        Welcome back{{ me.name ? ', ' + me.name : '' }}! 👋
      </h1>
      <p
        *ngIf="!accounts.ownedAccount"
        class="text-center color-white view__cta"
      >
        <span *ngIf="accounts.managedAccounts?.length" class="color-white">
          Select an account or
        </span>
        <span *ngIf="!accounts.managedAccounts?.length" class="color-white">
          You don't have any account
        </span>
        <aryel-link
          color="white"
          [bold]="true"
          [link]="{ url: 'https://aryel.io/contact/' }"
          >Talk to Sales to create a new one
        </aryel-link>
      </p>
      <div class="spacer spacer--v spacer--xxlarge"></div>
      <div
        class="accounts"
        *ngIf="
          accounts.ownedAccount || accounts.managedAccounts?.length;
          else noAccounts
        "
      >
        <div
          class="accounts__single"
          [class.accounts__single--owned]="accounts.ownedAccount"
          *ngIf="accounts.ownedAccount"
        >
          <aryel-card>
            <div class="text-center accounts__container">
              <img src="assets/img/illustrations/owner-account.png" alt="" />
              <aryel-tooltip
                width="12rem"
                [block]="true"
                [breakLine]="true"
                [text]="
                  accounts.ownedAccount.company?.name
                    ? accounts.ownedAccount.company?.name
                    : accounts.ownedAccount.contact?.surname
                "
                [ngClass]="
                  (accounts.ownedAccount.company?.name
                    ? accounts.ownedAccount.company?.name?.length
                    : accounts.ownedAccount.contact?.surname?.length) > 13
                    ? ' narrow-tooltip'
                    : ' narrow-tooltip invisible-tooltip'
                "
              >
                <h2 class="accounts__name weight-500 margin margin--none">
                  {{
                    accounts.ownedAccount.company?.name
                      ? accounts.ownedAccount.company?.name
                      : accounts.ownedAccount.contact?.surname
                  }}
                </h2>
              </aryel-tooltip>
              <div class="spacer spacer--v spacer--small"></div>
              <div class="accounts__text">
                <span class="material-symbols-outlined"> nest_display </span>
                <p>{{ ROLE_LABELS.owner }}</p>
              </div>
              <aryel-button
                (click)="operateOn(accounts.ownedAccount)"
                size="small"
                >Enter</aryel-button
              >
            </div>
          </aryel-card>
        </div>
        <div
          class="accounts__single"
          *ngFor="let account of accounts.managedAccounts || []"
        >
          <aryel-card>
            <div class="text-center accounts__container">
              <aryel-tooltip
                width="12rem"
                [block]="true"
                [breakLine]="true"
                [text]="account.company.name"
                [ngClass]="
                  account.company.name?.length > 13
                    ? ' narrow-tooltip'
                    : ' narrow-tooltip invisible-tooltip'
                "
              >
                <h2 class="accounts__name weight-500 margin margin--none">
                  {{ account.company.name }}
                </h2>
              </aryel-tooltip>
              <div class="spacer spacer--v spacer--small"></div>
              <div class="accounts__text">
                <span class="material-symbols-outlined"> groups </span>
                <p>{{ userRole(account.collaborators) }}</p>
              </div>
              <aryel-button (click)="operateOn(account)" size="small"
                >Enter</aryel-button
              >
            </div>
          </aryel-card>
        </div>
      </div>
      <div class="spacer spacer--v spacer--xxlarge"></div>
    </div>
  </div>
</div>

<ng-template #noAccounts>
  <div class="no-accounts">
    <div class="no-accounts--card cell auto">
      <div>
        <h2>
          You are not collaborating <br />
          to any account right now
        </h2>
        <p>
          If you think you’ve been removed by mistake,<br />
          get in touch with the owner of the account.
        </p>
      </div>
      <img
        [src]="'assets/img/illustrations/empty-search.jpg'"
        alt="Empty Search"
      />
    </div>
  </div>
  <div class="spacer spacer--v spacer--xxlarge sign-out"></div>
  <div class="sign-out" (click)="logOut()">
    <span class="material-symbols-outlined color-white"> exit_to_app </span>
    <p class="color-white">Sign out</p>
  </div>
</ng-template>
