import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DpDatePickerModule } from 'ng2-date-picker';
import { FormFieldModule } from '../form-field/form-field.module';
import { DatepickerComponent } from './datepicker.component';

@NgModule({
  declarations: [DatepickerComponent],
  imports: [CommonModule, FormFieldModule, FormsModule, DpDatePickerModule],
  exports: [DatepickerComponent],
})
export class DatepickerModule {}
