import { RouteGuard } from './route-guard';
import { AccountService } from '../services/account.service';
import { Transition } from '@uirouter/core';
import { ToastService } from '../services/toast.service';
export class HasDebtsRouteGuard extends RouteGuard {
  on: 'onBefore' = 'onBefore';

  hook = {
    to: (state) => state.name === 'private.billing.upgrade-plan' || state.name === 'private.billing.addons',
  };

  options = {
    priority: 30,
  };

  callback = async (transition: Transition) => {
    const accountService: AccountService = transition.injector().get(AccountService);
    const toastService: ToastService = transition.injector().get(ToastService);
    const { stateService } = transition.router;

    const debts = await accountService.debts().toPromise();
    if (debts.length) {
      toastService.addToast({
        title: 'There are open invoices!',
        text: 'You should first pay your open invoices',
        status: 'error',
      });
      return stateService.target('private.billing.main');
    }

    return null;
  };
}
