<aryel-card>
  <ng-container head>
    <div class="text-center">
      <img [src]="logo" class="logo" />
    </div>
  </ng-container>
  <ng-container *ngIf="!sent; else emailSent">
    <h2 class="text-center view__title">Recover your username</h2>
    <aryel-loading [loading]="loading">
      <p class="small text-center margin margin--none l-height-s">
        No worries, you can recover your Aryel username using the email address
        associated with your account.
      </p>
      <div class="spacer spacer--v spacer--slarge"></div>
      <form [formGroup]="form" (ngSubmit)="submit()" class="view__form">
        <aryel-form-field
          [errors]="form.controls.email.errors"
          [validate]="validate"
          placeholder="Email"
          color="light-gray"
        >
          <input
            aryelFormField
            type="text"
            [formControl]="form.controls.email"
          />
        </aryel-form-field>
        <div class="spacer spacer--v spacer--large"></div>
        <aryel-button type="submit" [full]="true"
          >Recover username</aryel-button
        >
      </form>
      <div class="spacer spacer--v spacer--large"></div>
      <p class="small text-center weight-700">
        <aryel-link uiSref="auth.sign-in" size="small"
          >Return to login</aryel-link
        >
      </p>
    </aryel-loading>
  </ng-container>
</aryel-card>

<ng-template #emailSent>
  <h2 class="text-center view__title">Check your email</h2>
  <p class="small text-center margin margin--none l-height-s">
    We've sent a message to your email with a link to recover your username.<br />
    Please also check your spam or junk mail folder.
  </p>
  <div class="spacer spacer--v"></div>
  <p class="text-center">
    Some problems?
    <span class="weight-600 link">
      <aryel-link
        [bold]="true"
        [link]="{ url: 'mailto:hello@aryel.io', target: '_blank' }"
        rightIcon="chevron_right"
        [biggerIcons]="true"
      >
        Contact us
      </aryel-link>
    </span>
  </p>
</ng-template>
