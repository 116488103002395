import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { UserInterface, UserSummaryInterface } from 'src/types/entities/user';
import { ApiService } from './api/api.service';
import { Restify } from './api/restify';
import { CustomerlyService } from './integrations/customerly.service';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class MeService extends Restify<UserInterface> {
  get me(): UserSummaryInterface {
    return this.localStorageService.getItem('user');
  }

  interpolate() {
    return this._basePath;
  }

  constructor(
    protected apiService: ApiService,
    private localStorageService: LocalStorageService,
    private customerlyService: CustomerlyService
  ) {
    super('/me', apiService);
  }

  getMe(): Observable<UserSummaryInterface> {
    return this.get('').pipe(
      catchError((err) => {
        if (err.status === 401) {
          return of(this.remove());
        }

        return of(null);
      }),
      tap((user) => this.save(user))
    );
  }

  profile() {
    return this.apiService.get(`${this.basePath}?full=true`);
  }

  setMe(me: UserSummaryInterface): this {
    this.save(me);
    return this;
  }

  save(user: UserSummaryInterface): UserSummaryInterface {
    this.localStorageService.setItem('user', user);
    return user;
  }

  remove(): boolean {
    this.localStorageService.removeItem('currentAccount');
    this.localStorageService.removeItem('availableFeatures');
    this.localStorageService.removeItem('currentSubscription');
    this.customerlyService.anonimize();
    return this.localStorageService.removeItem('user');
  }

  updateMe(me: Partial<Omit<UserInterface, 'avatar'> & { avatar: string }>): Observable<UserInterface> {
    return this.apiService.patch(this.basePath, me);
  }

  changePassword(currentPassword: string, newPassword: string): Observable<void> {
    return this.apiService.patch(`${this.basePath}/password`, {
      currentPassword,
      newPassword,
    });
  }
}
