import { Injectable } from '@angular/core';
import { DiscountInterface } from 'src/types/entities/discount';
import { AccountService } from '../account.service';
import { ApiService } from '../api/api.service';
import { Restify } from '../api/restify';

@Injectable({
  providedIn: 'root',
})
export class DiscountService extends Restify<DiscountInterface> {
  interpolate() {
    return this._basePath.replace(':account', this.accountService.currentAccount._id);
  }

  constructor(protected apiService: ApiService, protected accountService: AccountService) {
    super('/accounts/:account/discounts', apiService);
  }
}
