<aryel-card>
  <ng-container head>
    <div class="text-center">
      <img
        *ngIf="currentThemeMode === 'color'; else logo_white"
        src="assets/img/brand/logo.png"
        class="logo"
      />
      <ng-template #logo_white>
        <img src="assets/img/brand/logo-white.png" class="logo" />
      </ng-template>
    </div>
  </ng-container>
  <ng-container *ngIf="!sent; else emailSent">
    <h2 class="text-center view__title">Join your team</h2>
    <aryel-loading [loading]="loading">
      <div class="view__text">
        <p class="small text-center">
          Already using Aryel?
          <span class="weight-700 link">
            <aryel-link
              (click)="switch.emit('sign-in')"
              [bold]="true"
              rightIcon="chevron_right"
              [biggerIcons]="true"
              >Sign in to join this account</aryel-link
            >
          </span>
        </p>
      </div>
      <form [formGroup]="form" (submit)="submit($event)" class="view__form">
        <div class="grid-x view__name">
          <div class="cell">
            <aryel-form-field
              [errors]="form.controls.name.errors"
              [validate]="validate"
              placeholder="First name"
              color="light-gray"
            >
              <input
                name="name"
                autocomplete="name"
                aryelFormField
                type="text"
                [formControl]="form.controls.name"
              />
            </aryel-form-field>
          </div>
          <div class="cell">
            <aryel-form-field
              [errors]="form.controls.surname.errors"
              [validate]="validate"
              placeholder="Last name"
              color="light-gray"
            >
              <input
                name="surname"
                aryelFormField
                type="text"
                [formControl]="form.controls.surname"
              />
            </aryel-form-field>
          </div>
        </div>
        <div class="spacer spacer--v spacer--large"></div>
        <aryel-form-field
          [errors]="form.controls.email.errors"
          [validate]="validate"
          placeholder="Email"
          color="light-gray"
          [disabled]="true"
        >
          <input
            aryelFormField
            type="text"
            [formControl]="form.controls.email"
            [readOnly]="true"
          />
        </aryel-form-field>
        <div class="spacer spacer--v spacer--large"></div>
        <aryel-form-field
          [errors]="form.controls.username.errors"
          [validate]="validate"
          placeholder="Username"
          color="light-gray"
        >
          <input
            aryelFormField
            type="text"
            [formControl]="form.controls.username"
            (keyup)="checkUsername()"
          />
        </aryel-form-field>
        <div class="spacer spacer--v spacer--large"></div>
        <aryel-form-field
          [errors]="form.controls.password.errors"
          [password]="true"
          [(showPassword)]="showPassword"
          [validate]="validate"
          placeholder="Password"
          color="light-gray"
        >
          <input
            aryelFormField
            [type]="showPassword ? 'text' : 'password'"
            [formControl]="form.controls.password"
          />
        </aryel-form-field>
        <div class="spacer spacer--v spacer--large"></div>
        <aryel-requirements [requirements]="requirements"></aryel-requirements>
        <div class="spacer spacer--v"></div>
        <aryel-button type="submit" [full]="true">Get started</aryel-button>
      </form>
      <div class="spacer spacer--v spacer--large"></div>
      <p
        class="small view__policies text-center margin margin--none weight-400"
      >
        By joining, you are creating an Aryel account, and you agree to
        Aryel’s<aryel-link
          [bold]="true"
          [link]="{
            url: 'https://aryel.io/terms-conditions/',
            target: '_blank'
          }"
        >
          Terms and Conditions
        </aryel-link>
        and<aryel-link
          [bold]="true"
          [link]="{
            url: 'https://aryel.io/privacy-policy/',
            target: '_blank'
          }"
        >
          Privacy Policy </aryel-link
        >.
      </p>
      <div class="spacer spacer--v spacer--slarge"></div>
      <p class="xs text-center margin--none weight-400">
        Protected by reCAPTCHA
        <span class="block xs">
          <aryel-link
            [bold]="true"
            [link]="{
              url: 'https://www.google.com/intl/en/policies/terms/',
              target: '_blank'
            }"
          >
            Terms of Use
          </aryel-link>
          and<aryel-link
            [bold]="true"
            [link]="{
              url: 'https://www.google.com/intl/en/policies/privacy/',
              target: '_blank'
            }"
          >
            Privacy Policy
          </aryel-link>
        </span>
      </p>
    </aryel-loading>
  </ng-container>
</aryel-card>

<ng-template #emailSent>
  <h3 class="text-center view__title">Woohoo!</h3>
  <p class="small text-center margin margin--none sent__text l-height-s">
    You are now part of {{ invitation.companyName }}’s team as
    {{ invitation.role }}.<br />
    Log into your account and start working together on amazing projects ⚡️
  </p>
  <div class="spacer spacer--v spacer--mlarge"></div>
  <aryel-button uiSref="auth.sign-in" [full]="true">Go to Sign in</aryel-button>
  <div class="spacer spacer--v spacer--mlarge"></div>
  <p class="weight-500 text-center">
    Some problems?
    <span class="weight-600 link">
      <aryel-link
        [bold]="true"
        [link]="{ url: 'mailto:hello@aryel.io', target: '_blank' }"
        rightIcon="chevron_right"
        [biggerIcons]="true"
      >
        Contact us
      </aryel-link>
    </span>
  </p>
</ng-template>
