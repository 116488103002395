import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { environment } from 'src/environments/environment';
import { AUTH_INTERCEPTOR_OPTIONS } from './auth-interceptor-options';

@Injectable({
  providedIn: 'root',
})
export class AuthInterceptor implements HttpInterceptor {
  private whitelist: Array<RegExp> = [];
  private blacklist: Array<RegExp> = [];

  constructor(@Inject(AUTH_INTERCEPTOR_OPTIONS) config: any, private localStorageService: LocalStorageService) {
    this.whitelist = config.whitelist || [new RegExp('*')];
    this.blacklist = config.blacklist || [new RegExp('*/public/*')];
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (environment.doubleAuthenticationLayer) {
      return this.doubleLayerAuthenticationInterceptor(request, next);
    }

    return this.singleLayerAuthenticationInterceptor(request, next);
  }

  singleLayerAuthenticationInterceptor(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let cloned: HttpRequest<any>;

    // if (this.isWhitelisted(request) && !this.isBlacklisted(request)) {
    cloned = request.clone({
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.localStorageService.getItem('token')}` || '',
        'X-UID': this.localStorageService.getItem('uid') || '',
      }),
    });
    // } else {
    //   cloned = request.clone()
    // }

    return next.handle(cloned).pipe(
      tap((evt) => {
        if (evt instanceof HttpResponse) {
          const token = evt.headers.get('Authorization');
          const uid = evt.headers.get('X-UID');

          if (!!token && token.split(' ')[0] === 'Bearer' && token.split(' ')[1]) {
            this.localStorageService.setItem('token', token.split(' ')[1]);
            this.localStorageService.setItem('uid', uid || '');
          }
        }
      })
    );
  }

  doubleLayerAuthenticationInterceptor(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const customHeaders = {};

    if (request.url.indexOf('/fw/') < 0) {
      customHeaders['X-Fw-Authorization'] = this.localStorageService.getItem('fw-token') || '';
      customHeaders['X-Fw-UID'] = this.localStorageService.getItem('fw-uid') || '';
    }

    // if (this.isWhitelisted(request) && !this.isBlacklisted(request)) {
    customHeaders['Authorization'] = `Bearer ${this.localStorageService.getItem('token')}` || '';
    customHeaders['X-UID'] = this.localStorageService.getItem('uid') || '';
    // }

    const cloned: HttpRequest<any> = request.clone({
      setHeaders: customHeaders,
    });

    return next.handle(cloned).pipe(
      tap((evt) => {
        if (evt instanceof HttpResponse) {
          const token = evt.headers.get('Authorization');
          const uid = evt.headers.get('X-UID');

          if (!!token && token.split(' ')[0] === 'Bearer' && token.split(' ')[1]) {
            this.localStorageService.setItem('token', token.split(' ')[1]);
            this.localStorageService.setItem('uid', uid || 'Not-available');
          }
        }
      })
    );
  }

  isWhitelisted(request: HttpRequest<any>): boolean {
    const url = request.url;

    return this.whitelist.findIndex((route) => (route instanceof RegExp ? route.test(url) : false)) > -1;
  }

  isBlacklisted(request: HttpRequest<any>): boolean {
    const url = request.url;

    return this.blacklist.findIndex((route) => (route instanceof RegExp ? route.test(url) : false)) > -1;
  }
}
