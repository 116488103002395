import { AfterViewInit, Component, DestroyRef, Input, inject } from '@angular/core';
import { TransitionService } from '@uirouter/core';
import { ModalService } from 'src/app/services/modal.service';
import { MeService } from '../../../services/me.service';
import { TermsAndConditionsService } from '../../../services/terms-and-conditions.service';
import { ToastService } from '../../../services/toast.service';

@Component({
  selector: 'aryel-terms-and-conditions-acceptance-modal',
  templateUrl: './terms-and-conditions-acceptance-modal.component.html',
  styleUrls: ['./terms-and-conditions-acceptance-modal.component.scss'],
})
export class TermsAndConditionsAcceptanceModalComponent implements AfterViewInit {
  @Input() modalId: string = 'aryel-terms-and-conditions-acceptance-modal';
  public loading: boolean = false;
  public tacText: string = '';

  private destroyRef = inject(DestroyRef);

  constructor(
    private transitionService: TransitionService,
    private modalService: ModalService,
    private meService: MeService,
    private tacService: TermsAndConditionsService,
    private toastService: ToastService
  ) {
    this.transitionService.onEnter({ from: '**' }, (transition) => {
      this.verifyAcceptance();
    });
  }

  //TODO: search another way to fix the error (ExpressionChangedAfterItHasBeenCheckedError)
  ngAfterViewInit(): void {
    setTimeout(() => {
      this.verifyAcceptance();
    });
  }

  goTo() {
    window.open('https://aryel.io/privacy-policy/', '_blank');
  }

  verifyAcceptance() {
    // retrieves Terms and Conditions update
    const user = this.meService.me;

    if (user?.tac_state && typeof user?.tac_state !== 'boolean') {
      this.tacText = user.tac_state.text;

      this.open();
    }
  }

  open() {
    if (!this.modalService.isOpen(this.modalId)) this.modalService.openModal(this.modalId, true, true);
  }

  async accept() {
    this.loading = true;
    const user = this.meService.me;

    if (typeof user?.tac_state !== 'boolean') {
      await this.tacService.acceptTerms(user._id, user.tac_state._id).subscribe({
        error: (): void => {
          this.toastService.addToast({
            title: 'An error occurred!',
            text: 'An error has occurred while accepting Terms and Conditions...',
            status: 'error',
          });

          this.loading = false;
        },
        complete: (): void => {
          this.toastService.addToast({
            title: 'Terms accepted',
            text: 'You can now continue utilizing Aryel.',
            status: 'success',
          });

          // updates me in local
          user.tac_state = false;
          this.meService.save(user);

          this.modalService.closeModal(this.modalId);

          this.loading = false;
        },
      });
    }
  }
}
