export default abstract class AryelStorage {
  protected abstract prefix: string;
  protected abstract storage: Storage;

  // IDEA is it possible to use generic type <T>?
  getItem(key: string): any {
    const element = this.storage.getItem(this.buildKey(key));
    if (element === null) {
      return null;
    }

    // @ts-ignore
    if (!isNaN(element)) {
      return parseInt(element);
    }

    try {
      return JSON.parse(element);
    } catch (err) {
      return element;
    }
  }

  setItem(key: string, data: any): boolean {
    const aryelKey = this.buildKey(key);

    if (typeof data === 'string') {
      try {
        this.storage.setItem(aryelKey, data);
        return true;
      } catch (err) {
        return false;
      }
    }

    if (typeof data === 'string') {
      try {
        this.storage.setItem(aryelKey, data);
        return true;
      } catch (err) {
        return false;
      }
    }

    if (typeof data === 'number') {
      try {
        this.storage.setItem(aryelKey, data.toString());
        return true;
      } catch (err) {
        return false;
      }
    }

    if (typeof data === 'object') {
      try {
        this.storage.setItem(aryelKey, JSON.stringify(data));
        return true;
      } catch (err) {
        return false;
      }
    }

    return false;
  }

  removeItem(key: string): boolean {
    try {
      this.storage.removeItem(this.buildKey(key));
      return true;
    } catch (err) {
      return false;
    }
  }

  private buildKey(key: string): string {
    return `${this.prefix}-${key}`;
  }
}
