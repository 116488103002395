<div
  class="nav inline"
  (click)="
    nav.children && nav.children.length && !nav.disabled
      ? navElementTrigger()
      : null
  "
  [ngClass]="{
    'nav--has-children pointer': nav.children && nav.children.length
  }"
  [class.nav--disabled]="nav.disabled"
  [class.nav--open]="open"
>
  <ng-container *ngIf="!nav.ref; else noLink">
    <h5
      permissions
      [restriction]="nav.restriction"
      [class.nav__element--open]="open || activeChild"
      class="nav__element margin margin--none not-selectable"
    >
      {{ nav.title }}
    </h5>
    <ng-container *ngIf="nav.children && nav.children.length">
      <div
        (mouseleave)="navElementTrigger()"
        *ngIf="open"
        [@openClose]
        class="nav__menu"
      >
        <div
          (click)="navigateTo(child)"
          (click)="!child.badge ? navElementTrigger() : null"
          class="nav__menu-single"
          [ngClass]="checkActiveStatus(child) ? 'nav__menu-single--active' : ''"
          [class.nav__menu-single--disabled]="child.disabled"
          *ngFor="let child of nav.children"
        >
          <div class="grid-x align-middle">
            <div class="cell shrink nav__menu-single-icon" *ngIf="child.icon">
              <img class="nav__icon" [src]="child.icon" />
            </div>
            <div class="cell auto nav__menu-single-text">
              <p class="margin margin--none">
                <span class="h5">{{ child.title }}</span>
                <ng-container *ngIf="child.badge">
                  <aryel-badge [color]="child.badgeColor || 'secondary'">
                    {{ child.badge }}
                  </aryel-badge></ng-container
                >
              </p>
              <ng-container>
                <!-- <div class="spacer spacer--v spacer--small"></div> -->
                <small class="margin margin--none" *ngIf="child.text">{{
                  child.text
                }}</small>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>

<ng-template #noLink>
  <h5
    permissions
    [restriction]="nav.restriction"
    class="nav__element margin margin--none pointer not-selectable"
    (click)="navigateTo(nav)"
    [ngClass]="checkActiveStatus(nav) ? 'nav__element--active' : ''"
  >
    {{ nav.title }}
  </h5>
</ng-template>
