const defaultColor: string = '#1c0656';
const warnColor: string = '#f9d03f';
const alertColor: string = '#d22017';
const successColor: string = '#82c329';
const highlightColor: string = '#fc1aa0';
const highlight2Color: string = '#931aff';

const styles = `
  color: #fff;
  background-color: $$background$$;
  font-family: 'Helvetica';
  font-size: 12px;
  padding: 2px 4px
`;

type LoggerType = 'warn' | 'alert' | 'success' | 'highlight' | 'highlight2' | 'default';

const logger = (title: string, color: LoggerType = 'default') => {
  let style = styles;
  switch (color) {
    case 'warn':
      style = style.replace('$$background$$', warnColor);
      break;
    case 'alert':
      style = style.replace('$$background$$', alertColor);
      break;
    case 'success':
      style = style.replace('$$background$$', successColor);
      break;
    case 'highlight':
      style = style.replace('$$background$$', highlightColor);
      break;
    case 'highlight2':
      style = style.replace('$$background$$', highlight2Color);
      break;
    default:
      style = style.replace('$$background$$', defaultColor);
      break;
  }
  console.log(`%c${title}`, style);
};

export default logger;
