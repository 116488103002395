import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import AryelStorage from './storage';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService extends AryelStorage {
  prefix: string = environment.localStorageKey;
  storage: Storage = window.localStorage;
}
