import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';

export interface BodyDataInterface {
  [x: string]: any;
}
export interface FullPathInterface {
  domain: string;
  url: string;
}

type GetApiRequestInterface = (relativePath: string | FullPathInterface, options?: any) => Observable<any>;
type PostApiRequestInterface = (
  relativePath: string | FullPathInterface,
  data: BodyDataInterface,
  options?: any
) => Observable<any>;
type PatchApiRequestInterface = (
  relativePath: string | FullPathInterface,
  data: BodyDataInterface,
  options?: any
) => Observable<any>;
type PutApiRequestInterface = (
  relativePath: string | FullPathInterface,
  data: BodyDataInterface,
  options?: any
) => Observable<any>;
type DeleteApiRequestInterface = (relativePath: string | FullPathInterface, options?: any) => Observable<any>;

export interface ApiRequestInterface {
  get: GetApiRequestInterface;
  post: PostApiRequestInterface;
  patch: PatchApiRequestInterface;
  put: PutApiRequestInterface;
  delete: DeleteApiRequestInterface;
}

@Injectable({
  providedIn: 'root',
})
export class ApiService implements ApiRequestInterface {
  apiConfig = environment.api;

  constructor(private http: HttpClient) {}

  private request(method: string, url: string | FullPathInterface, options?: Object): Observable<any> {
    let requestUrl = '';

    if (typeof url === 'string') {
      requestUrl = `${this.apiConfig.fullPath}${url}`;
    } else {
      requestUrl = `${(url as FullPathInterface).domain}${(url as FullPathInterface).url}`;
    }

    return this.http.request(
      method,
      requestUrl,
      Object.assign(
        {
          withCredentials: true,
        },
        options
      )
    );
  }

  get(url: string | FullPathInterface, options: { [x: string]: any } = {}): Observable<any> {
    return this.request('GET', url, options);
  }

  post(url: string | FullPathInterface, body = {}, options: { [x: string]: any } = {}): Observable<any> {
    return this.request('POST', url, Object.assign(options, { body }));
  }

  patch(url: string | FullPathInterface, body = {}, options: { [x: string]: any } = {}): Observable<any> {
    return this.request('PATCH', url, Object.assign(options, { body }));
  }

  put(url: string | FullPathInterface, body = {}, options: { [x: string]: any } = {}): Observable<any> {
    return this.request('PUT', url, Object.assign(options, { body }));
  }

  delete(url: string | FullPathInterface, options: { [x: string]: any } = {}): Observable<any> {
    return this.request('DELETE', url, options);
  }
}
