import { RouteGuard } from './route-guard';
import { Transition } from '@uirouter/core';
import { AccountService } from '../services/account.service';
import { FeatureService } from '../services/account/feature.service';
import { AryelFeature, FEATURES } from 'src/modules/type-definitions/feature';
import { Feature } from 'src/types/entities/feature';

const defaultRedirect = 'private.home';

export class HasFeatureRouteGuard extends RouteGuard {
  on: 'onBefore' = 'onBefore';

  hook = {
    to: (state) => state.name.indexOf('private') === 0,
  };

  options = {
    priority: 26,
  };

  private redirect: string = defaultRedirect;

  callback = async (transition: Transition) => {
    const accountService: AccountService = transition.injector().get(AccountService);
    const featureService: FeatureService = transition.injector().get(FeatureService);
    const { stateService } = transition.router;
    const data = transition.targetState().state().data;

    if (data?.feature?.name && accountService.currentAccount) {
      const feature = data.feature.name as AryelFeature | AryelFeature[];

      let featureExist = false;
      if (typeof feature === 'string') {
        featureExist = FEATURES.includes(data.feature.name);
      } else {
        featureExist = (FEATURES as AryelFeature[]).some((f) => feature.indexOf(f) >= 0);
      }
      if (!featureExist) {
        return;
      }

      const redirect = data.feature.redirect || this.redirect;
      await featureService.refreshAvailableFeatures().toPromise();
      const availableFeatures = featureService.availableFeatures || [];

      if (typeof feature === 'string') {
        if (!availableFeatures.find((f) => f.feature === feature)) {
          return stateService.target(redirect);
        }
      } else {
        if (!availableFeatures.some((f) => feature.indexOf(f.feature) >= 0)) {
          return stateService.target(redirect);
        }
      }
    }
  };
}

export function RouteFeature(feature: Feature | Feature[], redirect: string = defaultRedirect) {
  return {
    name: feature,
    redirect,
  };
}
