<div
  class="tooltip"
  [ngClass]="['tooltip--' + position]"
  [style.left]="left + 'px'"
  [style.top]="top + 'px'"
  [class.tooltip--visible]="visible"
>
  <h3>
    {{ tooltip.title }}
  </h3>
  <p>{{ tooltip.text }}</p>
</div>
