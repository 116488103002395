<div class="container" [class.container--scroller]="!disableOverflow">
  <div
    #containerHead
    class="grid-container container__head"
    [ngClass]="size !== 'medium' ? 'grid-container--' + size : ''"
  >
    <ng-content select="[head]"></ng-content>
  </div>
  <div
    class="grid-container container__outer"
    [ngClass]="size !== 'medium' ? 'grid-container--' + size : ''"
    [class.background]="white"
    [class.container__outer--disablePadding]="disablePadding"
  >
    <ng-content></ng-content>
  </div>
  <div
    #containerFooter
    class="grid-container container__footer"
    [ngClass]="size !== 'medium' ? 'grid-container--' + size : ''"
  >
    <ng-content select="[footer]"></ng-content>
  </div>
</div>
