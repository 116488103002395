import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CampaignAssetType, CampaignTrigger } from 'src/modules/type-definitions/campaign';
import { CampaignInterface } from 'src/types/entities/campaign';
import {
  MainCategoryFavouriteResponse,
  MainCategoryFavouritesResponseInterface,
  MainCategoryIndexResponseInterface,
  TemplateInterface,
} from 'src/types/entities/template';
import { PaginationParamsInterface } from 'src/types/helpers/pagination';
import { AccountService } from '../account.service';
import { ApiService } from '../api/api.service';
import { Restify } from '../api/restify';

@Injectable({
  providedIn: 'root',
})
export class TemplateService extends Restify<TemplateInterface> {
  interpolate() {
    return this._basePath.replace(':account', this.accountService.currentAccount._id);
  }

  constructor(protected apiService: ApiService, protected accountService: AccountService) {
    super('/accounts/:account/templates', apiService);
  }

  mainCategories(): Observable<MainCategoryIndexResponseInterface[]> {
    return this.apiService.get(`${this.basePath}/main-categories`);
  }

  favouriteCategoriesTemplates(): Observable<MainCategoryFavouritesResponseInterface> {
    return this.apiService.get(`${this.basePath}/categories/presentation`, { params: { groupLimit: 3, favorites: true } });
  }

  listGroupedByCategories(
    groupLimit: number = 3,
    favorites: boolean = false,
    search?: string
  ): Observable<MainCategoryFavouritesResponseInterface> {
    const params = {
      groupLimit,
      favorites,
      ...(search && { search: search }),
    };

    return this.apiService.get(`${this.basePath}/categories/presentation`, { params });
  }

  filters(mainCategory: string): Observable<{ name: string; values: string[] }[]> {
    return this.apiService.get(`${this.basePath}/categories`, { params: { mainCategory } });
  }

  favoriteCategories(): Observable<MainCategoryFavouriteResponse[]> {
    return this.apiService.get(`${this.basePath}/main-category/favorites`);
  }

  updateFavoriteCategories(categories: string[]): Observable<void> {
    const body = {
      categories: categories.map((cat) => {
        return {
          categoryName: 'Main',
          categoryValue: cat,
        };
      }),
    };
    return this.apiService.patch(`${this.basePath}/main-category/favorites`, body);
  }

  list(
    filters: PaginationParamsInterface<{
      mainCategory?: string;
      trigger?: CampaignTrigger;
      media?: CampaignAssetType[];
      favorites?: boolean;
      difficulty?: number;
      categories?: {
        [x: string]: string[];
      };
      premium?: boolean;
      search?: string;
    }> = {}
  ): Observable<HttpResponse<TemplateInterface[]>> {
    const params: any = {};

    if (filters.mainCategory) {
      params.mainCategory = filters.mainCategory;
    }

    if (filters.trigger) {
      params.trigger = filters.trigger;
    }

    if (filters.media?.length) {
      params['media[]'] = filters.media;
    }

    if (filters.favorites) {
      params.favorites = filters.favorites;
    }

    if (filters.categories) {
      const categoryFilter: string[] = [];
      for (const category of Object.keys(filters.categories)) {
        for (const value of filters.categories[category]) {
          categoryFilter.push(`${category}:${value}`);
        }
      }
      if (categoryFilter.length) {
        params['categories[]'] = categoryFilter;
      }
    }

    if (filters.premium !== null && filters.premium !== undefined) {
      params.premium = filters.premium;
    }

    if (filters.difficulty) {
      params.difficulty = filters.difficulty;
    }

    if (filters.sort) {
      params.sort = filters.sort;
    }
    if (filters.page) {
      params.page = filters.page;
    }
    if (filters.limit) {
      params.limit = filters.limit;
    }

    if (filters.search?.trim()) {
      params.search = filters.search.trim();
    }

    return this.apiService.get(this.basePath, { params, observe: 'response' });
  }

  getCampaignFromTemplate(template: string): Observable<CampaignInterface> {
    return this.apiService.get(`${this.basePath}/${template}/campaign`);
  }

  getDailyTemplates(dailies: number = 1): Observable<TemplateInterface[]> {
    return this.index({ params: { dailies } });
  }

  dailies(dailies: number = 1): Observable<TemplateInterface[]> {
    return this.apiService.get(`${this.basePath}/dailies`, { params: { dailies } });
  }

  favorites(): Observable<TemplateInterface[]> {
    return this.apiService.get(`${this.basePath}/favorites`);
  }

  addFavorite(template: string): Observable<TemplateInterface> {
    return this.apiService.post(`${this.basePath}/favorites`, { template });
  }

  removeFavorite(template: string): Observable<TemplateInterface> {
    return this.apiService.delete(`${this.basePath}/favorites/${template}`);
  }

  createCampaign(template: string): Observable<CampaignInterface> {
    return this.apiService.post(`${this.basePath}/${template}/campaign`, {});
  }
}
