import { Component, DestroyRef, Input, OnInit, inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { of } from 'rxjs';
import { finalize, flatMap } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';
import { MeService } from 'src/app/services/me.service';
import { SignupService } from 'src/app/services/signup.service';
import { ThemeService } from 'src/app/services/theme.service';
import { ToastService } from 'src/app/services/toast.service';
import { AryelUserInterface } from 'src/modules/type-definitions/user';
import { RequirementInterface } from 'src/types/components/layout/requirements';
import { ValidatedInvitationInterface } from 'src/types/entities/account';
import { environment } from '../../../../environments/environment';
import { Join } from '../join';

@Component({
  selector: 'aryel-join-sign-up',
  templateUrl: './join-sign-up.component.html',
  styleUrls: ['./join-sign-up.component.scss'],
})
export class JoinSignUpComponent extends Join implements OnInit {
  @Input() user: AryelUserInterface = null;
  @Input() accountId: string = null;
  @Input() emailHash: string = null;
  @Input() invitation: ValidatedInvitationInterface = null;
  @Input() invitationToken: string = null;

  form: UntypedFormGroup = null;
  validate = false;
  sent = false;
  showPassword = false;
  loading = false;
  disabled = true;

  private destroyRef = inject(DestroyRef);

  constructor(
    private formBuilder: UntypedFormBuilder,
    private toastService: ToastService,
    private signupService: SignupService,
    private authService: AuthService,
    private meService: MeService,
    private themeService: ThemeService,
    private recaptchaV3Service: ReCaptchaV3Service
  ) {
    super();
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      email: [this.invitation.email, Validators.required],
      username: ['', Validators.required],
      password: ['', Validators.required],
      name: ['', Validators.required],
      surname: ['', Validators.required],
    });
  }

  get currentThemeMode() {
    return this.themeService.currentMode;
  }

  get requirements(): RequirementInterface[] {
    return [
      {
        text: '8 or more characters',
        fulfilled: this.form.controls.password.value && this.form.controls.password.value.length >= 8,
      },
      {
        text: 'One letter',
        fulfilled: this.form.controls.password.value && !!this.form.controls.password.value.match(/([a-z|A-Z])/g),
      },
      {
        text: 'One number',
        fulfilled: this.form.controls.password.value && !!this.form.controls.password.value.match(/([0-9])/g),
      },
      {
        text: 'One special character',
        fulfilled: this.form.controls.password.value && !!this.form.controls.password.value.match(/([^\w\*])/g),
      },
    ];
  }

  submit(event: Event) {
    event.preventDefault();
    this.validate = true;
    if (this.requirements.some((x) => !x.fulfilled)) {
      this.toastService.addToast({
        title: 'The password is insecure!',
        text: 'Use a stronger password following the hints',
        status: 'error',
      });

      return;
    }

    if (this.form.valid) {
      this.loading = true;

      const timezone = Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone || String(new Date().getTimezoneOffset());

      this.useRecaptcha()
        .pipe(
          flatMap((token) => {
            return this.signupService.reserve({
              email: this.form.get('email').value,
              username: this.form.get('username').value,
              password: this.form.get('password').value,
              name: this.form.get('name').value,
              surname: this.form.get('surname').value,
              invitationTicket: {
                token: this.invitationToken,
                account: this.accountId,
                emailHash: this.emailHash,
              },
              recaptcha: token,
              timezone,
            });
          }),
          flatMap(() => {
            if (this.meService.me) {
              return this.authService.signout();
            }

            return of(null);
          }),
          finalize(() => {
            this.loading = false;
          })
        )
        .subscribe(
          () => {
            this.sent = true;
          },
          (err) => {
            this.sent = false;
            if (typeof err === 'string') {
              this.toastService.addToast({
                title: 'Something went wrong with the sign-up... Try again or contact us!',
                status: 'error',
              });
            } else {
              this.toastService.addToast(err);
            }
          }
        );
    }
  }

  private useRecaptcha() {
    return environment.recaptcha.v3 ? this.recaptchaV3Service.execute('sign_up') : of(null);
  }

  checkUsername() {
    this.form.controls.username.setValue(
      (this.form.controls.username.value as string).toLowerCase().replace(/[^\w\s]/gi, '')
    );
  }
}
