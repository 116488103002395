<div class="el" *ngIf="show">
  <div
    class="el__overlay"
    *ngIf="content.redirect; else noRedirectOverlayTemplate"
    [uiSref]="content.redirect"
    (click)="dismiss()"
  ></div>
  <div
    class="el__content"
    [class.el__content--wide]="isWide"
    [class.el__content--full-height]="['adv'].includes(content.type)"
  >
    <div class="el__body grid-x">
      <div class="el__closer" (click)="dismiss()">
        <div></div>
      </div>
      <div
        class="cell small-6 el__body-left"
        [class.large-6]="content.right"
        [class.large-12]="!content.right"
      >
        <div>
          <div>
            <h2 class="color-white">{{ content.left.title }}</h2>
            <small
              class="color-white weight-500 margin margin--none"
              [innerHTML]="content.left.text"
            ></small>
          </div>

          <ng-container *ngIf="content.left.features">
            <div class="spacer spacer--v spacer--large"></div>
            <div
              *ngFor="let feature of content.left.features"
              class="el__feature grid-x align-middle"
            >
              <div class="cell shrink">
                <span class="material-symbols-outlined"> done </span>
              </div>
              <div class="cell auto">
                <small class="weight-500" [innerHTML]="feature"></small>
              </div>
            </div>
          </ng-container>

          <div
            *ngIf="content.left.carousel && content.left.carousel.length === 1"
            class="el__image"
          >
            <img [src]="content.left.carousel[0]" />
          </div>
          <div
            *ngIf="content.left.carousel && content.left.carousel.length > 1"
            class="el__carousel"
          >
            <div class="el__slides">
              <ng-container
                *ngFor="let slideUrl of content.left.carousel; let i = index"
              >
                <img
                  [@carouselAnimation]="
                    i === currentCarouselSlide ? 'current' : 'hidden'
                  "
                  [src]="slideUrl"
                  class="slide"
                  [class.active]="i === currentCarouselSlide"
                />
              </ng-container>
            </div>

            <div class="spacer spacer--v spacer--mlarge"></div>
            <div class="el__pagination">
              <ng-container
                *ngFor="let slide of content.left.carousel; let i = index"
              >
                <div
                  (click)="currentCarouselSlide = i"
                  class="pointer"
                  [style.width]="100 / content.left.carousel.length + '%'"
                >
                  <span [class.active]="currentCarouselSlide === i"></span>
                </div>
              </ng-container>
            </div>
          </div>
          <div class="el__actions">
            <div class="spacer spacer--v spacer--medium"></div>
            <aryel-button
              *ngIf="content.left.cta"
              status="tertiary"
              (click)="handleCta('left')"
            >
              {{ content.left.cta.text }}
            </aryel-button>
            <div *ngIf="content.left.link" class="text-center">
              <aryel-link
                size="small"
                color="white"
                rightIcon="chevron_right"
                (click)="handleLink('left')"
              >
                {{ content.left.link.text }}
              </aryel-link>
            </div>
          </div>
        </div>
      </div>
      <div class="cell small-6 el__body-right" *ngIf="content.right">
        <div>
          <div>
            <h2>{{ content.right.title }}</h2>
            <small
              class="weight-500 margin margin--none"
              [innerHTML]="content.right.text"
            >
            </small>
            <div
              *ngFor="let feature of content.right.features"
              class="el__feature grid-x align-middle"
            >
              <div class="cell shrink">
                <span class="material-symbols-outlined"> done </span>
              </div>
              <div class="cell auto">
                <small class="weight-500" [innerHTML]="feature"></small>
              </div>
            </div>
          </div>
          <div class="el__actions">
            <aryel-button (click)="handleCta('right')">
              {{ content.right.cta.text }}
            </aryel-button>
            <div class="text-center">
              <aryel-link
                size="small"
                color="primary"
                rightIcon="chevron_right"
                (click)="handleLink('right')"
              >
                {{ content.right.link.text }}
              </aryel-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #noRedirectOverlayTemplate>
  <div class="el__overlay" (click)="dismiss()"></div>
</ng-template>

<ng-template #noRedirectDismissTemplate>
  <p class="el__dismiss" (click)="dismiss()">
    <span>Dismiss</span>
  </p>
</ng-template>
