import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FileChooserComponent } from './file-chooser.component';

@NgModule({
  declarations: [FileChooserComponent],
  imports: [CommonModule],
  exports: [FileChooserComponent],
})
export class FileChooserModule {}
