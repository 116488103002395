import { Upload } from './upload';
import { ResourceAccountBucketCategory } from 'src/modules/type-definitions/resource';
import { HttpClient } from '@angular/common/http';
import { AccountService } from '../account.service';

export class UploadAccount extends Upload {
  constructor(
    protected type: ResourceAccountBucketCategory,
    protected httpClient: HttpClient,
    protected accountService: AccountService,
  ) {
    super(`/accounts/:account/resources/${type}`, httpClient, accountService);
  }
}
