<aryel-modal [id]="modalId">
  <h1 class="text-center margin margin--none">Pending plan upgrade</h1>
  <div class="spacer spacer--v spacer--mlarge"></div>
  <h5 class="text-center margin margin--none">
    It seem you have a pending plan upgrade. You can continue the process or
    remove it and start over.
  </h5>
  <div class="spacer spacer--v spacer--xlarge"></div>
  <div class="grid-x align-center grid-margin-x align-middle">
    <div class="cell shrink">
      <aryel-button (click)="upgrade()"> Upgrade plan </aryel-button>
    </div>
    <div class="cell shrink">
      <aryel-link [bold]="true" (click)="remove()">Remove</aryel-link>
    </div>
  </div>
  <div class="spacer spacer--v"></div>
  <p class="small weight-500 margin margin--none text-center">
    If you don't upgrade or remove your pending subscription, you won't be able
    to upgrade your subscription to a different plan or buy new add-ons.
  </p>
</aryel-modal>
