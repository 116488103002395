import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  Trigger360Interface,
  TriggerFaceInterface,
  TriggerGeolocationInterface,
  TriggerImageTrackingInterface,
  TriggerMarkerBasedInterface,
} from 'src/types/entities/campaign';
import { AccountService } from '../account.service';
import { ApiService } from '../api/api.service';
import { Restify } from '../api/restify';

interface Creatable {
  create(...params: any): Observable<any>;
}

@Injectable({
  providedIn: 'root',
})
export class TriggerService
  extends Restify<
    | TriggerMarkerBasedInterface
    | TriggerImageTrackingInterface
    | TriggerGeolocationInterface
    | TriggerFaceInterface
    | Trigger360Interface
  >
  implements Creatable
{
  interpolate() {
    return this._basePath.replace(':account', this.accountService.currentAccount._id);
  }

  constructor(protected apiService: ApiService, protected accountService: AccountService) {
    super('/accounts/:account/triggers', apiService);
  }

  markers(): Observable<TriggerMarkerBasedInterface[]> {
    return this.index({
      params: {
        type: 'marker-based',
      },
    }) as Observable<TriggerMarkerBasedInterface[]>;
  }

  imageTracking(): Observable<TriggerImageTrackingInterface[]> {
    return this.index({
      params: {
        type: 'image-tracking',
      },
    }) as Observable<TriggerImageTrackingInterface[]>;
  }

  imageTrackingResourceBind(triggers: string[], resource: string): Observable<TriggerImageTrackingInterface[]> {
    return this.apiService.post(`${this.basePath}/it-resource-bind`, { triggers, resource });
  }

  schedule(marker: string) {
    return this.apiService.post(`${this.basePath}/${marker}/schedules`);
  }

  unschedule(marker: string, schedule: string, action: string = 'delete') {
    return this.apiService.delete(`${this.basePath}/${marker}/schedules/${schedule}`);
  }
}
