import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ButtonModule } from '../../ui/button/button.module';
import { LinkModule } from '../../ui/link/link.module';
import { LoadingBarModule } from '../loading-bar/loading-bar.module';
import { LoadingModule } from '../loading/loading.module';
import { ModalModule } from '../modal/modal.module';
import { ItTargetGeneratorV2Component } from './it-target-generator-v2.component';

@NgModule({
  declarations: [ItTargetGeneratorV2Component],
  imports: [CommonModule, LoadingBarModule, LinkModule, LoadingModule, ModalModule, ButtonModule],
  exports: [ItTargetGeneratorV2Component],
})
export class ItTargetGeneratorV2Module { }
