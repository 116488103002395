<div
  class="grid-x toast align-middle"
  [ngClass]="['toast--' + (status || 'success')]"
  [class.toast--action]="action"
>
  <div
    *ngIf="removable && (!action || action.ref !== 'close')"
    class="toast__close"
    (click)="remove()"
  >
    <span class="material-symbols-outlined"> close </span>
  </div>
  <div class="cell shrink toast__icon">
    <span class="material-symbols-outlined">
      {{ icon }}
    </span>
  </div>
  <div class="cell auto toast__text">
    <p class="weight-700">{{ title }}</p>
    <p class="small weight-400 word-break" *ngIf="text">{{ text }}</p>
  </div>
  <div class="toast__action cell shrink" *ngIf="action">
    <aryel-button
      *ngIf="removable && action.ref === 'close'; else toastActionTemplate"
      status="tertiary"
      (click)="remove()"
    >
      {{ action.text }}
    </aryel-button>
  </div>
</div>

<ng-template #toastActionTemplate>
  <aryel-button
    status="tertiary"
    [uiSref]="action.ref"
    [uiParams]="action.params || {}"
  >
    {{ action.text }}
  </aryel-button>
</ng-template>
