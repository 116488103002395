export const campaignCategories: {
  [x: string]: {
    name?: string;
    account?: {
      icon: string;
    };
    templates?: {
      text: string;
      cover: string;
    };
    campaigns?: {
      title: string;
      text: string;
      triggers: { _id: string; title: string; text: string; image: string; icon: string; flow: string[] }[];
    };
  };
} = {
  'product-visualization': {
    name: 'Product Visualization',
    account: {
      icon: 'chair',
    },
    templates: {
      text: 'Give your customers realistic and true-to-size product previews to help them understand if your product meets their needs. The experience will be so enjoyable that they will keep coming back for more.',
      cover: 'assets/img/templates/categories/product-visualization.jpg',
    },
    campaigns: {
      title: 'Select your ideal solution',
      text: 'A product visualization experience can be provided in different ways. Select the one you prefer.',
      triggers: [
        {
          _id: 'surface',
          title: 'Placed on a surface',
          text: 'With this option your audience will be able to place any 3D object on a surface in the real environment.',
          image: 'surface',
          icon: 'view_in_ar',
          flow: [],
        },
        {
          _id: 'image-tracking',
          title: 'Triggered by images',
          text: 'With this solution you get the ability to showcase multiple products by scanning different images.',
          image: 'image',
          icon: 'image',
          flow: ['vertical', 'horizontal'],
        },
      ],
    },
  },
  'interactive-print': {
    name: 'Interactive Print',
    account: {
      icon: 'menu_book',
    },
    templates: {
      text: 'Bring static images to life with our Interactive Print templates. Watch videos, images and 3D assets spring from billboards, flyers and books, adding a captivating layer of engagement to traditional print media.',
      cover: 'assets/img/templates/categories/interactive-prints.jpg',
    },
    campaigns: {
      title: 'Select your ideal solution',
      text: 'missing',
      triggers: [
        {
          _id: 'image-tracking',
          title: 'Triggered by images',
          text: 'With this solution you get the ability to showcase multiple products by scanning different images.',
          image: 'image',
          icon: 'image',
          flow: ['vertical', 'horizontal'],
        },
      ],
    },
  },
  packaging: {
    name: 'Packaging',
    account: {
      icon: 'package',
    },
    templates: {
      text: 'Transform ordinary packaging into a memorable experience. Our innovative AR technology adds an interactive and informative layer to product packaging, providing added value and brand connection.',
      cover: 'assets/img/templates/categories/packaging.jpg',
    },
    campaigns: {
      title: 'Select your ideal solution',
      text: 'missing',
      triggers: [
        {
          _id: 'image-tracking',
          title: 'Triggered by images',
          text: 'With this solution you get the ability to showcase multiple products by scanning different images.',
          image: 'image',
          icon: 'image',
          flow: ['vertical', 'horizontal'],
        },
      ],
    },
  },
  'immersive-360': {
    name: 'Immersive 360',
    account: {
      icon: 'language',
    },
    templates: {
      text: 'Envelop your users in a 360° AR experience with our Immersive 360. Showcase content all around them and provide access to further material, creating a fully immersive starting point for your audience.',
      cover: 'assets/img/templates/categories/landing-pages.jpg',
    },
    campaigns: {
      title: 'Select your ideal solution',
      text: 'missing',
      triggers: [
        {
          _id: '360',
          title: 'Displayed all around',
          text: 'Get a fully-immersive experience surrounding your audience with any asset to display your data.',
          image: '360',
          icon: '360',
          flow: [],
        },
      ],
    },
  },
  'data-visualization': {
    name: 'Data Visualization',
    account: {
      icon: 'insights',
    },
    templates: {
      text: 'Visualize complex information effortlessly with our immersive Data Visualization tool. Dive into interactive charts and graphs that make understanding data more accessible and engaging.',
      cover: 'assets/img/templates/categories/data-visualization.jpg',
    },
    campaigns: {
      title: 'Select your ideal solution',
      text: 'A data visualization experience can be provided in different ways. Select the one you prefer.',
      triggers: [
        {
          _id: 'surface',
          title: 'Placed on a surface',
          text: 'With this option your audience will be able to place any 3D data on a surface in the real environment.',
          image: 'surface',
          icon: 'view_in_ar',
          flow: [],
        },
        {
          _id: 'image-tracking',
          title: 'Triggered by images',
          text: 'With this solution you get the ability to showcase multiple data charts by scanning different images.',
          image: 'image',
          icon: 'image',
          flow: ['vertical', 'horizontal'],
        },
        {
          _id: '360',
          title: 'Displayed all around',
          text: 'Get a fully-immersive experience surrounding your audience with any asset to display your data.',
          image: '360',
          icon: '360',
          flow: [],
        },
      ],
    },
  },
  'quizzes-and-surveys': {
    name: 'Quizzes & Surveys',
    account: {
      icon: 'quiz',
    },
    templates: {
      text: 'Gather insights and opinions in a fun and interactive way with our AR Quizzes and Surveys. Engage users with an immersive experience that encourages participation and boosts response rates.',
      cover: 'assets/img/templates/categories/quizzes-and-surveys.jpg',
    },
    campaigns: {
      title: 'Select your ideal solution',
      text: 'Quizzes and surveys experiences can be provided in different ways. Select the one you prefer.',
      triggers: [
        {
          _id: 'face',
          title: 'Controlled by face',
          text: 'Let your audience interact with your campaign using their face as a controller, in a filter-like experience.',
          image: 'face',
          icon: 'sentiment_satisfied',
          flow: [],
        },
        {
          _id: 'image-tracking',
          title: 'Triggered by an image',
          text: 'With this solution you get the ability to activate your quiz/survey by scanning an image.',
          image: 'image',
          icon: 'image',
          flow: ['vertical', 'horizontal'],
        },
        {
          _id: '360',
          title: 'Displayed all around',
          text: 'Get a fully-immersive quiz/survey surrounding your audience with any asset type.',
          image: '360',
          icon: '360',
          flow: [],
        },
      ],
    },
  },
  'virtual-try-on': {
    name: 'Virtual Try-On',
    account: {
      icon: 'face_retouching_natural',
    },
    templates: {
      text: 'Experience the perfect fit with our Virtual Try-On, giving your audience the confidence to make the right purchase. See overlaid 3D elements, making online shopping experience enjoyable and hassle-free.',
      cover: 'assets/img/templates/categories/virtual-try-on.jpg',
    },
    campaigns: {
      title: 'Select your ideal solution',
      text: 'missing',
      triggers: [
        {
          _id: 'face',
          title: 'Controlled by face',
          text: 'Let your audience interact with your campaign using their face as a controller, in a filter-like experience.',
          image: 'face',
          icon: 'sentiment_satisfied',
          flow: [],
        },
      ],
    },
  },
  avatars: {
    name: 'Avatars',
    account: {
      icon: 'accessibility_new',
    },
    templates: {
      text: 'Engage with your audience through our AR Avatars, bridging the gap between virtual and real world. Learn about products or simply connect with others through interactive storytelling and personalized guidance.',
      cover: 'assets/img/templates/categories/3d-avatars.jpg',
    },
    campaigns: {
      title: 'Select your ideal solution',
      text: 'Avatars experiences can be provided in different ways. Select the one you prefer.',
      triggers: [
        {
          _id: 'geolocation',
          title: 'Geolocalized',
          text: 'Let your audience look for the hidden information all around the city in a discovery adventure.',
          image: 'geolocation',
          icon: 'pin_drop',
          flow: ['vertical', 'horizontal'],
        },
        {
          _id: 'image-tracking',
          title: 'Triggered by images',
          text: 'With this solution you get the ability to display multiple avatars by scanning different images.',
          image: 'image',
          icon: 'image',
          flow: ['vertical', 'horizontal'],
        },
        {
          _id: '360',
          title: 'Displayed all around',
          text: 'Get a fully-immersive experience surrounding your audience with multiple avatars and any asset type.',
          image: '360',
          icon: '360',
          flow: [],
        },
        {
          _id: 'surface',
          title: 'Placed on a surface',
          text: 'With this option your audience will be able to place any 3D avatar on a surface in the real environment.',
          image: 'surface',
          icon: 'view_in_ar',
          flow: [],
        },
      ],
    },
  },
  'filters-and-effects': {
    name: 'Filters & Effects',
    account: {
      icon: 'auto_fix',
    },
    templates: {
      text: 'Celebrate special events with our Filters & Effects, adding a touch of fun to your brand. Enhance user experiences with face filters and interactive elements, perfect for holidays, store openings, and more.',
      cover: 'assets/img/templates/categories/face-filters.jpg',
    },
    campaigns: {
      title: 'Select your ideal solution',
      text: 'Filters & effects experiences can be provided in different ways. Select the one you prefer.',
      triggers: [
        {
          _id: 'face',
          title: 'Controlled by face',
          text: 'Let your audience have fun with your engaging campaign using their face as a trigger.',
          image: 'face',
          icon: 'sentiment_satisfied',
          flow: [],
        },
        {
          _id: 'surface',
          title: 'Placed on a surface',
          text: 'With this option your audience will be able to place any 3D object on a surface in the real environment.',
          image: 'surface',
          icon: 'view_in_ar',
          flow: [],
        },
      ],
    },
  },
  'mini-games': {
    name: 'Mini-Games',
    account: {
      icon: 'sports_esports',
    },
    templates: {
      text: 'Dive into the world of Mini-Games, where captivating AR experiences await. Our templates offer immersive and challenging gameplay, providing entertainment for users while promoting brand engagement.',
      cover: 'assets/img/templates/categories/minigames.jpg',
    },
    campaigns: {
      title: 'Select your ideal solution',
      text: 'Mini-games experiences can be provided in different ways. Select the one you prefer.',
      triggers: [
        {
          _id: 'face',
          title: 'Controlled by face',
          text: 'Let your audience interact with your campaign using their face as a controller, in a filter-like experience.',
          image: 'face',
          icon: 'sentiment_satisfied',
          flow: [],
        },
        {
          _id: '360',
          title: 'Displayed all around',
          text: 'Get a fully-immersive mini-game surrounding your audience with any asset type.',
          image: '360',
          icon: '360',
          flow: [],
        },
      ],
    },
  },
  'treasure-hunts': {
    name: 'Treasure Hunts',
    account: {
      icon: 'travel_explore',
    },
    templates: {
      text: 'Embark on exciting Treasure Hunts, with hidden items awaiting for discovery in various locations. Engage with surroundings and unlock exclusive rewards, providing a fun and interactive way to explore.',
      cover: 'assets/img/templates/categories/treasure-hunts.jpg',
    },
    campaigns: {
      title: 'Select your ideal solution',
      text: 'Treasure hunts can be provided in different ways. Select the one you prefer.',
      triggers: [
        {
          _id: 'geolocation',
          title: 'Geolocalized',
          text: 'Let your audience look for the hidden hints all around the city in a discovery adventure.',
          image: 'geolocation',
          icon: 'pin_drop',
          flow: ['vertical', 'horizontal'],
        },
        {
          _id: 'image-tracking',
          title: 'Triggered by images',
          text: 'With this solution you can hide your hints behind different images and create in-store hunts.',
          image: 'image',
          icon: 'image',
          flow: ['vertical', 'horizontal'],
        },
      ],
    },
  },
};
