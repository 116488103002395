import { Injectable } from '@angular/core';
import { StateService } from '@uirouter/core';
import { Observable } from 'rxjs';
import { PaywallTrackingInterface } from 'src/types/services/paywall-tracking';
import { AccountService } from './account.service';
import { ApiService } from './api/api.service';
import { Restify } from './api/restify';

@Injectable({
  providedIn: 'root',
})
export class TrackingService extends Restify<any> {
  constructor(private stateService: StateService, apiService: ApiService, private accountService: AccountService) {
    super('/accounts/:account/tracking', apiService);
  }

  interpolate() {
    return this._basePath.replace(':account', this.accountService.currentAccount._id);
  }

  trackPaywall(content: {
    title: string;
    type: string;
    action?: {
      text?: string;
      ref?: string;
      url?: string;
    };
  }): Observable<PaywallTrackingInterface> {
    const paywallTracking: PaywallTrackingInterface = {
      paywall_title: content.title,
      paywall_slug: content.type,
      created_at: new Date(),
      created_at_route: window.location.pathname,
      cta: content.action
        ? { ...content.action, ref: content.action.ref ? this.stateService.href(content?.action?.ref) : undefined }
        : undefined,
    };

    return content.action
      ? this.apiService.post(`${this.basePath}/cta`, paywallTracking)
      : this.apiService.post(`${this.basePath}/paywall`, paywallTracking);
  }
}
