import { Component, DestroyRef, Input, OnInit, inject } from '@angular/core';
import { StateService } from '@uirouter/core';
import { AccountService } from 'src/app/services/account.service';
import { CommunicationService } from 'src/app/services/account/communication.service';
import { CollaboratorService } from 'src/app/services/collaborator.service';
import { ToastService } from 'src/app/services/toast.service';
import { AryelUserInterface } from 'src/modules/type-definitions/user';
import { ValidatedInvitationInterface } from 'src/types/entities/account';
import { Join } from '../join';

@Component({
  selector: 'aryel-join-logged',
  templateUrl: './join-logged.component.html',
})
export class JoinLoggedComponent extends Join implements OnInit {
  @Input() user: AryelUserInterface = null;
  @Input() invitation: ValidatedInvitationInterface = null;
  @Input() emailHash: string = null;
  @Input() accountId: string = null;
  @Input() invitationToken: string = null;

  loading = false;

  private destroyRef = inject(DestroyRef);

  constructor(
    private accountService: AccountService,
    private collaboratorService: CollaboratorService,
    private stateService: StateService,
    private toastService: ToastService,
    private communicationService: CommunicationService
  ) {
    super();
  }

  ngOnInit(): void {}

  submit() {
    this.loading = true;

    this.collaboratorService
      .join({
        account: this.accountId,
        token: this.invitationToken,
        emailHash: this.emailHash,
      })
      .subscribe(
        () => {
          this.communicationService.stop();
          this.accountService.clearCurrentAccount();
          this.stateService.go('private.choose-account');
        },
        (err) => {
          this.toastService.addToast(err);
        },
        () => (this.loading = false)
      );
  }
}
