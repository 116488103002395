import { Injectable } from '@angular/core';
import { StateService } from '@uirouter/core';
import { AccountService } from './account.service';

@Injectable({
  providedIn: 'root',
})
export class HeaderService {
  hideHeaderWhenState = {
    startsWith: ['auth', 'join'],
    is: [
      'private.account-creation',
      'private.choose-account',
      'private.campaigns.edit',
      'private.billing.upgrade-plan',
      'private.billing.addons',
      'private.campaigns.create',
    ],
  };

  constructor(private stateService: StateService, private accountService: AccountService) {}

  private get currentStateName() {
    return this.stateService.current.name;
  }

  get isVisible() {
    if (!this.accountService?.currentAccount) {
      return false;
    }

    for (const condition of this.hideHeaderWhenState.is) {
      if (condition === this.currentStateName) {
        return false;
      }
    }

    for (const condition of this.hideHeaderWhenState.startsWith) {
      if (this.currentStateName.indexOf(condition) === 0) {
        return false;
      }
    }

    return true;
  }
}
