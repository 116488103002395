<aryel-modal [id]="modalId" heading="Updated Terms and Conditions" size="large">
  <aryel-loading [loading]="loading">
    <div class="tac" [innerHTML]="tacText"></div>
    <div class="spacer spacer--v spacer--xlarge"></div>
    <div class="grid-x align-middle align-center grid-margin-x">
      <div class="cell shrink">
        <aryel-button (click)="accept()">
          Accept
        </aryel-button>
      </div>
    </div>
  </aryel-loading>
</aryel-modal>