import { RouteGuard } from './route-guard';
import { AuthService } from '../services/auth.service';
import { Transition } from '@uirouter/core';
import { UserService } from '../services/user.service';

export class CanResetPasswordRouteGuard extends RouteGuard {
  on: 'onBefore' = 'onBefore';

  hook = {
    to: (state) => state.name.indexOf('auth.reset-password') === 0,
  };

  options = {
    priority: 15,
  };

  callback = async (transition: Transition) => {
    const userService: UserService = transition.injector().get(UserService);
    const { stateService } = transition.router;

    try {
      await userService.canResetPassword(transition.params().usernameHash, transition.params().token).toPromise();

      return null;
    } catch (err) {
      return stateService.target('private');
    }
  };
}
