import { Injectable } from '@angular/core';
import { ApiService } from './api/api.service';

@Injectable({
  providedIn: 'root',
})
export class TermsAndConditionsService {
  constructor(private apiService: ApiService) {}

  acceptTerms(userId: string, tac_id: string) {
    return this.apiService.patch(`/terms-and-conditions/${userId}`, {tac_id});
  }
}