import { animate, style, transition, trigger } from '@angular/animations';
import { Component, DestroyRef, ElementRef, HostListener, OnInit, inject } from '@angular/core';
import { StateService, TransitionService } from '@uirouter/core';
import { CommunicationService } from 'src/app/services/account/communication.service';
import { FeatureService } from 'src/app/services/account/feature.service';
import { CommunicationActionInterface, CommunicationInterface, CommunicationType } from 'src/types/entities/communication';

@Component({
  selector: 'aryel-notifications-menu',
  templateUrl: './notifications-menu.component.html',
  styleUrls: ['./notifications-menu.component.scss'],
  animations: [
    trigger('openClose', [
      transition(':enter', [style({ opacity: 0 }), animate('.200s ease-out', style({ opacity: 1 }))]),
      transition(':leave', [style({ opacity: 1 }), animate('.200s ease-in', style({ opacity: 0 }))]),
    ]),
  ],
})
export class NotificationsMenuComponent implements OnInit {
  notificationIcons: { [K in CommunicationType]: string } = {
    alert: 'error',
    info: 'help',
    success: 'done',
    warning: 'warning',
  };

  showNotifications: boolean = false;

  canOperate: boolean = true;

  timeout: any = null;

  get communications(): CommunicationInterface[] {
    return this.communicationService.communications || [];
  }

  get count(): number {
    return this.communications.length;
  }

  private destroyRef = inject(DestroyRef);

  constructor(
    private _eref: ElementRef,
    private stateService: StateService,
    private featureService: FeatureService,
    private transitionService: TransitionService,
    private communicationService: CommunicationService
  ) {
    this.transitionService.onStart({}, () => {
      this.showNotifications = false;
    });
  }

  ngOnInit(): void {}

  openPanel() {
    if (this.canOperate) {
      this.showNotifications = !this.showNotifications;
      clearTimeout(this.timeout);
      this.canOperate = false;
      this.timeout = setTimeout(() => {
        this.canOperate = true;
      }, 600);
    }
  }

  @HostListener('document:click', ['$event'])
  handleKeyDown(event: MouseEvent) {
    if (this.showNotifications && !this._eref.nativeElement.contains(event.target)) {
      this.showNotifications = false;
    }
  }

  parseAction(action: CommunicationActionInterface): string {
    if (action?.type === 'navigation') {
      if (action.payload.to === 'campaigns') {
        return 'Go to Campaigns';
      }
      if (action.payload.to === 'domains') {
        return 'Go to Domains';
      }
      if (action.payload.to === 'markers') {
        return 'Go to Markers';
      }
      if (action.payload.to === 'collaborators') {
        return 'Go to Collaborators';
      }
    }
    return null;
  }

  markAsRead(communication: CommunicationInterface) {
    this.communicationService.markAsRead(communication).subscribe(
      (res) => {},
      (err) => {}
    );
  }

  operate(communication: CommunicationInterface) {
    const parsedAction = this.parseAction(communication.action);
    if (parsedAction) {
      if (communication.action.type === 'navigation') {
        if (communication.action.payload?.to === 'campaigns') {
          return this.stateService.go('private.campaigns.index');
        } else if (communication.action.payload?.to === 'domains') {
          return this.stateService.go(
            this.featureService.feature<boolean>('brandkits')
              ? 'private.brandkits.domains.index'
              : 'private.brand.domains.index'
          );
        } else if (communication.action.payload?.to === 'markers') {
          return this.stateService.go('private.brand.marker-generator.index');
        } else if (communication.action.payload?.to === 'collaborators') {
          return this.stateService.go('private.users.index');
        }
      }
    }
  }
}
