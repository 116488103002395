import { trigger, transition, style, animate, state } from '@angular/animations';

const scaleFadeIn = (time: string = '.35s') => {
  return transition(':enter', [
    style({ opacity: 0, transform: 'scale(.85)' }),
    animate(`${time} ease-in-out`, style({ opacity: 1, transform: 'scale(1)' })),
  ]);
};
const scaleFadeOut = (time: string = '.35s') => {
  return transition(':leave', [
    style({ opacity: 1, transform: 'scale(1)' }),
    animate(`${time} ease-in-out`, style({ opacity: 0, transform: 'scale(.85)' })),
  ]);
};
export const cardScaleFadeIn = [trigger('cardScaleFadeIn', [scaleFadeIn()])];

export const cardScaleFadeOut = [trigger('cardScaleFadeOut', [scaleFadeOut()])];

export const cardScaleFadeInOut = [trigger('cardScaleFadeInOut', [scaleFadeIn(), scaleFadeOut()])];
export const cardScaleFadeInOutFast = [trigger('cardScaleFadeInOut', [scaleFadeIn('.15s'), scaleFadeOut('.15s')])];

export const headerSlide = [
  trigger('headerSlide', [
    transition(':enter', [
      style({ transform: 'translateY(-100%)' }),
      animate('.35s ease-in-out', style({ transform: 'translateY(0)' })),
    ]),
    transition(':leave', [
      style({ transform: 'translateY(0)' }),
      animate('.35s ease-in-out', style({ transform: 'translateY(-100%)' })),
    ]),
  ]),
];

export const accordion = [
  trigger('accordion', [
    transition(':enter', [
      style({ opacity: 0, height: 0, overflow: 'hidden' }),
      animate('.35s ease-in-out', style({ opacity: 1, height: '*' })),
    ]),
    transition(':leave', [
      style({ opacity: 1, height: '*', overflow: 'hidden' }),
      animate('.35s', style({ opacity: 0, height: 0 })),
    ]),
  ]),
];

export const enterExitSlide = [
  trigger('enterExitSlide', [
    transition(':enter', [
      style({ opacity: 0, position: 'absolute', top: 0, left: 0, transform: 'translateX(150px)' }),
      animate('.6s cubic-bezier(0.82, 0.02, 0.08, 1.01)', style({ opacity: 1, transform: 'translateX(0)' })),
    ]),
    transition(':leave', [
      style({ opacity: 1, position: 'absolute', top: 0, left: 0, transform: 'translateX(0)' }),
      animate('.6s cubic-bezier(0.82, 0.02, 0.08, 1.01)', style({ opacity: 0, transform: 'translateX(-150px)' })),
    ]),
  ]),
];
