import { Component, DestroyRef, EventEmitter, Input, OnInit, Output, inject } from '@angular/core';
import { StateService } from '@uirouter/core';
import { BreadcrumbsInterface } from 'src/types/components/layout/breadcrumbs';

@Component({
  selector: 'aryel-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss'],
})
export class BreadcrumbsComponent implements OnInit {
  @Input() back: boolean;
  @Input() breadcrumbs: BreadcrumbsInterface[];
  @Input() active: string;
  @Input() manual: boolean;

  @Output() onBread: EventEmitter<BreadcrumbsInterface> = new EventEmitter();

  private destroyRef = inject(DestroyRef);

  constructor(private stateService: StateService) {}

  ngOnInit(): void {}

  goBack() {
    const last = this.breadcrumbs[this.breadcrumbs.length - 1];
    if (this.manual) {
      return this.onBread.emit(last);
    }
    this.stateService.go(last.state, last.params);
  }
}
