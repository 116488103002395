import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AryelAccountBillingInterface } from 'src/modules/type-definitions/account';
import { AryelBillingInterface } from 'src/modules/type-definitions/contact';
import { ReportsInterface } from 'src/types/components/pages/reports';
import { AccountInterface, InvitationTicketInterface } from 'src/types/entities/account';
import { AddonsPlanInterface } from 'src/types/entities/plan';
import { PreviewPurchaseInterface, PurchaseInterface } from 'src/types/entities/purchase';
import { StripeInvoicePreview } from 'src/types/entities/stripe';
import { SubscriptionInterface } from 'src/types/entities/subscription';
import { PaymentProvider } from '../../modules/type-definitions/generics';
import { ApiService } from './api/api.service';
import { Restify } from './api/restify';
import { CustomerlyService } from './integrations/customerly.service';
import { LocalStorageService } from './local-storage.service';
import { MeService } from './me.service';

@Injectable({
  providedIn: 'root',
})
export class AccountService extends Restify<AccountInterface> {
  private _currentPendingSubscription: BehaviorSubject<SubscriptionInterface> = new BehaviorSubject(null);

  get currentPendingSubscription() {
    return this._currentPendingSubscription;
  }

  get currentAccount(): AccountInterface {
    return this.localStorageService.getItem('currentAccount') || null;
  }

  get currentAccountBaseRoute(): string {
    return `${this.basePath}/${this.currentAccount._id}`;
  }

  interpolate() {
    return this._basePath;
  }

  constructor(
    protected apiService: ApiService,
    private localStorageService: LocalStorageService,
    private meService: MeService,
    private customerlyService: CustomerlyService
  ) {
    super('/accounts', apiService);
  }

  setCurrentAccount(account: AccountInterface): Observable<AccountInterface> {
    return this.get(account._id).pipe(
      tap((account) => {
        this.localStorageService.setItem('currentAccount', account);
        if (this.meService.me) {
          this.customerlyService.updateAccounts(this.meService.me, account);
        }
        return account;
      })
    );
  }

  refreshCurrentAccount() {
    if (this.currentAccount) {
      return this.setCurrentAccount(this.currentAccount);
    }
    return of(null);
  }

  fullAccount() {
    return this.get(`${this.currentAccount._id}?full=true`);
  }

  setPendingSubscription(subscription: SubscriptionInterface) {
    this._currentPendingSubscription.next(subscription);
  }

  join(invitationTicket: Omit<InvitationTicketInterface, 'emailHash'>) {
    return this.apiService.post(`${this.currentAccountBaseRoute}/collaborators`, {
      token: invitationTicket.token,
    });
  }

  clearCurrentAccount() {
    return this.localStorageService.removeItem('currentAccount');
  }

  updateAccountBilling(options: AryelBillingInterface) {
    return this.apiService.patch(`${this.currentAccountBaseRoute}/billing`, options);
  }

  pendingSubscriptions(): Observable<SubscriptionInterface[]> {
    return this.apiService.get(`${this.currentAccountBaseRoute}/pending-subscriptions`);
  }

  removePendingSubscriptions() {
    return this.apiService.delete(`${this.currentAccountBaseRoute}/pending-subscriptions`);
  }

  removePromo() {
    return this.apiService.delete(`${this.currentAccountBaseRoute}/subscription/discount`);
  }

  /**
   * Statistics management
   */

  statistics(params: any = {}): Observable<ReportsInterface> {
    const account = (this.currentAccount || { _id: '' })._id;
    const filters: any = { ...params };
    let campaignsFilter: string = '';
    if (filters.campaigns?.length) {
      filters.campaigns.forEach((cmp) => {
        campaignsFilter += `${!campaignsFilter ? '?' : '&'}campaigns[]=${cmp}`;
      });
      delete filters.campaigns;
    }
    return this.apiService.get(`${this.basePath}/${account}/statistics${campaignsFilter}`, {
      params: { ...filters },
    });
  }

  statisticsCSV(params: any = {}): Observable<HttpResponse<Blob>> {
    const account = (this.currentAccount || { _id: '' })._id;
    const filters: any = { ...params };
    let campaignsFilter: string = '';
    if (filters.campaigns?.length) {
      filters.campaigns.forEach((cmp) => {
        campaignsFilter += `${!campaignsFilter ? '?' : '&'}campaigns[]=${cmp}`;
      });
      delete filters.campaigns;
    }
    return this.apiService.get(`${this.basePath}/${account}/statistics/csv${campaignsFilter}`, {
      params: { ...filters },
      responseType: 'blob',
      observe: 'response',
    });
  }

  activeSessions(params: { campaigns?: string[] } = {}): Observable<{
    campaigns: { _id: string; users: number }[];
    users: number;
  }> {
    const account = (this.currentAccount || { _id: '' })._id;

    let campaigns: string = '';
    if (params.campaigns?.length) {
      campaigns = '?' + params.campaigns.map((c) => `campaigns[]=${c}`).join('&');
    }

    return this.apiService.get(`${this.basePath}/${account}/statistics/active-sessions${campaigns}`);
  }

  /**
   * Domains management
   */

  getDomainTicket(domain: string) {
    return this.apiService.post(`${this.currentAccountBaseRoute}/domains/ticket`, {
      domain,
    });
  }

  addDomain(ticket: string) {
    return this.apiService.post(`${this.currentAccountBaseRoute}/domains`, {
      ticket,
    });
  }

  deleteDomain(domainId: string) {
    return this.apiService.delete(`${this.currentAccountBaseRoute}/domains/${domainId}`);
  }

  scheduleDomain(domain: string) {
    return this.apiService.post(`${this.currentAccountBaseRoute}/domains/${domain}/schedules`);
  }

  unscheduleDomain(domain: string, schedule: string, action: string = 'delete') {
    return this.apiService.delete(`${this.currentAccountBaseRoute}/domains/${domain}/schedules/${schedule}`);
  }

  /**
   * @param paymentMethod the payment method id given by the provider
   * @returns
   */
  createPaymentMethod<T extends PaymentProvider>(
    paymentMethod: string
  ): Observable<
    Extract<
      {
        provider: 'stripe';
        setup_intent: string;
        customer: string;
        client_secret: string;
        payment_method: string;
      },
      { provider: T }
    >
  > {
    return this.apiService.post(`${this.currentAccountBaseRoute}/payment-method`, {
      provider: 'stripe',
      payment_method: paymentMethod,
    });
  }

  /**
   * Coupon management
   */

  verifyCoupon(accountId: string, coupon: string) {
    return this.get(`${accountId}/coupons/${coupon}`);
  }

  // TODO: do better
  redeemCoupon(accountId: string, couponId: string) {
    return this.apiService.post(`${this.basePath}/stripe/${accountId}/redeem-coupon`, {
      coupon: couponId,
    });
  }

  /**
   * Purchases and debts management
   */

  purchases(): Observable<PurchaseInterface[]> {
    return this.apiService.get(`${this.currentAccountBaseRoute}/purchases`);
  }

  debts(): Observable<PurchaseInterface[]> {
    return this.apiService.get(`${this.currentAccountBaseRoute}/debts`);
  }

  previewPurchase(data: PreviewPurchaseInterface = {}): Observable<StripeInvoicePreview> {
    return this.apiService.post(`${this.currentAccountBaseRoute}/preview-purchase`, data);
  }

  // subscriptionBill() {
  //   return this.apiService.get(`${this.currentAccountBaseRoute}/subscription-cost`);
  // }

  /**
   * Plan management
   */

  plan(): Observable<AddonsPlanInterface> {
    return this.apiService.get(`${this.currentAccountBaseRoute}/plan`);
  }

  /**
   * Features management
   */

  featuresExceed(): Observable<{ campaigns: number; collaborators: number; domains: number; markers: number }> {
    return this.apiService.get(`${this.currentAccountBaseRoute}/subscription/delta?next_renewal=true`);
  }

  /**
   * Ask a content
   */
  askContent(data: FormData) {
    return this.apiService.post(`${this.currentAccountBaseRoute}/ask-content`, data);
  }

  /**
   * Billing info editing
   */

  updateBillingInfo(data: {
    billing: AryelAccountBillingInterface;
    all_purchases?: boolean;
    purchase?: string;
    set_as_default_billing?: boolean;
  }) {
    return this.apiService.patch(`${this.currentAccountBaseRoute}/billing`, data);
  }

  leaveAccount(accountId: string) {
    return this.apiService.delete(`${this.basePath}/${accountId}/me`);
  }
}
