<div class="notifications">
  <div class="notifications__opener" (click)="openPanel()">
    <span *ngIf="count" class="notifications__counter">
      {{ count > 9 ? '9+' : count }}
    </span>
    <ng-content></ng-content>
  </div>
  <div class="notifications__content" *ngIf="showNotifications" [@openClose]>
    <div class="notifications__body scrollbar scrollbar--v">
      <ng-container
        *ngIf="communications?.length; else noCommunicationsTemplate"
      >
        <div
          class="grid-x notifications__single align-middle"
          *ngFor="let communication of communications"
        >
          <div class="cell shrink">
            <span
              class="material-symbols-outlined"
              [ngClass]="'color-' + communication.type"
            >
              {{ notificationIcons[communication.type] }}
            </span>
          </div>
          <div class="cell auto notifications__data">
            <p class="cell notifications__abstract small">
              {{ communication.abstract }}
            </p>
            <p class="cell notifications__title">{{ communication.title }}</p>
            <p class="cell notifications__text small">
              {{ communication.description }}
            </p>
            <div class="grid-x align-right notifications__actions">
              <div class="cell shrink">
                <aryel-link
                  size="small"
                  [bold]="true"
                  (click)="markAsRead(communication)"
                  >Dismiss</aryel-link
                >
              </div>
              <div class="cell shrink notifications__action">
                <aryel-link
                  *ngIf="
                    communication.action && parseAction(communication.action)
                  "
                  size="small"
                  [bold]="true"
                  (click)="operate(communication)"
                >
                  {{ parseAction(communication.action) }}
                </aryel-link>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>

<ng-template #noCommunicationsTemplate>
  <div class="notifications__empty">
    <p class="h5 text-center">There are no notifications</p>
  </div>
</ng-template>
