import { RouteGuard } from './route-guard';
import { AccountService } from '../services/account.service';
import { Transition } from '@uirouter/core';
import { UserSummaryInterface } from 'src/types/entities/user';
import { AccountInterface } from 'src/types/entities/account';
import { MeService } from '../services/me.service';
import funnelParams from '../helpers/parsers/funnel';
import { CommunicationService } from '../services/account/communication.service';
export class AccountStateRouteGuard extends RouteGuard {
  on: 'onBefore' = 'onBefore';

  hook = {
    from: (state) => {
      return state.name === '' || state.name === 'auth.sign-in';
    },
    to: (state) =>
      state.name !== 'private.account-creation' &&
      state.name !== 'private.choose-account' &&
      state.name.indexOf('join') !== 0 &&
      state.name.indexOf('auth') !== 0 &&
      state.name.indexOf('backoffice') !== 0,
  };

  options = {
    priority: 10,
  };

  callback = async (transition: Transition) => {
    const meService: MeService = transition.injector().get(MeService);
    const accountService: AccountService = transition.injector().get(AccountService);
    const communicationService: CommunicationService = transition.injector().get(CommunicationService);
    const { stateService } = transition.router;

    const params = funnelParams(stateService);

    let me: UserSummaryInterface = null;
    try {
      me = await meService.getMe().toPromise();
    } catch (err) {
      return stateService.target('auth', params);
    }

    // Removed to avoid creating account for collaborator users
    // if ((!me.ownedAccount || !me.ownedAccount.subscription) && (!me.managedAccounts || !me.managedAccounts.length)) {
    //   return stateService.target('private.account-creation', params);
    // }

    if (!accountService.currentAccount) {
      return stateService.target('private.choose-account', params);
    }

    let ownedAccount: AccountInterface | string = meService.me.ownedAccount;
    if (typeof ownedAccount === 'object' && ownedAccount._id) {
      ownedAccount = ownedAccount._id;
    }

    if (
      accountService.currentAccount._id !== ownedAccount &&
      !accountService.currentAccount.collaborators.find((u) => u.user._id === meService.me._id)
    ) {
      communicationService.stop();
      accountService.clearCurrentAccount();
      return stateService.target('private.choose-account', params);
    }

    return null;
  };
}
