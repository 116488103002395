<div class="toasts">
  <div
    class="toasts__single"
    @items
    *ngFor="let toast of toasts; index as index; trackBy: trackToasts"
  >
    <aryel-toast
      [title]="toast.title"
      [text]="toast.text"
      [status]="toast.status"
      [action]="toast.action"
      [removable]="toast.removable"
      [timestamp]="toast.timestamp"
      (onRemove)="remove($event)"
    ></aryel-toast>
  </div>
</div>
