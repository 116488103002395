import { Injectable } from '@angular/core';
import 'src/app/libs/image-tracking-compiler/v1/aryel-it-compiler.prod.js';
import 'src/app/libs/image-tracking-compiler/v2/compiler.js';
import { ITTargetGeneratorV1Service } from './it-target-generator-v1.service';
import { ITTargetGeneratorV2Service } from './it-target-generator-v2.service';
import { CompileInput } from './it-target-generator.abstract';
import { EditCampaignImageTrackingInterface } from '../../../types/entities/campaign';

interface PrepareInput {
  v1: EditCampaignImageTrackingInterface;
  v2: EditCampaignImageTrackingInterface;
}

@Injectable({
  providedIn: 'root',
})
export class ITTargetGeneratorService {
  compiler: {
    v1?: ITTargetGeneratorV1Service;
    v2?: ITTargetGeneratorV2Service;
  } = {};

  get process() {
    return this.itTargetGeneratorV1Service.process;
  }

  constructor(
    private itTargetGeneratorV1Service: ITTargetGeneratorV1Service,
    private itTargetGeneratorV2Service: ITTargetGeneratorV2Service,
  ) {
    this.compiler.v1 = this.itTargetGeneratorV1Service;
    this.compiler.v2 = this.itTargetGeneratorV2Service;
  }

  compile(input: CompileInput, version: 'v1' | 'v2') {
    return this.compiler[version].compile(input);
  }

  prepareInput<T extends 'v1' | 'v2'>(version: T, input: PrepareInput[T]) {
    return this.compiler[version].prepareInput(input);
  }

  isCurrentCampaignOnHold(id: string) {
    return this.itTargetGeneratorV1Service.isCurrentCampaignOnHold(id);
  }

  setCompiler(version: 'v1' | 'v2') {
    return this.compiler[version].setCompiler();
  }

  getProcess(v: 'v1' | 'v2') {
    return this.compiler[v].process;
  }

  /**
   * Only for v1
   */
  complete() {
    window.removeEventListener('beforeunload', this.preventClose);
    this.process.next(null);

    this.setCompiler('v1');
  }

  /**
   * Only for v1
   */
  preventClose(event: BeforeUnloadEvent) {
    event.preventDefault();
    event.returnValue = '';
    return;
  }

  /**
   * Only for v1
   */
  completedWithSuccess() {
    if (this.process.value) {
      this.process.next({
        ...this.process.value,
        success: true,
      });
    }
  }
}
