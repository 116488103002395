import { animate, style, transition, trigger } from '@angular/animations';
import { Component, DestroyRef, ElementRef, HostListener, Input, inject } from '@angular/core';
import { StateService, TransitionService } from '@uirouter/core';
import { NavElementInterface } from 'src/types/components/layout/header';
import { PermissionsService } from '../../collaborators-permissions/permissions.service';

@Component({
  selector: 'aryel-nav-element',
  templateUrl: './nav-element.component.html',
  styleUrls: ['./nav-element.component.scss'],
  animations: [
    trigger('openClose', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateX(-50%) translateY(-1rem)' }),
        animate('.200s ease-out', style({ opacity: 1, transform: 'translateX(-50%) translateY(0rem)' })),
      ]),
      transition(':leave', [
        style({ opacity: 1, transform: 'translateX(-50%) translateY(0rem)' }),
        animate('.200s ease-in', style({ opacity: 0, transform: 'translateX(-50%) translateY(-1rem)' })),
      ]),
    ]),
  ],
})
export class NavElementComponent {
  @Input() nav: NavElementInterface;
  open = false;

  timeout: any;
  canOperate = true;

  private destroyRef = inject(DestroyRef);

  constructor(
    private _eref: ElementRef,
    private stateService: StateService,
    private transitionService: TransitionService,
    private permissionsService: PermissionsService
  ) {
    this.transitionService.onStart({}, () => {
      this.open = false;
    });
  }

  get activeChild() {
    if (!this.nav.parentRef || !this.nav.children || !this.nav.children.length) {
      return false;
    }
    return this.stateService.current.name.includes(this.nav.parentRef);
  }

  navElementTrigger() {
    if (this.canOperate) {
      this.open = !this.open;
      clearTimeout(this.timeout);
      this.canOperate = false;
      this.timeout = setTimeout(() => {
        this.canOperate = true;
      }, 250);
    }
  }

  @HostListener('document:click', ['$event'])
  handleKeyDown(event: MouseEvent) {
    if (this.open && !this._eref.nativeElement.contains(event.target)) {
      this.open = false;
    }
  }

  public checkActiveStatus(navElement: NavElementInterface): boolean {
    return this.stateService.current.name === navElement.ref;
  }

  public navigateTo(navElement: NavElementInterface): void {
    if (!this.permissionsService.isElementRestricted(navElement.restriction)) {
      this.stateService.go(navElement.ref);
    }
  }
}
