import { Injectable } from '@angular/core';
import { ApiService } from './api/api.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private apiService: ApiService) {}

  forgotUsername(email: string) {
    return this.apiService.get(`/users/${email}/username`);
  }

  reserveResetPassword(username: string) {
    return this.apiService.patch(`/users/${username}/reset-password-token`);
  }

  canResetPassword(username: string, token: string) {
    return this.apiService.get(`/users/${username}/reset-password-token/${token}`);
  }

  resetPassword(username: string, token: string, password: string) {
    return this.apiService.patch(`/users/${username}/reset-password-token/${token}`, {
      password,
    });
  }
}
