<ng-content *ngIf="isDesktop || disabled; else splash"> </ng-content>
<ng-template #splash>
  <div class="splash">
    <div class="splash__logo text-center">
      <img src="assets/img/brand/logo-white.png" class="logo" alt="Aryel" />
    </div>
    <div class="text-center splash__content">
      <img class="splash__img" [src]="'assets/img/illustrations/alas.png'" />
      <div class="spacer spacer--v spacer--xxlarge"></div>
      <h1 class="color-white splash__title title">Alas!</h1>
      <h4 class="color-white splash__text">
        We’re working to export this area on mobile as well. For now, please
        sign in from desktop.
      </h4>
    </div>
  </div>
</ng-template>
