<button
  [type]="type"
  class="button not-selectable"
  [class.button--disabled]="disabled"
  [class.button--full]="full"
  [class.button--leftIcon]="!!leftIcon"
  [class.button--rightIcon]="!!rightIcon"
  [class.button--focusIcon]="!!focusIcon"
  [ngClass]="[
    'button--' + status,
    'button--size-' + size,
    !!leftIcon || !!rightIcon ? 'grid-x align-middle' : ''
  ]"
  [disabled]="disabled"
  (click)="onClick()"
>
  <span
    class="button__icon button__left-icon material-symbols-outlined cell shrink"
    [class.button__left-icon--focus]="!!focusIcon"
    *ngIf="leftIcon"
  >
    {{ leftIcon }}
  </span>
  <span class="button__text cell shrink">
    <ng-content></ng-content>
  </span>
  <span
    class="button__icon button__right-icon material-symbols-outlined cell shrink"
    [class.button__right-icon--focus]="!!focusIcon"
    *ngIf="rightIcon"
  >
    {{ rightIcon }}
  </span>
</button>
