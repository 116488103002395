import { Injectable } from '@angular/core';
import { Subscription, timer } from 'rxjs';
import { flatMap, tap } from 'rxjs/operators';
import { CommunicationInterface } from 'src/types/entities/communication';
import { AccountService } from '../account.service';
import { ApiService } from '../api/api.service';

@Injectable({
  providedIn: 'root',
})
export class CommunicationService {
  private _basePath: string = '/accounts/:account/communications';

  private timer: Subscription = null;

  communications: CommunicationInterface[] = [];

  private get basePath() {
    return this._basePath.replace(':account', this.accountService.currentAccount?._id || '');
  }

  constructor(protected apiService: ApiService, protected accountService: AccountService) {}

  init(): void {
    this.stop();
    this.timer = timer(0, 1000 * 120)
      .pipe(
        flatMap(() => this.apiService.get(`${this.basePath}`)),
        tap((communications: CommunicationInterface[]) => {
          this.communications = communications;
        })
      )
      .subscribe();
  }

  markAsRead(communication: CommunicationInterface) {
    return this.apiService.patch(`${this.basePath}/${communication._id}`).pipe(
      tap(() => {
        const i = this.communications.findIndex((x) => x._id === communication._id);
        if (i >= 0) {
          this.communications.splice(i, 1);
        }
      })
    );
  }

  stop() {
    if (this.timer) {
      this.timer.unsubscribe();
      this.communications = [];
    }
  }
}
