import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { Transition, UIRouterModule } from '@uirouter/angular';
import { ForgotPasswordCardComponent } from 'src/app/components/compounds/forgot-password-card/forgot-password-card.component';
import { ForgotUsernameCardComponent } from 'src/app/components/compounds/forgot-username-card/forgot-username-card.component';
import { FormFieldModule } from 'src/app/components/form/form-field/form-field.module';
import { InputModule } from 'src/app/components/form/input/input.module';
import { CardModule } from 'src/app/components/layout/card/card.module';
import { LoadingModule } from 'src/app/components/layout/loading/loading.module';
import { RequirementsModule } from 'src/app/components/layout/requirements/requirements.module';
import { ButtonModule } from 'src/app/components/ui/button/button.module';
import { LinkModule } from 'src/app/components/ui/link/link.module';
import { AuthService } from 'src/app/services/auth.service';
import { MeService } from 'src/app/services/me.service';
import { JoinLoggedComponent } from './join-logged/join-logged.component';
import { JoinSignInComponent } from './join-sign-in/join-sign-in.component';
import { JoinSignUpComponent } from './join-sign-up/join-sign-up.component';
import { JoinTeamComponent } from './join-team/join-team.component';
import { JoinComponent } from './join.component';

const commonRoutingOptions = {
  redirectTo: async (transition) => {
    if (transition.params().invitation) {
      return null;
    }

    const { accountId, emailHash, invitationToken } = transition.params();
    const authService: AuthService = transition.injector().get(AuthService);

    let invitation = null;
    try {
      invitation = await authService.validateInvitation(accountId, emailHash, invitationToken).toPromise();
    } catch (err) {
      return 'auth.sign-in';
    }

    return {
      name: 'join.team',
      params: {
        accountId,
        emailHash,
        invitation,
        invitationToken,
      },
    };
  },
};

@NgModule({
  declarations: [JoinComponent, JoinSignInComponent, JoinSignUpComponent, JoinLoggedComponent, JoinTeamComponent],
  imports: [
    CommonModule,
    UIRouterModule.forChild({
      states: [
        {
          name: 'join.team',
          url: '/:emailHash/accounts/:accountId/invitations/:invitationToken',
          data: {
            title: 'Join Team',
          },
          component: JoinTeamComponent,
          ...commonRoutingOptions,
          resolve: [
            {
              token: 'accountId',
              deps: [Transition],
              resolveFn: (transition: Transition) => {
                return transition.params().accountId;
              },
            },
            {
              token: 'emailHash',
              deps: [Transition],
              resolveFn: (transition: Transition) => {
                return transition.params().emailHash;
              },
            },
            {
              token: 'invitation',
              deps: [Transition],
              resolveFn: (transition: Transition) => {
                return transition.params().invitation;
              },
            },
            {
              token: 'invitationToken',
              deps: [Transition],
              resolveFn: (transition: Transition) => {
                return transition.params().invitationToken;
              },
            },
            {
              token: 'user',
              deps: [MeService],
              resolveFn: async (meService: MeService) => {
                let user = null;
                try {
                  user = await meService.getMe().toPromise();
                } catch (err) {}

                return user;
              },
            },
          ],
          params: {
            accountId: null,
            emailHash: null,
            invitation: null,
            invitationToken: null,
          },
        },
        {
          name: 'join.team.forgot-password',
          url: '/forgot-password',
          data: {
            title: 'Forgot Password',
          },
          ...commonRoutingOptions,
          component: ForgotPasswordCardComponent,
        },
        {
          name: 'join.team.forgot-username',
          url: '/forgot-username',
          data: {
            title: 'Forgot Username',
          },
          ...commonRoutingOptions,
          component: ForgotUsernameCardComponent,
        },
      ],
    }),
    CardModule,
    LinkModule,
    InputModule,
    ButtonModule,
    LoadingModule,
    RequirementsModule,
    FormFieldModule,
    ReactiveFormsModule,
  ],
})
export class JoinModule {}
