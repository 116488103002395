import { AfterContentInit, Component, DestroyRef, ElementRef, Input, ViewChild, inject } from '@angular/core';

@Component({
  selector: 'aryel-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
})
export class CardComponent implements AfterContentInit {
  @Input() dark = false;
  @Input() size: 'small' | 'normal' | 'square' = 'normal';
  @Input() disablePadding: boolean;
  @Input() disableDivider: boolean;

  @ViewChild('cardHead', { static: true }) head: ElementRef;

  private destroyRef = inject(DestroyRef);

  constructor() {}

  ngAfterContentInit() {
    if (!this.head.nativeElement.innerHTML && !this.head.nativeElement.text) {
      this.head.nativeElement.classList.add('card__head--hide');
    }
  }
}
