import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { UIRouterModule } from '@uirouter/angular';
import { FormFieldModule } from '../../form/form-field/form-field.module';
import { InputModule } from '../../form/input/input.module';
import { CardModule } from '../../layout/card/card.module';
import { LoadingModule } from '../../layout/loading/loading.module';
import { ButtonModule } from '../../ui/button/button.module';
import { LinkModule } from '../../ui/link/link.module';
import { ForgotUsernameCardComponent } from './forgot-username-card.component';

@NgModule({
  declarations: [ForgotUsernameCardComponent],
  imports: [
    CommonModule,
    UIRouterModule,
    CardModule,
    LoadingModule,
    InputModule,
    ButtonModule,
    LinkModule,
    ReactiveFormsModule,
    FormFieldModule,
  ],
  exports: [ForgotUsernameCardComponent],
})
export class ForgotUsernameCardModule {}
