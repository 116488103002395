import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, DestroyRef, EventEmitter, OnInit, Output, inject } from '@angular/core';
import { StateService } from '@uirouter/core';
import { LockedFeatureService } from 'src/app/services/locked-feature.service';
import { TrackingService } from 'src/app/services/tracking.service';
import { LockedFeatureModalContentInterface } from 'src/types/components/layout/locked-feature-modal';

@Component({
  selector: 'aryel-locked-feature-modal',
  templateUrl: './locked-feature-modal.component.html',
  styleUrls: ['./locked-feature-modal.component.scss'],
  animations: [
    trigger('carouselAnimation', [
      state(
        'current',
        style({
          opacity: 1,
        })
      ),
      state(
        'hidden',
        style({
          opacity: 0,
        })
      ),
      transition('* => current', [animate('200ms')]),
      transition('* => hidden', [animate('200ms')]),
    ]),
  ],
})
export class LockedFeatureModalComponent implements OnInit {
  content: Partial<LockedFeatureModalContentInterface> = {};
  currentCarouselSlide: number = 0;

  @Output() onAccept: EventEmitter<void> = new EventEmitter();
  @Output() onDismiss: EventEmitter<void> = new EventEmitter();

  get icon() {
    return this.content?.icon || 'assets/img/plans/pro.png';
  }

  get show() {
    return this.lockedFeatureService.isOpen;
  }

  get isWide(): boolean {
    return this.content.type
      ? ![
          'enterprise-limit-campaigns',
          'enterprise-limit-seats',
          'partner-program',
          'domains-limit',
          'stylekits-limit',
        ].includes(this.content.type)
      : false;
  }

  private destroyRef = inject(DestroyRef);

  constructor(
    private lockedFeatureService: LockedFeatureService,
    private stateService: StateService,
    private trackingService: TrackingService
  ) {}

  ngOnInit(): void {
    this.lockedFeatureService.getContent.subscribe((content) => {
      this.currentCarouselSlide = 0;
      this.content = content;
    });
  }

  dismiss() {
    if (this.content?.onDismiss) {
      this.content.onDismiss();
    }
    this.checkRedirect();
    this.close();
  }

  checkRedirect(): void {
    if (this.content?.redirect) {
      this.stateService.go(this.content.redirect);
    }
  }

  close() {
    this.lockedFeatureService.close();
  }

  handleCta(modalSide: 'left' | 'right') {
    if (this.content?.onAccept) {
      this.content.onAccept();
    }

    // ADD TRACKING FOR PAYWALLS
    this.trackingService
      .trackPaywall({
        title: this.content.left.title,
        type: this.content.type,
        action: modalSide === 'left' ? this.content.left.cta : this.content.right.cta,
      })
      .subscribe();

    if (modalSide === 'right') {
      if (this.content.right.cta.ref) {
        this.stateService.go(this.content.right.cta.ref);
      } else if (this.content.right.cta.url) {
        window.open(this.content.right.cta.url, '_blank');
      } else {
        this.close();
        return;
      }
    } else {
      if (this.content.left.cta.ref) {
        this.stateService.go(this.content.left.cta.ref);
      } else if (this.content.left.cta.url) {
        window.open(this.content.left.cta.url, '_blank');
      } else {
        this.close();
        return;
      }
    }

    this.checkRedirect();

    this.close();
  }

  handleLink(modalSide: 'left' | 'right') {
    if (modalSide === 'right') {
      window.open(this.content.right.link.url, '_blank');
    } else {
      window.open(this.content.left.link.url, '_blank');
    }
  }
}
