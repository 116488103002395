import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DomService {
  private prefix: string = 'aryel-data-';

  constructor(@Inject(DOCUMENT) private document: Document) {}

  addAttribute(name: string, value: string) {
    this.document.body.setAttribute(`${this.prefix}${name}`, value);
  }

  removeAttribute(name: string) {
    return this.document.body.removeAttribute(`${this.prefix}${name}`);
  }

  getAttribute(name: string) {
    return this.document.body.getAttribute(`${this.prefix}${name}`);
  }
}
