import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { CampaignStatus, CampaignType, SceneTrigger } from 'src/modules/type-definitions/campaign';
import { CampaignInterface } from 'src/types/entities/campaign';
import { PaginationParamsInterface } from 'src/types/helpers/pagination';
import { AccountService } from '../account.service';
import { ApiService } from '../api/api.service';
import { Restify } from '../api/restify';

@Injectable({
  providedIn: 'root',
})
export class CampaignService extends Restify<CampaignInterface & { views?: number }> {
  private _fromBlankCreateModal: BehaviorSubject<{
    opened: boolean;
    preselect?: {
      category_name: string;
      category_value: string;
    };
  }> = new BehaviorSubject({
    opened: false,
    preselect: null,
  });

  interpolate() {
    return this._basePath.replace(':account', this.accountService.currentAccount._id);
  }

  constructor(protected apiService: ApiService, protected accountService: AccountService) {
    super('/accounts/:account/campaigns', apiService);
  }

  get fromBlankCreateModal(): Subject<{
    opened: boolean;
    preselect?: {
      category_name: string;
      category_value: string;
    };
  }> {
    return this._fromBlankCreateModal;
  }

  updateFromBlankStatus(status: {
    opened: boolean;
    preselect?: {
      category_name: string;
      category_value: string;
    };
  }) {
    this._fromBlankCreateModal.next({ ...status });
  }

  list(
    params: PaginationParamsInterface<{
      search?: string;
      trigger?: SceneTrigger;
      type?: CampaignType;
      status?: CampaignStatus[];
    }> = {},
    expand: 'total_views'[] = []
  ): Observable<HttpResponse<(CampaignInterface & { views?: number })[]>> {
    const query = Object.keys(params)
      .filter((key) => params[key] ?? params[key])
      .map((key) => {
        if (key === 'status') {
          return params[key].map((status) => encodeURIComponent(key) + '[]=' + encodeURIComponent(status)).join('&');
        } else {
          return encodeURIComponent(key) + '=' + encodeURIComponent(params[key]);
        }
      });
    if (expand.length) {
      query.push(...expand.map((x) => `expand[]=${x}`));
    }
    return this.apiService.get(`${this.basePath}/${query.length ? '?' + query.join('&') : ''}`, { observe: 'response' });
  }

  getWithStylekit(campaignId: string) {
    return this.apiService.get(`${this.basePath}/${campaignId}/?withStylekit=true`);
  }

  duplicate(campaignId: string) {
    return this.apiService.post(`${this.basePath}/${campaignId}/duplicate`);
  }

  getDraftToken(campaignId: string) {
    return this.apiService.get(`${this.basePath}/${campaignId}/draft-token`);
  }

  createDraftToken(campaignId: string) {
    return this.apiService.post(`${this.basePath}/${campaignId}/draft-token`);
  }

  schedule(campaignId: string, action: 'archive' = 'archive') {
    return this.apiService.post(`${this.basePath}/${campaignId}/schedules`, { action });
  }

  unschedule(campaignId: string, schedule: string, action: 'archive' = 'archive') {
    return this.apiService.post(`${this.basePath}/${campaignId}/schedules/${schedule}`);
  }

  updateCover(campaignId: string, data: FormData) {
    return this.apiService.post(`${this.basePath}/${campaignId}/cover`, data);
  }

  unbindTriggers(campaignId: string): Observable<any> {
    return this.apiService.delete(`${this.basePath}/${campaignId}/triggers/it-resource-unbind`);
  }
}
