import { animate, style, transition, trigger } from '@angular/animations';
import { Component, DestroyRef, EventEmitter, Input, OnInit, Output, inject } from '@angular/core';

@Component({
  selector: 'aryel-form-field',
  templateUrl: './form-field.component.html',
  styleUrls: ['./form-field.component.scss'],
  animations: [
    trigger('showError', [
      transition(':enter', [style({ height: 0 }), animate('.100s ease-out', style({ height: '*' }))]),
      transition(':leave', [style({ height: '*' }), animate('.100s ease-in', style({ height: 0 }))]),
    ]),
  ],
})
export class FormFieldComponent implements OnInit {
  @Input() placeholder: string;
  @Input() errors: any | null;
  @Input() validate: boolean;
  @Input() password: boolean;
  @Input() icon: string;
  @Input() disabled: boolean;
  @Input() white: boolean;
  @Input() color: string | 'light-gray' | 'gradient' = '';
  @Input() showPassword: boolean;
  @Input() helpText: string;
  @Output() showPasswordChange: EventEmitter<boolean> = new EventEmitter();
  @Output() iconClick: EventEmitter<void> = new EventEmitter();

  private destroyRef = inject(DestroyRef);

  constructor() {}

  ngOnInit(): void {}

  get errorMessage() {
    if (!this.errors || !this.validate) {
      return null;
    }
    const errorType = Object.keys(this.errors)[0];
    if (errorType === 'required') {
      return 'This field is required';
    } else if (errorType === 'pattern') {
      return 'Please check your entry';
    } else if (errorType === 'match') {
      return this.errors.match && typeof this.errors.match === 'string' ? this.errors.match : 'Please check your entry';
    } else if (errorType === 'invalidDomain') {
      return this.errors.match && typeof this.errors.match === 'string'
        ? this.errors.match
        : 'It must be a third level domain or higher';
    } else if (errorType === 'noResults') {
      return 'No results found';
    } else if (errorType === 'notEmailField') {
      return 'It seems you’re entering your email address instead of your username!';
    }
  }
}
