import { Transition } from '@uirouter/core';
import { AryelAccountRole } from 'src/modules/type-definitions/account';
import {
  privilegeOfAdmin,
  privilegeOfAuthor,
  privilegeOfManager,
  privilegeOfViewer,
} from '../components/compounds/permissions/role-check';
import { AccountService } from '../services/account.service';
import { MeService } from '../services/me.service';
import { RouteGuard } from './route-guard';

const defaultRedirect = 'private.home';

export class IsRoleRouteGuard extends RouteGuard {
  on: 'onBefore' = 'onBefore';

  hook = {
    to: (state) => state.name.indexOf('private') === 0,
  };

  options = {
    priority: 25,
  };

  private redirect: string = defaultRedirect;

  callback = async (transition: Transition) => {
    const meService: MeService = transition.injector().get(MeService);
    const accountService: AccountService = transition.injector().get(AccountService);
    const { stateService } = transition.router;
    const data = transition.targetState().state().data;
    if (data?.role?.name && accountService.currentAccount && meService.me) {
      const role = data.role.name as AryelAccountRole;
      const redirect = data.role.redirect || this.redirect;
      if (role === 'admin' && !privilegeOfAdmin(meService.me._id, accountService.currentAccount)) {
        return stateService.target(redirect);
      } else if (role === 'manager' && !privilegeOfManager(meService.me._id, accountService.currentAccount)) {
        return stateService.target(redirect);
      } else if (role === 'author' && !privilegeOfAuthor(meService.me._id, accountService.currentAccount)) {
        return stateService.target(redirect);
      } else if (role === 'viewer' && !privilegeOfViewer(meService.me._id, accountService.currentAccount)) {
        return stateService.target(redirect);
      }
    }
  };
}

export function RouteRole(role: AryelAccountRole, redirect: string = defaultRedirect) {
  return {
    name: role,
    redirect,
  };
}
