import { Component, DestroyRef, EventEmitter, Input, OnInit, Output, inject } from '@angular/core';
import { StateService } from '@uirouter/core';
import { finalize } from 'rxjs/operators';
import { campaignCategories } from 'src/app/global/campaigns';
import { getMainCategoryContent } from 'src/app/global/templates';
import { CampaignService } from 'src/app/services/account/campaign.service';
import { TemplateService } from 'src/app/services/account/template.service';
import { ModalService } from 'src/app/services/modal.service';
import { ToastService } from 'src/app/services/toast.service';
import { MainCategoryFavouriteResponse } from 'src/types/entities/template';

@Component({
  selector: 'aryel-campaign-from-blank-modal',
  templateUrl: './campaign-from-blank-modal.component.html',
  styleUrls: ['./campaign-from-blank-modal.component.scss'],
})
export class CampaignFromBlankModalComponent implements OnInit {
  @Output() modalClose: EventEmitter<void> = new EventEmitter();
  @Output() onUpdate: EventEmitter<void> = new EventEmitter();

  categories: { name: string; slug: string }[] = [];
  private _favourites: MainCategoryFavouriteResponse[] = [];
  @Input() modalId: string = 'from-blank-modal';
  campaignName: string = '';
  modalStatus: boolean = false;
  modalText: string = '';
  hasPreselected: boolean = false;

  get favourites() {
    return this._favourites.map((fav) => fav?.category_value);
  }

  private destroyRef = inject(DestroyRef);

  constructor(
    private modalService: ModalService,
    private templateService: TemplateService,
    private toastService: ToastService,
    private stateService: StateService,
    private campaignService: CampaignService
  ) {}

  ngOnInit(): void {
    this.campaignService.fromBlankCreateModal.subscribe(
      (status: {
        opened: boolean;
        preselect: {
          category_name: string;
          category_value: string;
        };
      }) => {
        const { opened, preselect } = status;
        this.modalStatus = opened;
        if (this.modalStatus) {
          this.open(preselect);
        }
        if (!this.modalStatus && this.modalService.isOpen(this.modalId)) {
          this.modalService.closeModal(this.modalId);
        }
      }
    );
  }

  initCategories() {
    this.categories = Object.entries(campaignCategories).map(([slug, value]) => {
      const { name } = value;
      return {
        name,
        slug,
        _id: `campaign-from-blank-modal-category-${slug}`,
      };
    });
  }

  reset() {
    this._favourites = [];
    this.categories = [];
    this.campaignName = '';
    this.modalText = '';
    this.hasPreselected = false;
  }

  open(preselectCategory?: { category_name: string; category_value: string }) {
    this.reset();
    this.modalService.openModal(this.modalId);

    if (preselectCategory !== undefined && Object.keys(campaignCategories).includes(preselectCategory?.category_value)) {
      this.hasPreselected = true;
      this._favourites.push(preselectCategory);
      this.modalText = 'Our engines are already roaring! But before moving on, give your campaign a name.';
    } else {
      this.initCategories();
      this.modalText = 'What are you going to create today? Select the type of campaign you’d like to build.';
    }
  }

  handleClose() {
    this.campaignService.updateFromBlankStatus({ opened: false });
  }

  getMainCategoryContent(category: string) {
    return getMainCategoryContent(category);
  }

  toggle(category: { name: string; slug: string }) {
    this._favourites = [
      {
        category_name: 'Main',
        category_value: category.slug,
      },
    ];
  }

  confirm() {
    const categoryData = campaignCategories[this._favourites[0].category_value];

    if (categoryData.campaigns.triggers.length === 1 && !categoryData.campaigns.triggers[0].flow.length) {
      this.modalService.lockModal(this.modalId, true);
      const trigger = categoryData.campaigns.triggers[0];
      this.campaignService
        .create({
          name: this.campaignName,
          trigger_type: trigger._id,
          init_scenes: 1,
        })
        .pipe(
          finalize(() => {
            this.modalService.unlockModal(this.modalId, true);
            this.campaignService.updateFromBlankStatus({ opened: false });
          })
        )
        .subscribe((campaign) => {
          this.stateService.go('private.campaigns.edit', {
            id: campaign._id,
          });
        }, this.toastService.addToast);
    } else {
      this.campaignService.updateFromBlankStatus({ opened: false });
      this.stateService.go('private.campaigns.create', {
        category: this._favourites[0].category_value,
        campaignName: this.campaignName,
      });
    }
  }
}
