<aryel-form-field
  [placeholder]="placeholder"
  [disabled]="disabled"
  [errors]="errors"
  [validate]="validate"
  class="form-field"
  [class.form-field__square]="square"
  [white]="white"
>
  <input
    [dpDayPicker]="config"
    aryelFormField
    type="text"
    [(ngModel)]="model"
    (ngModelChange)="modelChange.emit(model)"
  />
</aryel-form-field>
