import { Injectable } from '@angular/core';
import { Observable, merge } from 'rxjs';
import { concatMap, flatMap, map } from 'rxjs/operators';
import 'src/app/libs/image-tracking-compiler/v1/aryel-it-compiler.prod.js';
import 'src/app/libs/image-tracking-compiler/v2/compiler.js';
import { CompileInput, CompileOutput, ITTargetGenerator } from './it-target-generator.abstract';
import { EditCampaignImageTrackingInterface } from '../../../types/entities/campaign';
import { ITTargetGeneratorTriggersCollectionInterface } from '../../../types/services/it-target-generator';

const ARYELIT = (window as any).ARYEL?.Image;
let COMPILER = null;
let DETECTOR = null;

if (ARYELIT?.Compiler && ARYELIT?.Detector) {
  COMPILER = ARYELIT.Compiler;
  DETECTOR = ARYELIT.Detector;
}

@Injectable({
  providedIn: 'root',
})
export class ITTargetGeneratorV2Service extends ITTargetGenerator {
  static COMPILER = () => {
    return COMPILER && DETECTOR
      ? new COMPILER(DETECTOR, {
        workerPath: '/assets/libs/image-tracking/v2/compiler.worker.js',
      })
      : null;
  };

  constructor() {
    super(ITTargetGeneratorV2Service.COMPILER());
  }

  prepareInput(input: EditCampaignImageTrackingInterface | { file?: File }) {
    const fileInput = input as { file?: File };
    if (fileInput.file) {
      return {
        campaign: "",
        triggers: [],
        background: false,
        library_version: 'v2',
        file: fileInput.file,
      } as CompileInput;
    } else {
      const triggers = []
      const campaingInput = input as EditCampaignImageTrackingInterface;
      for (const scene of campaingInput.scenes) {
        const generableTriggers = scene.triggers.filter((trigger) => trigger.type === 'image-tracking' && !trigger.payload?.dataset?.parsed)
        for (const trigger of generableTriggers) {
          triggers.push({
            id: trigger._id,
            src: trigger.payload.resource.main.entry.file.src,
          });
        }
      }

      return {
        campaign: campaingInput._id,
        triggers: triggers,
        background: false,
        library_version: 'v2',
      } as CompileInput;
    }
  }

  setCompiler() {
    this.compiler =
      COMPILER && DETECTOR
        ? new COMPILER(DETECTOR, {
          workerPath: '/assets/libs/image-tracking/v2/compiler.worker.js',
        })
        : null;

    return this.compiler;
  }

  compile(input: CompileInput) {
    this.setCompiler();
    if (input.file) { // single file mode
      return this.resize(input.file).pipe(
        flatMap((image) => {
          window.addEventListener('beforeunload', this.preventClose);

          return this.generateTarget({
            ...input,
            images: [image],
          });
        })
      );
    } else { // whole campaign mode
      const observables: Observable<CompileOutput>[] = input.triggers.map((trigger) => {
        const obs = new Observable<ITTargetGeneratorTriggersCollectionInterface>((observer) => { observer.next(trigger) }).pipe(
          concatMap((trigger) => {
            return this.resizePromise({ src: trigger.src }).then(image => {
              return {
                trigger,
                image,
              };
            })
          }),
          flatMap((data: { trigger: ITTargetGeneratorTriggersCollectionInterface, image: HTMLImageElement }) => {
            return this.generateTarget({
              triggers: [data.trigger],
              library_version: 'v2',
              images: [data.image],
            });
          }),
        );
        return obs;
      }) as any;

      const progressByTrigger:Record<string,number> = {};

      return merge(...observables).pipe(map(data => {
        progressByTrigger[data.triggers[0].id] = data.progress;
        data.totalProgress = Object.values(progressByTrigger).reduce((acc, curr) => acc + curr, 0) / Object.values(progressByTrigger).length;
        this._process.next(data);
        return data;
      }));
    }
  }

  protected generateTarget(input: CompileInput & { images: HTMLImageElement[] }) {
    return new Observable<CompileOutput>((observer) => {
      observer.next({
        ...input,
        progress: 0,
        complete: false,
      });

      this.compiler
        .compileImageTargets(input.images, async (progress: number) => {
          observer.next({
            ...input,
            progress: Math.floor(progress),
            complete: false,
          });
        })
        .then(
          (buffer) => {
            observer.next({
              ...input,
              progress: 100,
              complete: true,
              file: this.bufferToFile(buffer),
            });

            observer.complete();
          },
          (err: Error) => {
            observer.error(err);
          }
        )
        .catch((err: Error) => {
          observer.error(err);
        });
    });
  }

  /**
   * For both V1 and V2
   *
   * it prepares the image for the compiling.
   * This is used to resized the image when necessary and also for the
   * conversion from File object to HTML Image Element
   *
   * @param file an object with info for V1 or a File object for V2
   * @returns
   */
  protected resize(file: File) {
    return new Observable<HTMLImageElement>((observer) => {
      this.resizePromise({ file })
        .then(
          (res) => {
            observer.next(res);
          },
          (err) => {
            observer.error(err);
          }
        )
        .catch((err) => observer.error(err));
    });
  }

  /**
   * It resize the image if
   * one of width or height of the File are greater then resize params
   *
   * @param file A File object containing an Image
   */
  private async resizePromise(input: { file?: File, src?: string }) {
    return new Promise<HTMLImageElement>((resolve, reject) => {
      const resize = this.imageBounds;
      const canvas = this.canvas;
      const resizeWidthAndHeight = this.resizeWidthAndHeight;
      const isWidthAndHeightResizable = this.isWidthAndHeightResizable;

      // First, create the original image from the file to retrieve data
      const img = new Image();
      img.crossOrigin = 'Anonymous';

      img.onload = function (this: HTMLImageElement) {
        if (isWidthAndHeightResizable(this.naturalWidth, this.naturalHeight, resize)) {
          Object.assign(canvas, resizeWidthAndHeight(this.naturalWidth, this.naturalHeight, resize));

          const context = canvas.getContext('2d');
          context.clearRect(0, 0, canvas.width, canvas.height);
          context.drawImage(img, 0, 0, canvas.width, canvas.height);

          const resizedImage = new Image(canvas.width, canvas.height);
          resizedImage.crossOrigin = 'Anonymous';

          resizedImage.onload = () => resolve(resizedImage);
          resizedImage.onerror = reject;

          resizedImage.src = canvas.toDataURL();
        } else {
          resolve(img);
        }

        URL.revokeObjectURL(img.src);
      };
      img.src = input.file ? URL.createObjectURL(input.file) : input.src;
    });
  }

  preventClose(event: BeforeUnloadEvent) {
    event.preventDefault();
    event.returnValue = '';
    return;
  }
}
