import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PlanInterface } from 'src/types/entities/plan';
import { ApiService } from './api/api.service';
import { Restify } from './api/restify';

@Injectable({
  providedIn: 'root',
})
export class CollaboratorService extends Restify<PlanInterface> {
  interpolate() {
    return this._basePath;
  }

  constructor(protected apiService: ApiService) {
    super('/collaborators', apiService);
  }

  join(data: { account: string; token: string; emailHash: string }): Observable<PlanInterface[]> {
    return this.apiService.post(`${this.basePath}`, data);
  }
}
