import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class FwService {
  constructor(private httpClient: HttpClient, private localStorageService: LocalStorageService) {}

  signinRequest(email: string) {
    return this.httpClient.post(`${environment.api.path}/fw/signin-request`, { email });
  }

  signin(email: string, code: string) {
    return this.httpClient
      .post<{ token: string; _id: string }>(`${environment.api.path}/fw/signin`, {
        email,
        code,
      })
      .pipe(
        tap((res) => {
          this.localStorageService.setItem('fw-token', res.token);
          this.localStorageService.setItem('fw-uid', res._id);
        })
      );
  }

  verify(token: string, uid: string) {
    return this.httpClient.post(
      `${environment.api.path}/fw/verify`,
      {},
      {
        headers: new HttpHeaders({
          'X-Fw-Authorization': token,
          'X-Fw-UID': uid,
        }),
      }
    );
  }

  signout() {
    const token = this.localStorageService.getItem('fw-token');
    const uid = this.localStorageService.getItem('fw-uid');

    return this.httpClient.post(
      `${environment.api.path}/fw/signout`,
      {},
      {
        headers: new HttpHeaders({
          'X-Fw-Authorization': token,
          'X-Fw-UID': uid,
        }),
      }
    );
  }
}
