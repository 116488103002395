import { Component, DestroyRef, OnInit, inject } from '@angular/core';
import { TooltipPosition } from '../permissions.directive';

@Component({
  selector: 'aryel-permissions-tooltip',
  templateUrl: './permissions-tooltip.component.html',
  styleUrls: ['./permissions-tooltip.component.scss'],
})
export class PermissionsTooltipComponent implements OnInit {
  tooltip: { title?: string; text?: string } = {};
  left: number = 0;
  top: number = 0;

  visible: boolean = false;
  position: TooltipPosition = TooltipPosition.BELOW;

  private destroyRef = inject(DestroyRef);

  constructor() {}

  ngOnInit(): void {}
}
