<aryel-card>
  <ng-container head>
    <div class="text-center">
      <img
        *ngIf="currentThemeMode === 'color'; else logo_white"
        src="assets/img/brand/logo.png"
        class="logo"
      />
      <ng-template #logo_white>
        <img src="assets/img/brand/logo-white.png" class="logo" />
      </ng-template>
    </div>
  </ng-container>
  <h2 class="text-center view__title">Join your team</h2>
  <aryel-loading [loading]="loading">
    <form [formGroup]="form" (ngSubmit)="submit($event)">
      <div class="view__text">
        <p class="text-center small">
          Don't have an account yet?
          <span class="weight-700 link">
            <aryel-link
              (click)="switch.emit('sign-up')"
              [bold]="true"
              rightIcon="chevron_right"
              [biggerIcons]="true"
              >Go back and fill out the form
            </aryel-link>
          </span>
        </p>
      </div>
      <div class="spacer spacer--v spacer--slarge"></div>
      <div class="view__form">
        <aryel-form-field
          [errors]="form.controls.username.errors"
          [validate]="validate"
          placeholder="Username"
          color="light-gray"
        >
          <input
            aryelFormField
            type="text"
            [formControl]="form.controls.username"
          />
        </aryel-form-field>
        <div class="spacer spacer--v spacer--slarge"></div>
        <aryel-form-field
          [errors]="form.controls.password.errors"
          [(showPassword)]="showPassword"
          [password]="true"
          [validate]="validate"
          placeholder="Password"
          color="light-gray"
        >
          <input
            aryelFormField
            [type]="showPassword ? 'text' : 'password'"
            [formControl]="form.controls.password"
          />
        </aryel-form-field>
      </div>
      <div class="spacer spacer--v spacer--slarge"></div>
      <aryel-button type="submit" [full]="true">Get started</aryel-button>
      <div class="spacer spacer--v spacer--slarge"></div>
      <div class="grid-x align-middle align-center grid-margin-x">
        <div class="cell shrink">
          <aryel-link uiSref="join.team.forgot-username" size="small"
            >Forgot username?</aryel-link
          >
        </div>
        <div class="cell shrink">
          <aryel-link uiSref="join.team.forgot-password" size="small"
            >Forgot password?</aryel-link
          >
        </div>
      </div>
      <div class="spacer spacer--v spacer--slarge"></div>
      <p class="xs text-center margin--none weight-400">
        Protected by reCAPTCHA
        <span class="block xs">
          <aryel-link
            [bold]="true"
            [link]="{
              url: 'https://www.google.com/intl/en/policies/terms/',
              target: '_blank'
            }"
          >
            Terms of Use
          </aryel-link>
          and
          <aryel-link
            [bold]="true"
            [link]="{
              url: 'https://www.google.com/intl/en/policies/privacy/',
              target: '_blank'
            }"
          >
            Privacy Policy
          </aryel-link>
        </span>
      </p>
    </form>
  </aryel-loading>
</aryel-card>
