import {
  ApplicationRef,
  ComponentFactoryResolver,
  ComponentRef,
  Directive,
  ElementRef,
  EmbeddedViewRef,
  HostListener,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
} from '@angular/core';
import { PermissionsTooltipComponent } from './permissions-tooltip/permissions-tooltip.component';
import { ElementRestriction, PermissionsService } from './permissions.service';

export enum TooltipPosition {
  ABOVE = 'above',
  BELOW = 'below',
  LEFT = 'left',
  RIGHT = 'right',
  BOLOW_LEFT = 'below-left',
}

@Directive({
  selector: '[permissions]',
})
export class PermissionsDirective implements OnInit, OnDestroy {
  showDelay: number = 200;
  hideDelay: number = 200;
  tooltip: { title?: string; text?: string };

  @Input('case') case: 'action' | 'area' = 'area';
  @Input('position') position: TooltipPosition = TooltipPosition.BELOW;
  @Input('restriction') restriction: ElementRestriction = ElementRestriction.LOW;

  private componentRef: ComponentRef<any> | null = null;
  private showTimeout?: number;
  private hideTimeout?: number;
  private restricted: boolean;

  constructor(
    private injector: Injector,
    private renderer: Renderer2,
    private elementRef: ElementRef,
    private applicationRef: ApplicationRef,
    private permissionsService: PermissionsService,
    private componentFactoryResolver: ComponentFactoryResolver
  ) {}

  ngOnInit(): void {
    this.tooltip =
      this.case === 'action'
        ? {
            title: 'Action not allowed',
            text: 'You don’t have the required permissions to do this.',
          }
        : {
            title: 'Access denied',
            text: 'You don’t have the required permissions to access this area.',
          };
    this.restricted = this.permissionsService.isElementRestricted(this.restriction);
    if (this.restricted) {
      this.addClass();
    }
  }

  addClass(): void {
    this.renderer.addClass(this.elementRef.nativeElement, 'restricted');
  }

  @HostListener('mouseenter')
  onMouseEnter(): void {
    this.initializeTooltip();
  }

  @HostListener('mouseleave')
  onMouseLeave(): void {
    this.setHideTooltipTimeout();
  }

  private initializeTooltip() {
    if (this.componentRef === null && this.restricted) {
      window.clearInterval(this.hideDelay);
      const componentFactory = this.componentFactoryResolver.resolveComponentFactory(PermissionsTooltipComponent);
      this.componentRef = componentFactory.create(this.injector);

      this.applicationRef.attachView(this.componentRef.hostView);
      const [tooltipDOMElement] = (this.componentRef.hostView as EmbeddedViewRef<any>).rootNodes;

      this.setTooltipComponentProperties();

      document.body.appendChild(tooltipDOMElement);
      this.showTimeout = window.setTimeout(this.showTooltip.bind(this), this.showDelay);
    }
  }

  private setTooltipComponentProperties() {
    if (this.componentRef !== null) {
      this.componentRef.instance.tooltip = this.tooltip;
      this.componentRef.instance.position = this.position;

      const { left, right, top, bottom } = this.elementRef.nativeElement.getBoundingClientRect();

      switch (this.position) {
        case TooltipPosition.BELOW: {
          this.componentRef.instance.left = Math.round((right - left) / 2 + left);
          this.componentRef.instance.top = Math.round(bottom);
          break;
        }
        case TooltipPosition.ABOVE: {
          this.componentRef.instance.left = Math.round((right - left) / 2 + left);
          this.componentRef.instance.top = Math.round(top);
          break;
        }
        case TooltipPosition.RIGHT: {
          this.componentRef.instance.left = Math.round(right);
          this.componentRef.instance.top = Math.round(top + (bottom - top) / 2);
          break;
        }
        case TooltipPosition.LEFT: {
          this.componentRef.instance.left = Math.round(left);
          this.componentRef.instance.top = Math.round(top + (bottom - top) / 2);
          break;
        }
        case TooltipPosition.BOLOW_LEFT: {
          this.componentRef.instance.left = Math.round((right - left) / 2 + left);
          this.componentRef.instance.top = Math.round(bottom);
          break;
        }
        default: {
          break;
        }
      }
    }
  }

  private showTooltip() {
    if (this.componentRef !== null) {
      this.componentRef.instance.visible = true;
    }
  }

  private setHideTooltipTimeout() {
    this.hideTimeout = window.setTimeout(this.destroy.bind(this), this.hideDelay);
  }

  ngOnDestroy(): void {
    this.destroy();
  }

  destroy(): void {
    if (this.componentRef !== null) {
      window.clearInterval(this.showTimeout);
      window.clearInterval(this.hideDelay);
      this.applicationRef.detachView(this.componentRef.hostView);
      this.componentRef.destroy();
      this.componentRef = null;
    }
  }
}
