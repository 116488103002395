<div class="card" [class.card--dark]="dark" [ngClass]="['card--' + size]">
  <div
    #cardHead
    class="card__head"
    [class.card__head--disablePadding]="disablePadding"
    [class.card__head--disableDivider]="disableDivider"
  >
    <ng-content select="[head]"></ng-content>
  </div>
  <div
    class="card__content"
    [class.card__content--disablePadding]="disablePadding"
  >
    <ng-content></ng-content>
  </div>
</div>
