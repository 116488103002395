<div class="process" *ngIf="process">
  <aryel-loading [loading]="loading">
    <p class="text-center margin margin--none weight-700">
      We are preparing your campaign's triggers
    </p>
    <div class="spacer spacer--v spacer--large"></div>
    <aryel-loading-bar
      size="small"
      [status]="process.progress"
    ></aryel-loading-bar>
    <div class="spacer spacer--v spacer--medium"></div>
    <div class="text-center">
      <aryel-link size="small" [bold]="true" (click)="cancel()">
        Cancel
      </aryel-link>
    </div>
  </aryel-loading>
</div>

<aryel-modal [id]="modalId" heading="Stop process" (modalClose)="checkStatus()">
  <h5 class="margin margin--none text-center">
    Are you sure? If you exit the process now, you'll loose all the unsaved
    data.
  </h5>
  <div class="spacer spacer--v spacer--xlarge"></div>
  <div class="grid-x align-middle align-center grid-margin-x">
    <div class="cell shrink">
      <aryel-button (click)="confirmCancel()">Confirm</aryel-button>
    </div>
    <div class="cell shrink">
      <p>
        <aryel-link (click)="closeModal()" [bold]="true">Cancel</aryel-link>
      </p>
    </div>
  </div>
</aryel-modal>
