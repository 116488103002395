import { DOCUMENT } from '@angular/common';
import { Component, DestroyRef, Inject, inject } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TransitionService } from '@uirouter/core';
import { environment } from 'src/environments/environment';
import logger from './helpers/logger/logger';
import { FwService } from './pages/fw/fw.service';
import { DomService } from './services/dom.service';
import { HeaderService } from './services/header.service';
import { CustomerlyService } from './services/integrations/customerly.service';
import { LocalStorageService } from './services/local-storage.service';
import { ThemeService } from './services/theme.service';
const templateUrl = environment.doubleAuthenticationLayer ? './app-fw.component.html' : './app.component.html';

@Component({
  selector: 'aryel-app',
  templateUrl,
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  doubleAuthenticationLayer: boolean = environment.doubleAuthenticationLayer;
  authorized: boolean = null;

  get headerIsVisible() {
    return this.headerService.isVisible;
  }

  private destroyRef = inject(DestroyRef);

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private headerService: HeaderService,
    private localStorageService: LocalStorageService,
    private fwService: FwService,
    private transitionService: TransitionService,
    private title: Title,
    private themeService: ThemeService,
    private domService: DomService,
    private customerlyService: CustomerlyService
  ) {
    this.themeService.initMode();
    logger('Hi! 🖖', 'highlight');
    logger('Are you looking for something?', 'highlight2');
    logger('If you found an error, contact us at hello@aryel.io');
    if (this.doubleAuthenticationLayer) {
      const token = this.localStorageService.getItem('fw-token');
      const uid = localStorageService.getItem('fw-uid');

      this.fwService.verify(token, uid).subscribe(
        (res) => {
          this.authorized = true;
        },
        () => {
          this.authorized = false;
        }
      );
    }
    this.transitionService.onEnter({ from: '**' }, (transition) => {
      const data = transition.targetState().state().data;
      if (data?.title) {
        this.title.setTitle(`${data.title} | Aryel`);
      }
    });
    this.transitionService.onFinish({ from: '**', to: '**' }, (transition) => {
      const to = transition?.to()?.name || '';
      if (to) {
        this.domService.addAttribute('current', to);
      } else {
        this.domService.removeAttribute('current');
      }

      requestAnimationFrame(() => this.document.defaultView.scrollTo({ top: 0, behavior: 'auto' }));
      this.customerlyService.updateNavigation();
    });
  }

  signout() {
    if (this.doubleAuthenticationLayer) {
      this.fwService
        .signout()
        .subscribe(() => {
          this.localStorageService.removeItem('fw-token');
          this.localStorageService.removeItem('fw-uid');
        })
        .add(() => {
          location.reload();
        });
    }
  }
}
