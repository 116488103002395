import { Injectable } from '@angular/core';

interface ModalOptionInterface {
  open: boolean;
  persistent?: boolean;
  disableClose?: boolean;
  loading?: boolean;
  expanded?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  private modals: { [key: string]: ModalOptionInterface } = {};

  modal(id: string): ModalOptionInterface {
    return this.modals[id] || null;
  }

  isOpen(id: string): boolean {
    return this.modals[id]?.open || null;
  }

  isPersistent(id: string): boolean {
    return this.modals[id]?.persistent || false;
  }

  isClosedDisabled(id: string): boolean {
    return this.modals[id]?.disableClose || false;
  }

  isLoading(id: string): boolean {
    return this.modals[id]?.loading || false;
  }

  isExpanded(id: string): boolean {
    return this.modals[id]?.expanded || false;
  }

  registerModal(
    id: string,
    options: ModalOptionInterface = {
      open: false,
      persistent: false,
      disableClose: false,
      loading: false,
      expanded: false,
    }
  ): this {
    if (this.modals[id]) {
      throw new Error(`modal ${id} has allready been registered, please select another id`);
    }

    this.modals[id] = options;
    return this;
  }

  unregisterModal(id: string) {
    delete this.modals[id];
  }

  openModal(
    id: string,
    persistent: boolean = false,
    disableClose: boolean = false,
    loading: boolean = false,
    expanded: boolean = false
  ) {
    if (!this.modals[id]) {
      return this;
    }
    this.modals[id].open = true;
    this.modals[id].persistent = persistent;
    this.modals[id].disableClose = disableClose;
    this.modals[id].loading = loading;
    this.modals[id].expanded = expanded;
    window.addEventListener('keyup', (event) => this.escapeClick(event, this));
    return this;
  }

  closeModal(id: string) {
    if (!this.modals[id]) {
      return this;
    }
    this.modals[id].open = false;
    return this;
  }

  toggleModal(id: string) {
    if (!this.modals[id]) {
      return this;
    }
    this.modals[id].open = !this.modals[id].open;
    return this;
  }

  private escapeClick(event: KeyboardEvent, vm: ModalService) {
    if (event.key === 'Escape') {
      Object.keys(vm.modals)
        .filter((id) => vm.modals[id].open && !vm.modals[id].persistent)
        .forEach((modal) => {
          this.closeModal(modal);
        });
    }
  }

  setProperties(id: string, options: { persistent?: boolean; disableClose?: boolean; loading?: boolean }) {
    if (this.isOpen(id)) {
      if (options.persistent !== undefined) {
        this.modals[id].persistent = options.persistent;
      }
      if (options.disableClose !== undefined) {
        this.modals[id].disableClose = options.disableClose;
      }
      if (options.loading !== undefined) {
        this.modals[id].loading = options.loading;
      }
    }
  }

  lockModal(id: string, loading?: boolean) {
    if (!this.modals[id]) {
      console.warn(`Modal ${id} does not exists. Can't lock.`);
      return this;
    }
    this.modals[id].persistent = true;
    this.modals[id].disableClose = true;
    if (loading !== undefined) {
      this.modals[id].loading = true;
    }
    if (loading === false) {
      this.modals[id].loading = false;
    }
    return this;
  }

  unlockModal(id: string, loading?: boolean) {
    if (!this.modals[id]) {
      console.warn(`Modal ${id} does not exists. Can't unlock.`);
      return this;
    }
    this.modals[id].persistent = false;
    this.modals[id].disableClose = false;
    if (loading !== undefined) {
      this.modals[id].loading = false;
    }
    return this;
  }

  expandModal(id: string) {
    this.modals[id].expanded = true;
  }

  collapseModal(id: string) {
    this.modals[id].expanded = false;
  }
}
