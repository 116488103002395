import { animate, style, transition, trigger } from '@angular/animations';
import { Component, DestroyRef, OnInit, inject } from '@angular/core';
import { ToastService } from 'src/app/services/toast.service';
import { ToastInterface } from 'src/types/components/layout/toasts';

@Component({
  selector: 'aryel-toasts-container',
  templateUrl: './toasts-container.component.html',
  styleUrls: ['./toasts-container.component.scss'],
  animations: [
    trigger('items', [
      transition(':enter', [
        style({ transform: 'translateY(50%)', opacity: 0, height: 0 }),
        animate('450ms cubic-bezier(.8, -0.6, 0.2, 1.5)', style({ transform: 'translateY(0)', opacity: 1, height: '*' })),
      ]),
      transition(':leave', [
        style({ transform: 'translateY(0)', opacity: 1 }),
        animate(
          '450ms ease-in-out',
          style({
            transform: 'translateY(-50%)',
            opacity: 0,
          })
        ),
      ]),
    ]),
  ],
})
export class ToastsContainerComponent implements OnInit {
  toasts: ToastInterface[] = [];

  private destroyRef = inject(DestroyRef);

  constructor(private toastService: ToastService) {}

  ngOnInit(): void {
    this.toastService.toasts.subscribe((toasts) => {
      this.toasts = toasts;
    });
  }

  trackToasts(index: number, toast: ToastInterface) {
    return toast.timestamp;
  }

  remove(timestamp: number) {
    this.toastService.remove(timestamp);
  }
}
