import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { AccountService } from '../account.service';

export abstract class Restify<T> {
  protected get basePath(): string {
    return this.interpolate();
  }

  protected abstract interpolate();

  constructor(
    protected _basePath: string,
    protected apiService: ApiService,
  ) {}

  index(params = {}): Observable<Array<T>> {
    return this.apiService.get(this.basePath, params);
  }
  get(id: string | number, params = {}): Observable<T> {
    return this.apiService.get(`${this.basePath}/${id}`, params);
  }
  delete(id: string | number, params = {}): Observable<T> {
    return this.apiService.delete(`${this.basePath}/${id}`, params);
  }
  update(id: string | number, data, params = {}): Observable<T> {
    return this.apiService.patch(`${this.basePath}/${id}`, data, params);
  }
  create(data: { [x: string]: any }, params = {}): Observable<T> {
    return this.apiService.post(this.basePath, data, params);
  }
}
