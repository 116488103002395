import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PermissionsTooltipComponent } from './permissions-tooltip/permissions-tooltip.component';
import { PermissionsDirective } from './permissions.directive';

@NgModule({
  declarations: [PermissionsTooltipComponent, PermissionsDirective],
  imports: [CommonModule],
  exports: [PermissionsDirective],
})
export class CollaboratorsPermissionsModule {}
