import { AryelAccountRole } from 'src/modules/type-definitions/account';
import { AccountInterface } from 'src/types/entities/account';

export const user = (userId: string, account: AccountInterface) => {
  return (
    account.collaborators.find((x) => x.user._id === userId) || {
      role: '',
    }
  );
};

export const isOwner = (userId: string, account: AccountInterface) => {
  return account.owner._id === userId;
};
export const privilegeOfAdmin = (userId: string, account: AccountInterface) => {
  return user(userId, account).role === 'admin' || isOwner(userId, account);
};
export const privilegeOfManager = (userId: string, account: AccountInterface) => {
  return user(userId, account).role === 'manager' || privilegeOfAdmin(userId, account);
};
export const privilegeOfAuthor = (userId: string, account: AccountInterface) => {
  return user(userId, account).role === 'author' || privilegeOfManager(userId, account);
};
export const privilegeOfViewer = (userId: string, account: AccountInterface) => {
  return user(userId, account).role === 'viewer' || privilegeOfAuthor(userId, account);
};

export const isHighRestrictedElement = (userId: string, account: AccountInterface) => {
  const role: '' | AryelAccountRole = user(userId, account).role;
  return role === 'manager' || role === 'author' || role === 'viewer';
};

export const isPartialRestrictedElement = (userId: string, account: AccountInterface) => {
  const role: '' | AryelAccountRole = user(userId, account).role;
  return role === 'author' || role === 'viewer';
};

export const isLowRestrictedElement = (userId: string, account: AccountInterface) => {
  return user(userId, account).role === 'viewer';
};
