<aryel-modal [id]="modalId" (modalClose)="handleClose()">
  <div class="from-blank-modal">
    <h3 class="text-center">Create new campaign</h3>
    <p class="text-center">
      {{ modalText }}
    </p>
    <div class="divider divider--thin"></div>
    <div class="spacer spacer--large spacer--v"></div>
    <div
      class="from-blank-modal__grid grid-x grid-margin-x"
      *ngIf="categories.length && !hasPreselected"
    >
      <div
        *ngFor="let category of categories"
        class="cell from-blank-modal__single pointer relative"
        [id]="category._id"
        [class.from-blank-modal__single--favorite]="
          favourites.includes(category.slug)
        "
        [style.background-image]="
          'url(' + getMainCategoryContent(category.slug).cover + ')'
        "
        (click)="toggle(category)"
      >
        <div>
          <span class="material-symbols-outlined"> done </span>
        </div>

        <h4 class="from-blank-modal__single__title">
          {{ category.name }}
        </h4>
      </div>
    </div>
    <div class="from-blank-modal__name">
      <h5 *ngIf="!hasPreselected">Ready to start?</h5>
      <p *ngIf="!hasPreselected">
        Our engines are already roaring! But before moving on, give your
        campaign a name.
      </p>
      <aryel-form-field placeholder="Campaign name">
        <input
          name="campaign-name"
          aryelFormField
          type="text"
          [(ngModel)]="campaignName"
        />
      </aryel-form-field>
    </div>
    <div class="text-center">
      <aryel-button
        [disabled]="!campaignName || !favourites.length"
        (click)="campaignName && favourites.length && confirm()"
        status="primary"
      >
        Confirm
      </aryel-button>
    </div>
  </div>
</aryel-modal>
