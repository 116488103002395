import { Ng2StateDeclaration } from '@uirouter/angular';
import { AuthService } from 'src/app/services/auth.service';
import { PrivateComponent } from './private.component';

const PrivateRoutes: Ng2StateDeclaration[] = [
  {
    name: 'private',
    url: '?plan&coupon',
    redirectTo: 'private.home',
    component: PrivateComponent,
  },
  {
    name: 'private.sign-out',
    url: '/sign-out',
    redirectTo: async (transition) => {
      const authService: AuthService = transition.injector().get(AuthService);

      try {
        await authService.signout().toPromise();
      } catch (err) {}

      return { state: 'auth.sign-in', params: {} };
    },
  },
  {
    name: 'private.home.**',
    url: '/',
    loadChildren: () => import('./home/home.module').then((mod) => mod.HomeModule),
  },
  {
    name: 'private.campaigns.**',
    url: '/campaigns',
    loadChildren: () => import('./campaigns/campaigns.module').then((mod) => mod.CampaignsModule),
  },
  {
    name: 'private.reports.**',
    url: '/reports',
    loadChildren: () => import('./reports/reports.module').then((mod) => mod.ReportsModule),
  },
  {
    name: 'private.billing.**',
    url: '/plans-and-billing',
    loadChildren: () => import('./billing/billing.module').then((mod) => mod.BillingModule),
  },
  {
    name: 'private.users.**',
    url: '/users',
    loadChildren: () => import('./users/users.module').then((mod) => mod.UsersModule),
  },
  {
    name: 'private.account.**',
    url: '/account',
    loadChildren: () => import('./account/account.module').then((mod) => mod.AccountModule),
  },
  {
    name: 'private.brand.**',
    url: '/brand',
    loadChildren: () => import('./brand/brand.module').then((mod) => mod.BrandModule),
  },
  {
    name: 'private.profile.**',
    url: '/profile',
    loadChildren: () => import('./profile/profile.module').then((mod) => mod.ProfileModule),
  },
  {
    name: 'private.templates.**',
    url: '/',
    loadChildren: () => import('./templates/templates.module').then((mod) => mod.TemplatesModule),
  },
  {
    name: 'private.brandkits.**',
    url: '/',
    loadChildren: () => import('./brandkits/brandkits.module').then((mod) => mod.BrandkitsModule),
  },
];

export default PrivateRoutes;
