<span
  class="link not-selectable"
  [class.link--arrowRight]="rightIcon && rightIcon === 'arrow_forward'"
  [class.link--disabled]="disabled"
  [ngClass]="['link--' + size]"
  (click)="onClick()"
>
  <span class="link__left" *ngIf="leftIcon">
    <span
      class="material-symbols-outlined link__icon"
      [ngClass]="['color-' + color]"
      [class.link__icon--bigger]="biggerIcons"
    >
      {{ leftIcon }}
    </span>
  </span>
  <span
    class="link__text"
    [class.link__text--underline]="underline"
    [ngClass]="['color-' + color]"
    [class.link__text--bold]="bold"
  >
    <ng-content></ng-content>
  </span>
  <span class="link__right" *ngIf="rightIcon">
    <span
      class="material-symbols-outlined link__icon"
      [ngClass]="['color-' + color]"
      [class.link__icon--bigger]="biggerIcons"
    >
      {{ rightIcon }}
    </span>
  </span>
</span>
