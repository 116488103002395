<div
  class="input"
  [class.input--status-error]="errorMessage && validate"
  [class.input--icon]="password || icon"
  [class.input--disabled]="disabled"
  [class.input--white]="white"
  [class.input--no-label]="!placeholder"
  [ngClass]="'input--' + color"
>
  <ng-content></ng-content>
  <label class="input__label" *ngIf="placeholder">{{ placeholder }}</label>
  <span
    class="material-symbols-outlined input__icon not-selectable"
    (click)="showPasswordChange.emit(!showPassword)"
    *ngIf="password"
  >
    visibility
    <span class="input__line" [class.input__line--fill]="showPassword"></span>
  </span>
  <span
    class="material-symbols-outlined input__icon not-selectable"
    (click)="iconClick.emit()"
    *ngIf="icon && !password"
  >
    {{ icon }}
  </span>
</div>
<small
  [@showError]
  *ngIf="errorMessage"
  class="input__error color-error weight-700 margin margin--none"
  >{{ errorMessage }}</small
>
<small
  *ngIf="helpText"
  class="input__error weight-700"
  [innerHtml]="helpText"
></small>
