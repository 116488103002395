import { StateService } from '@uirouter/angular';

const funnelParams = (stateService: StateService): { plan?: string; coupon?: string } => {
  const params: any = {};
  if (stateService.params.plan) {
    params.plan = stateService.params.plan;
  }
  if (stateService.params.coupon) {
    params.coupon = stateService.params.coupon;
  }

  return params;
};

export default funnelParams;
