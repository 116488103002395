import { Component, DestroyRef, EventEmitter, Input, OnInit, Output, ViewChild, inject } from '@angular/core';
import { DatePickerDirective } from 'ng2-date-picker';

@Component({
  selector: 'aryel-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
})
export class DatepickerComponent implements OnInit {
  @Input() placeholder: string;
  @Input() errors: any | null;
  @Input() validate: boolean;
  @Input() disabled: boolean;
  @Input() square: boolean;
  @Input() min: string;
  @Input() max: string;
  @Input() format: string = 'YYYY-MM-DD';
  @Input() model: string;
  @Input() white: boolean;
  @Output() modelChange: EventEmitter<string> = new EventEmitter();

  @ViewChild(DatePickerDirective) datePickerDirective: DatePickerDirective;

  config: any = {
    format: 'YYYY-MM-D1D',
    firstDayOfWeek: 'mo',
  };

  private destroyRef = inject(DestroyRef);

  constructor() {}

  ngOnInit(): void {
    if (this.min) {
      this.config.min = this.min;
    }
    if (this.max) {
      this.config.max = this.max;
    }
    if (this.format) {
      this.config.format = this.format;
    }
  }
}
