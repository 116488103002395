<div
  class="loading"
  [class.loading--enable]="loading"
  [class.loading--dark]="dark"
  [class.loading--light]="!dark"
  [ngClass]="['loading--' + position, 'loading--' + size]"
>
  <div>
    <svg
      *ngIf="loading"
      [@showHide]
      class="loading__spinner"
      viewBox="0 0 66 66"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        class="circle"
        fill="none"
        stroke-width="6"
        stroke-linecap="round"
        cx="33"
        cy="33"
        r="30"
      ></circle>
    </svg>
    <div class="message" *ngIf="message">
      <p class="h4">{{ message.title }}</p>
      <p>{{ message.description }}</p>
    </div>
  </div>
  <div class="loading__content">
    <ng-content></ng-content>
  </div>
</div>
