import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, forkJoin, of } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import 'src/app/libs/image-tracking-compiler/v1/aryel-it-compiler.prod.js';
import 'src/app/libs/image-tracking-compiler/v2/compiler.js';
import { EditCampaignImageTrackingInterface } from 'src/types/entities/campaign';
import { ITTargetGeneratorTriggersCollectionInterface } from 'src/types/services/it-target-generator';
import { ToastService } from '../toast.service';
import { CompileInput, CompileOutput, ITTargetGenerator } from './it-target-generator.abstract';

const ARYELIT = (window as any)?.ARYELIT;
const COMPILER = ARYELIT?.Compiler ? ARYELIT.Compiler : null;

@Injectable({
  providedIn: 'root',
})
export class ITTargetGeneratorV1Service extends ITTargetGenerator {
  static COMPILER = () => {
    return COMPILER ? new COMPILER() : null;
  };

  constructor(private toastService: ToastService) {
    super(ITTargetGeneratorV1Service.COMPILER());
  }

  isCurrentCampaignOnHold(campaign: string) {
    if (!this._process.value) {
      return false;
    }

    return this._process.value.campaign === campaign && !this._process.value.complete;
  }

  setCompiler() {
    this.compiler = ITTargetGeneratorV1Service.COMPILER();

    return this.compiler;
  }

  /**
   * For both V1 and V2
   *
   * it prepares the image for the compiling.
   * This is used to resized the image when necessary and also for the
   * conversion from File object to HTML Image Element
   *
   * @param file an object with info for V1 or a File object for V2
   * @returns
   */
  resize(file: { width: number; height: number; src: string }) {
    return new Observable<HTMLImageElement>((observer) => {
      this.resizePromise(file)
        .then(
          (res) => {
            observer.next(res);

            observer.complete();
          },
          (err) => {
            observer.error(err);
          },
        )
        .catch((err) => observer.error(err));
    });
  }

  /**
   * It resize the image if
   * one of width or height are greater then resize params
   *
   * @param file An object containing the src og the image and datas
   */
  private async resizePromise(file: { width: number; height: number; src: string }) {
    return new Promise<HTMLImageElement>((resolve, reject) => {
      const img = new Image(file.width, file.height);
      img.crossOrigin = 'Anonymous';
      img.onerror = reject;

      if (this.isWidthAndHeightResizable(file.width, file.height, this.imageBounds)) {
        Object.assign(this.canvas, this.resizeWidthAndHeight(file.width, file.height, this.imageBounds));

        const context = this.canvas.getContext('2d');
        context.clearRect(0, 0, this.canvas.width, this.canvas.height);

        img.onload = () => {
          context.drawImage(img, 0, 0, this.canvas.width, this.canvas.height);

          const resizedImage = new Image(this.canvas.width, this.canvas.height);
          resizedImage.crossOrigin = 'Anonymous';

          resizedImage.onload = () => resolve(resizedImage);
          resizedImage.onerror = (err) => reject(err);

          resizedImage.src = this.canvas.toDataURL();
        };

        img.src = file.src;
      } else {
        img.onload = () => resolve(img);
        img.src = file.src;
      }
    });
  }

  prepareInput(campaign: EditCampaignImageTrackingInterface) {
    if (!campaign.scenes?.length) {
      return null;
    }

    const triggers: ITTargetGeneratorTriggersCollectionInterface[] = [];

    for (const scene of (campaign as EditCampaignImageTrackingInterface).scenes) {
      for (const trigger of scene.triggers) {
        triggers.push({
          id: trigger._id,
          src: trigger.payload.resource.main.entry.file.src,
          height: this.extractDimensionsMetadata(trigger, 'height'),
          width: this.extractDimensionsMetadata(trigger, 'width'),
        });
      }
    }

    if (!triggers.length) {
      this.error({ title: 'No triggers found to process' });
    }

    return {
      campaign: campaign._id,
      triggers,
      background: false,
      library_version: campaign.library_version,
    } as CompileInput;
  }

  /**
   * Only for V1
   *
   * It start the compiling phase for the dataset
   *
   * @param campaign The campaign tha we are using for the compiling
   * @param version
   * @returns
   */
  compile(input: CompileInput): Observable<CompileOutput> {
    // TODO manage this
    if (this._process.value && !this._process.value.complete) {
      this.toastService.addToast({
        title: 'You have to wait for the other process to end',
        status: 'warning',
      });

      return;
    }

    if (!input.triggers.length) {
      this.error({ title: 'No triggers found to process' });
    }

    const observables: Observable<HTMLImageElement>[] = input.triggers.map((trigger) => this.resize(trigger));

    return forkJoin(observables).pipe(
      concatMap((images) => {
        if (!images.length) {
          this.error({ title: 'No images found to process' });

          return of(null);
        }

        window.addEventListener('beforeunload', this.preventClose);

        return this.generateTarget({
          ...input,
          images,
        });
      }),
    );
  }

  /**
   * Only for v1
   *
   * It compiles the target.aryelit dataset file and manage the loading bar
   */
  protected generateTarget(input: CompileInput & { images: HTMLImageElement[] }) {
    return new Observable<CompileOutput>((observer) => {
      observer.next({
        ...input,
        progress: 0,
        complete: false,
      });

      this._process.next({
        ...input,
        progress: 0,
        complete: false,
      });

      this.compiler
        .compileImageTargets(input.images, (progress: number) => {
          observer.next({
            ...input,
            progress: Math.floor(progress),
            complete: false,
          });

          this._process.next({
            ...input,
            progress: Math.floor(progress),
            complete: false,
          });
        })
        .then(
          async () => {
            const buffer = (await this.compiler.exportData()) as BlobPart;

            observer.next({
              ...input,
              progress: 100,
              complete: true,
              file: this.bufferToFile(buffer),
            });

            this._process.next({
              ...input,
              progress: 100,
              complete: true,
              file: this.bufferToFile(buffer),
            });

            observer.complete();
          },
          (err: Error) => {
            observer.error(err);
          },
        )
        .catch((err: Error) => {
          observer.error(err);
        });
    });
  }

  background(background: boolean = true) {
    if (!this.process) {
      console.warn('No process to put in background');
      return;
    }

    this._process.next({ ...this._process.value, background });
  }

  complete() {
    window.removeEventListener('beforeunload', this.preventClose);
    this.process.next(null);
    this.setCompiler();
    // @ts-ignore
    // this.itCompiler.v1 = new window.ARYELIT.Compiler();
  }

  completedWithSuccess() {
    if (this._process.value) {
      this._process.next({
        ...this._process.value,
        success: true,
      });
    }
  }

  private error(err: any) {
    console.error(err);

    this.toastService.addToast({
      title: 'An error occurred while processing your triggers...',
      status: 'error',
    });

    this.complete();
  }

  preventClose(event: BeforeUnloadEvent) {
    event.preventDefault();
    event.returnValue = '';

    return;
  }
}
