<div class="grid-x breadcrumbs align-middle">
  <div
    (click)="goBack()"
    class="cell shrink breadcrumbs__back"
    *ngIf="breadcrumbs && breadcrumbs.length && back"
  ></div>
  <ng-container *ngIf="breadcrumbs && breadcrumbs.length">
    <div
      class="cell shrink breadcrumbs__bread"
      *ngFor="let bread of breadcrumbs"
    >
      <p
        class="h1"
        *ngIf="!manual"
        [uiSref]="bread.state"
        [uiParams]="bread.params"
      >
        {{ bread.label }}
      </p>
      <p *ngIf="manual" class="pointer h1" (click)="onBread.emit(bread)">
        {{ bread.label }}
      </p>
    </div>
  </ng-container>
  <div class="cell shrink breadcrumbs__current" *ngIf="active">
    <p class="h1">{{ active }}</p>
  </div>
</div>
