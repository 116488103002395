<div
  class="tooltip"
  [class.tooltip--disabled]="disabled"
  [class.tooltip--block]="block"
  [ngClass]="'tooltip--' + size"
>
  <div
    class="tooltip__message not-selectable"
    [class.tooltip__message--full]="full"
    [class.tooltip__message--break]="breakLine"
    [class.tooltip__message--oneline]="oneLine"
    [ngClass]="['tooltip__message--' + position]"
    [style.width]="width ? width : null"
    [innerHTML]="text"
  ></div>
  <ng-content></ng-content>
</div>
