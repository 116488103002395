import { LockedFeatureModalPreset } from 'src/types/components/layout/locked-feature-modal';

export const presets: LockedFeatureModalPreset = {
  crm: {
    left: {
      title: 'CRM Suite',
      text: `This campaign cannot be published with your current subscription.<br>Integrate the <strong class="color-white weight-700">CRM Suite</strong> to your plan to unlock this content and much more!
      `,
      carousel: ['/assets/img/suites/crm/preview.jpg'],
    },
    right: {
      title: 'Get in touch with us',
      text: `
        Schedule a call with our sales team to gather all the information you need.<br> Adding the CRM Suite to your plan you will get access to the following features:
      `,
      features: [
        'Immersive 360',
        'Face Filters & Effects',
        'Variables',
        'Lead generation module - <span class="italic weight-inherit">available soon</span>',
      ],
      cta: {
        text: 'Talk to sales',
        url: 'https://aryel.io/contact/',
      },
      link: {
        text: 'Learn more about the CRM Suite',
        url: 'https://aryel.io/crm/',
      },
    },
  },
  ecommerce: {
    left: {
      title: 'Ecommerce Suite',
      text: `
          This campaign cannot be published with your current subscription.<br>
          Integrate the <strong class="color-white weight-700">Ecommerce Suite</strong> to your plan to unlock this content and much more!
        `,
      carousel: ['/assets/img/suites/ecommerce/preview.jpg'],
    },
    right: {
      title: 'Get in touch with us',
      text: `
          Schedule a call with our sales team to gather all the information you need.
          Adding the Ecommerce Suite to your plan you will get access to the following features:
        `,
      features: [
        'Virtual Try-On',
        'Embed 3D & WebAR with shortcode',
        'Ecommerce plugins',
        'Advanced reports - <span class="italic weight-inherit">available soon</span>',
      ],
      cta: {
        text: 'Talk to sales',
        url: 'https://aryel.io/contact/',
      },
      link: {
        text: 'Learn more about the Ecommerce Suite',
        url: 'https://aryel.io/ecommerce/',
      },
    },
  },
  'ecommerce-crm': {
    left: {
      title: 'Ecommerce/CRM Suite',
      text: `
          This campaign cannot be published with your current subscription.<br>
          Integrate the <strong class="color-white weight-700">Ecommerce or CRM Suite</strong> to your plan to unlock this content and much more!
        `,
      carousel: ['/assets/img/suites/crm/preview.jpg'],
    },
    right: {
      title: 'Get in touch with us',
      text: `
          Schedule a call with our sales team to gather all the information you need.
          With the Ecommerce and CRM Suite in your plan you will get access to the following features:
        `,
      features: [
        'Immersive 360',
        'Virtual Try-On + Face Filters & Effects',
        'Embed 3D & WebAR with shortcode',
        'Ecommerce plugins',
      ],
      cta: {
        text: 'Talk to sales',
        url: 'https://aryel.io/contact/',
      },
      link: {
        text: 'Learn more about the Aryel Suites',
        url: 'https://help.aryel.io/en/plans-andamp-billing/about-aryel-pricing-plan',
      },
    },
  },
  'pro-preview-campaign': {
    left: {
      title: 'Preview campaign',
      text: `
          No campaign can be published with your current subscription. Use the <strong class="color-white weight-700">preview</strong> button to generate a temporary QR code and try the experiences on your mobile devices.
        `,
      carousel: ['/assets/img/suites/pro-preview-campaign/preview.gif'],
    },
    right: {
      title: 'Upgrade to Enterprise',
      text: `Ready to publish? Upgrade your plan to Enterprise. You can either get the monthly or yearly subscription. With the Enterprise plan you can get access to the following features:`,
      features: [
        'Ecommerce plugins',
        'Embed 3D & WebAR with shortcode',
        'Premium templates and ready-to-use',
        'Remove “Powered by Aryel” badge',
        'Downloadable reports',
        'Custom domain',
      ],
      cta: {
        text: 'Talk to sales',
        url: 'https://aryel.io/contact/',
      },
      link: {
        text: 'Learn more about Aryel Suites',
        url: 'https://help.aryel.io/en/plans-andamp-billing/about-aryel-pricing-plan',
      },
    },
  },
  'link-in-bio-preview-campaign': {
    left: {
      title: 'Preview campaign',
      text: `
        You can publish <strong class="color-white weight-700">one immersive 360</strong> campaign with your current subscription. Use the <strong class="color-white weight-700">preview</strong> button to generate a temporary QR code and try the experiences on your mobile devices.
      `,
      carousel: ['/assets/img/suites/pro-preview-campaign/preview.gif'],
    },
    right: {
      title: 'Upgrade to Enterprise',
      text: `Upgrade your plan to Enterprise. You can either get the monthly or yearly subscription. With the Enterprise plan you can get access to the following features:`,
      features: [
        'Ecommerce plugins',
        'Embed 3D & WebAR with shortcode',
        'Premium templates and ready-to-use',
        'Remove “Powered by Aryel” badge',
        'Downloadable reports',
        'Custom domain',
      ],
      cta: {
        text: 'Talk to sales',
        url: 'https://aryel.io/contact/',
      },
      link: {
        text: 'Learn more about Aryel Suites',
        url: 'https://help.aryel.io/en/plans-andamp-billing/about-aryel-pricing-plan',
      },
    },
  },
  offline: {
    left: {
      title: 'Offline Suite',
      text: `
          This campaign cannot be published with your current subscription.<br> Integrate the <strong class="color-white weight-700">Offline Suite</strong> to your plan to unlock this content and much more!
        `,
      carousel: ['/assets/img/suites/offline/preview.jpg'],
    },
    right: {
      title: 'Get in touch with us',
      text: `
          Schedule a call with our sales team to gather all the information you
          need. Adding the Offline Suite to your plan you will get access to the
          following features:
        `,
      features: [
        'Campaigns triggered by images',
        'Geolocalized campaigns',
        'Canva add-on - <span class="italic weight-inherit">available soon</span>',
      ],
      cta: {
        text: 'Talk to sales',
        url: 'https://aryel.io/contact/',
      },
      link: {
        text: 'Learn more about the Offline Suite',
        url: 'https://aryel.io/offline/',
      },
    },
  },
  adv: {
    left: {
      title: 'Advertising Suite',
      text: 'This feature is not available in the current plan.<br />To unlock it, the <strong class="color-white weight-700">Advertising suite</strong> must be integrated.',
      carousel: ['assets/img/suites/adv/preview.png'],
    },
    right: {
      title: 'Get in touch with us',
      text: 'Schedule a call with our sales team to gather all the information you need.Adding the Advertising Suite to your plan you will get access to the following features:',
      features: ['ADV Banners', 'Advanced reports'],
      cta: {
        text: 'Talk to sales',
        url: 'https://aryel.io/contact/',
      },
      link: {
        text: 'Learn more about the Advertising Suite',
        url: 'https://aryel.io/advertising/',
      },
    },
  },
  'enterprise-feature': {
    left: {
      title: 'Enterprise',
      text: 'This Premium feature is not included in your current subscription.<br />Upgrade your plan to <strong class="color-white weight-700">Enterprise</strong> to gain access to a world of extra features.',
      carousel: [
        'assets/img/suites/crm/preview.jpg',
        'assets/img/suites/ecommerce/preview.jpg',
        'assets/img/suites/offline/preview.jpg',
        'assets/img/suites/adv/preview.png',
      ],
    },
    right: {
      title: 'Get in touch with us',
      text: 'Schedule a call with our sales team to gather all the information you need and define the perfect fit. Upgrading your plan to <strong class="weight-700">Enterprise</strong> you can get access to the following features:',
      features: [
        'Ecommerce plugins',
        'Embed 3D & WebAR with shortcode',
        'Premium templates and ready-to-use',
        'Remove “Powered by Aryel” badge',
        'Downloadable reports',
        'Custom domain',
      ],
      cta: {
        text: 'Talk to sales',
        url: 'https://aryel.io/contact/',
      },
      link: {
        text: 'Learn more about the Aryel Suites',
        url: 'https://help.aryel.io/en/plans-andamp-billing/about-aryel-pricing-plan',
      },
    },
  },
  'enterprise-out-of-storage': {
    left: {
      title: 'Out of storage!',
      text: 'You’ve reached the storage limit included in your current subscription.<br />Upgrade to the <strong class="color-white weight-700">Enterprise</strong> to get up to 10GB of space in your media library.',
      carousel: ['assets/img/suites/out-of-storage/preview.png'],
    },
    right: {
      title: 'Get in touch with us',
      text: 'Schedule a call with our sales team to gather all the information you need and define the perfect fit.Upgrading your plan to <strong class="weight-700">Enterprise</strong> you can get access to the following features:',
      features: [
        'Ecommerce plugins',
        'Embed 3D & WebAR with shortcode',
        'Premium templates and ready-to-use',
        'Remove “Powered by Aryel” badge',
        'Downloadable reports',
        'Custom domain',
      ],
      cta: {
        text: 'Talk to sales',
        url: 'https://aryel.io/contact/',
      },
      link: {
        text: 'Learn more about the Aryel Suites',
        url: 'https://help.aryel.io/en/plans-andamp-billing/about-aryel-pricing-plan',
      },
    },
  },
  'enterprise-limit-campaigns': {
    left: {
      title: 'Get in touch with us',
      text: 'The limit of <strong class="color-white weight-700">active campaigns</strong> included in your current subscription has been reached.<br />Get in touch with us to perform an upgrade of your <strong class="color-white weight-700">Enterprise</strong> plan.',
      carousel: ['assets/img/suites/pro/preview.jpeg'],
      cta: {
        text: 'Talk to sales',
        url: 'https://aryel.io/contact/',
      },
      link: {
        text: 'Learn more about the Aryel Suites',
        url: 'https://help.aryel.io/en/plans-andamp-billing/about-aryel-pricing-plan',
      },
    },
  },
  'enterprise-limit-seats': {
    left: {
      title: 'Get in touch with us',
      text: 'The limit of <strong class="color-white weight-700">seats</strong> included in your current subscription has been reached.<br />Get in touch with us to perform an upgrade of your <strong class="color-white weight-700">Enterprise</strong> plan.',
      carousel: ['assets/img/suites/enterprise/limit-seats.jpg'],
      cta: {
        text: 'Talk to sales',
        url: 'https://aryel.io/contact/',
      },
      link: {
        text: 'Learn more about the Aryel Suites',
        url: 'https://help.aryel.io/en/plans-andamp-billing/about-aryel-pricing-plan',
      },
    },
  },
  'partner-program': {
    left: {
      title: 'Partner Program 🤝',
      text: 'The limit of custom <strong class="color-white weight-700">domains</strong> included in your current subscription has been reached. Getting more domains is an exclusive feature reserved to our Partner Agencies.<br />If you’re interested in joining our <strong class="color-white weight-700">Partner Program</strong>, you can apply. You’ll be redirected to a form to be filled out with your data. Can’t wait to have you onboard! As a partner you will get:',
      features: [
        'Full Aryel Access',
        'Continuous Learning',
        'Support in Presales',
        'Reselling Rights',
        'Exclusive Contents',
        'Space in Our Featured List',
      ],
      cta: {
        text: 'Apply plan',
        url: 'https://aryel.io/agency-partner-program/',
      },
      link: {
        text: 'Learn more about our Partner Program',
        url: 'http://jump.aryel.io/what-is-aryel-partner-program',
      },
    },
  },
  'domains-limit': {
    left: {
      title: 'Get in touch with us',
      text: 'The limit of custom domains included in your current subscription has been reached. Get in touch with us if you’d like to add one or more. <br /><br /> By using the button below our team will receive your request and will get back to you as soon as possible! 💫',
      carousel: ['assets/img/brandkits-index-preview.png'],
      cta: {
        text: 'Contact us',
        url: null,
      },
    },
  },
  'stylekits-limit': {
    left: {
      title: 'Get in touch with us',
      text: "The limit of style kits included in your current subscription has been reached. Get in touch with us if you'd like to add one or more. <br /><br /> By using the button below our team will receive your request and will get back to you as soon as possible! 💫",
      carousel: ['assets/img/brandkits-index-preview.png'],
      cta: {
        text: 'Contact us',
        url: null,
      },
    },
  },
};
