import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UIRouterModule } from '@uirouter/angular';
import { DatepickerModule } from 'src/app/components/form/datepicker/datepicker.module';
import { FormFieldModule } from 'src/app/components/form/form-field/form-field.module';
import { BreadcrumbsModule } from 'src/app/components/layout/breadcrumbs/breadcrumbs.module';
import { CardModule } from 'src/app/components/layout/card/card.module';
import { ElementRestriction } from 'src/app/components/layout/collaborators-permissions/permissions.service';
import { FileChooserModule } from 'src/app/components/layout/file-chooser/file-chooser.module';
import { LoadingModule } from 'src/app/components/layout/loading/loading.module';
import { PageContainerModule } from 'src/app/components/layout/page-container/page-container.module';
import { TooltipModule } from 'src/app/components/layout/tooltip/tooltip.module';
import { ButtonModule } from 'src/app/components/ui/button/button.module';
import { RouteRestriction } from 'src/app/guards/permissions-guard';
import { AskContentComponent } from './ask-content.component';

@NgModule({
  declarations: [AskContentComponent],
  imports: [
    CommonModule,
    UIRouterModule.forChild({
      states: [
        {
          name: 'private.ask-content',
          url: '/ask-for-content',
          data: {
            title: 'Ask for content',
            routeRestriction: RouteRestriction(ElementRestriction.PARTIAL),
          },
          component: AskContentComponent,
        },
      ],
    }),
    BreadcrumbsModule,
    PageContainerModule,
    CardModule,
    FileChooserModule,
    FormFieldModule,
    FormsModule,
    DatepickerModule,
    ButtonModule,
    ReactiveFormsModule,
    LoadingModule,
    TooltipModule,
  ],
})
export class AskContentModule {}
