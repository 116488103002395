import { RouteGuard } from './route-guard';
import { Transition } from '@uirouter/core';
import { AccountService } from '../services/account.service';
import { MeService } from '../services/me.service';
import { CommunicationService } from '../services/account/communication.service';

export class IsLoggedInRouteGuard extends RouteGuard {
  on: 'onBefore' = 'onBefore';

  hook = {
    to: (state) => state.name.indexOf('private') === 0,
  };

  options = {
    priority: 20,
  };

  callback = async (transition: Transition) => {
    const meService: MeService = transition.injector().get(MeService);
    const accountService: AccountService = transition.injector().get(AccountService);
    const communicationService: CommunicationService = transition.injector().get(CommunicationService);
    const { stateService } = transition.router;

    if (!meService.me) {
      const urlParams = window.location.href.split('?')[1];
      const stateParams = {};
      if (urlParams) {
        for (const p of urlParams.split('&')) {
          const param = p.split('=');
          stateParams[param[0]] = param[1];
        }
      }

      communicationService.stop();
      accountService.clearCurrentAccount();
      return stateService.target('auth', stateParams);
    }
  };
}
