import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UIRouterModule } from '@uirouter/angular';
import { ModalModule } from '../../layout/modal/modal.module';
import { ButtonModule } from '../../ui/button/button.module';
import { LinkModule } from '../../ui/link/link.module';
import { DraftSubscriptionModalComponent } from './draft-subscription-modal.component';

@NgModule({
  declarations: [DraftSubscriptionModalComponent],
  imports: [CommonModule, ButtonModule, LinkModule, ModalModule, UIRouterModule],
  exports: [DraftSubscriptionModalComponent],
})
export class DraftSubscriptionModalModule {}
