import { TargetState, Transition } from '@uirouter/core';
import { ToastInterface } from 'src/types/components/layout/toasts';
import { ElementRestriction, PermissionsService } from '../components/layout/collaborators-permissions/permissions.service';
import { ToastService } from '../services/toast.service';
import { RouteGuard } from './route-guard';

export class PermissionsRouteGuard extends RouteGuard {
  on: 'onBefore' = 'onBefore';

  hook = {
    to: (state) => state.name.indexOf('private') === 0,
  };

  options = {
    priority: 24,
  };

  /**
   * Method for managing permissions associated with a user. The management of conditions related to toast needs to be reviewed.
   * The solution applied is necessary so that no more than one piece of toast is the same as the previous one.
   * The error occurs because, during navigation from 'searchBar', UI-Router makes N navigations as many parent/child levels are crossed.
   * This without ever updating the 'previousState', making it impossible to filter navigations;
   * @param transition
   * @returns
   */
  callback = async (transition: Transition): Promise<TargetState> => {
    const futureState: string = transition.to()?.name;
    const previousState: string = transition.from()?.name;

    const isTransitionBySearchBar: boolean = previousState == '';
    const isTransitionFromChooseAccount: boolean = previousState === 'private.choose-account';
    const isTransitionToHome: boolean = futureState === 'private.home' || futureState === 'private.home.**';

    const toastService: ToastService = transition.injector().get(ToastService);
    const permissionsService: PermissionsService = transition.injector().get(PermissionsService);

    const { stateService } = transition.router;
    const data: any = transition.targetState().state().data;
    const elementRestriction: ElementRestriction = data?.routeRestriction;

    const isToastAlreadyPresent: boolean = toastService.toasts
      .getValue()
      .some((singleToast: ToastInterface): boolean => singleToast.title === 'Access denied!');

    if (elementRestriction) {
      const toast: ToastInterface = {
        status: 'error',
        title: 'Access denied!',
        text: 'You don’t have the required permissions to access this area.',
      };

      if (permissionsService.isElementRestricted(elementRestriction)) {
        if (isTransitionBySearchBar && !isToastAlreadyPresent) {
          toastService.addToast(toast);
        }

        let navigationTarget: string = previousState;
        if (isTransitionFromChooseAccount || isTransitionToHome || isTransitionBySearchBar) {
          navigationTarget = permissionsService.retrieveHomePageByRole();
        }

        return stateService.target(navigationTarget);
      }
    }
  };
}

export function RouteRestriction(routeRestriction: ElementRestriction): ElementRestriction {
  return routeRestriction;
}
