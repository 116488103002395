<div
  class="thumb grid-x align-middle"
  [ngClass]="['thumb--' + size, !image ? 'thumb--text' : '']"
  [style.background-image]="image ? 'url(' + image + ')' : null"
  [class.thumb--hover]="!disableHover"
>
  <ng-container *ngIf="text">
    <div class="thumb__bg"></div>
    <span class="cell thumb__text text-center">{{ text }}</span>
  </ng-container>
</div>
