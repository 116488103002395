import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ButtonModule } from '../../ui/button/button.module';
import { LinkModule } from '../../ui/link/link.module';
import { LoadingBarModule } from '../loading-bar/loading-bar.module';
import { LoadingModule } from '../loading/loading.module';
import { ModalModule } from '../modal/modal.module';
import { ItTargetGeneratorComponent } from './it-target-generator.component';

@NgModule({
  declarations: [ItTargetGeneratorComponent],
  imports: [CommonModule, LoadingBarModule, LinkModule, LoadingModule, ModalModule, ButtonModule],
  exports: [ItTargetGeneratorComponent],
})
export class ItTargetGeneratorModule {}
