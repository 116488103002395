<div class="grid-x requirements">
  <div
    class="cell large-6 requirements__single"
    *ngFor="let requirement of requirements"
    [class.requirements__single--fulfilled]="requirement.fulfilled"
  >
    <div class="requirements__check"></div>
    <small class="requirements__text">{{ requirement.text }}</small>
  </div>
</div>
