import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AccountInterface } from 'src/types/entities/account';
import { SubscriptionInterface } from 'src/types/entities/subscription';
import { UserSummaryInterface } from 'src/types/entities/user';
import { LocalStorageService } from '../local-storage.service';

interface CustomerlyCompanySettingsInterface {
  company_id?: string;
  created_at?: number;
  name?: string;
  plan?: string;
  planVersion?: number;
  'Company type'?: string;
}

interface CustomerlySettingsInterface {
  app_id?: string;
  name?: string;
  email?: string;
  email_hash?: string;

  attributes?: {
    'Current account'?: string;
    'Owned account'?: string;
  };
  company?: CustomerlyCompanySettingsInterface;
  companies?: CustomerlyCompanySettingsInterface[];
}

declare global {
  interface Window {
    customerly: {
      load: (settings?: CustomerlySettingsInterface) => void;
      update: (settings?: CustomerlySettingsInterface) => void;
      logout: () => void;
      hide: () => void;
      show: () => void;
    };
    customerlySettings: CustomerlySettingsInterface;
  }
}

@Injectable({
  providedIn: 'root',
})
export class CustomerlyService {
  private _booted: boolean = false;
  private _everBooted: boolean = false;

  private settings: CustomerlySettingsInterface = {
    app_id: environment.customerly.key,
  };

  get enabled() {
    return environment.customerly.enable && environment.customerly.key && !!window.customerly;
  }

  get booted() {
    return this._booted;
  }

  get everBooted() {
    return this._everBooted;
  }

  constructor(@Inject(DOCUMENT) private document: Document, private storage: LocalStorageService) {}

  start(user: UserSummaryInterface, account?: AccountInterface, subscription?: SubscriptionInterface) {
    const data = this.storage.getItem('user');

    if (data?.impersonification) {
      return;
    }

    if (!this.enabled) {
      return;
    }

    if (this.booted) {
      return;
    }

    this._booted = true;

    if (!this.everBooted) {
      window.customerly.load({
        ...this.settings,
        name: `${user.name} ${user.surname}`,
        email: user.email,
        email_hash: user.user_email_hash,
      });
      this._everBooted = true;
    } else {
      window.customerly.update({
        ...this.settings,
        name: `${user.name} ${user.surname}`,
        email: user.email,
        email_hash: user.user_email_hash,
      });
    }
    window.customerly.show();

    if (account) {
      this.updateAccounts(user, account, subscription);

      this.setCurrentAccount(user, account);
    }
  }

  setCurrentAccount(user: UserSummaryInterface, account: AccountInterface) {
    if (!this.enabled || !this.booted) {
      return;
    }

    window.customerly.update({
      ...this.settings,
      name: `${user.name} ${user.surname}`,
      email: user.email,
      email_hash: user.user_email_hash,
      attributes: {
        'Current account': `${account._id}`,
      },
    });
  }

  updateAccounts(user: UserSummaryInterface, account: AccountInterface, subscription?: SubscriptionInterface) {
    if (!this.enabled || !this.booted) {
      return;
    }

    if (!user.ownedAccount && !user.managedAccounts?.length) {
      return;
    }

    if (user.ownedAccount && account._id === user.ownedAccount._id) {
      this.updateCompany(user, {
        company_id: account._id,
        name: account.company.name,
        'Company type': account.company.type,
        ...(subscription ? { plan: subscription.plan.name, planVersion: subscription.plan.version } : {}),
      });
    } else {
      if (!user.managedAccounts?.length) {
        return;
      }

      this.updateCompanies(
        user,
        user.managedAccounts.map((x) => {
          // @ts-ignore
          if (x._id === account._id) {
            return {
              // @ts-ignore
              company_id: x._id,
              name: account.company.name,
              'Company type': account.company.type,
              ...(subscription ? { plan: subscription.plan.name, planVersion: subscription.plan.version } : {}),
            };
          }

          return {
            // @ts-ignore
            company_id: x._id,
          };
        })
      );
    }
  }

  updateCompany(user: UserSummaryInterface, company: CustomerlyCompanySettingsInterface) {
    if (!this.enabled || !this.booted) {
      return;
    }

    window.customerly.update({
      ...this.settings,
      name: `${user.name} ${user.surname}`,
      email: user.email,
      attributes: {
        'Owned account': `${company.company_id}`,
      },
      company: {
        ...company,
        company_id: `${company.company_id}`,
      },
    });
  }

  updateCompanies(user: UserSummaryInterface, companies: CustomerlyCompanySettingsInterface[]) {
    if (!this.enabled || !this.booted) {
      return;
    }

    window.customerly.update({
      ...this.settings,
      name: `${user.name} ${user.surname}`,
      email: user.email,
      companies: companies.map((company) => {
        return {
          ...company,
          company_id: `${company.company_id}`,
        };
      }),
    });
  }

  anonimize() {
    if (!this.enabled || !this.booted) {
      return;
    }

    this._booted = false;

    window.customerly.logout();
    window.customerly.update({
      ...this.settings,
    });
    window.customerly.hide();
  }

  updateNavigation() {
    if (!this.enabled || !this.booted) {
      return;
    }

    window.customerly.update({});
  }
}
