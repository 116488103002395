import { DifficultyInterface, DifficultyValueType } from 'src/types/components/pages/templates';
import { TemplateInterface } from 'src/types/entities/template';

export const difficulties: DifficultyInterface[] = [
  {
    index: 1,
    value: 'easy',
    label: 'Easy',
  },
  {
    index: 2,
    value: 'medium',
    label: 'Medium',
  },
  {
    index: 3,
    value: 'advanced',
    label: 'Advanced',
  },
];

export const getDifficulty = (template: TemplateInterface): DifficultyInterface => {
  let difficulty: number = 0;
  if (template.metadata?.difficulty !== undefined) {
    difficulty = template.metadata.difficulty - 1;
  }
  return difficulties[Math.min(2, Math.max(0, difficulty))];
};

export const parseMacroValue = (template: TemplateInterface): string => {
  const macro: string = String(template.metadata.macro) === '1' ? 'Random' : 'Sequential';
  return macro;
};

export const getDifficultyIndex = (value: DifficultyValueType[]): number => {
  return difficulties.find((x) => x.value === value[0])?.index || null;
};

export const mainCategoryContents: { [x: string]: { text: string; cover: string } } = {
  'product-visualization': {
    text: 'Give your customers realistic and true-to-size product previews to help them understand if your product meets their needs. The experience will be so enjoyable that they will keep coming back for more.',
    cover: 'assets/img/templates/categories/product-visualization.jpg',
  },
  'virtual-try-on': {
    text: 'Experience the perfect fit with our Virtual Try-On, giving your audience the confidence to make the right purchase. See overlaid 3D elements, making online shopping experience enjoyable and hassle-free.',
    cover: 'assets/img/templates/categories/virtual-try-on.jpg',
  },
  'interactive-print': {
    text: 'Bring static images to life with our Interactive Print templates. Watch videos, images and 3D assets spring from billboards, flyers and books, adding a captivating layer of engagement to traditional print media.',
    cover: 'assets/img/templates/categories/interactive-prints.jpg',
  },
  packaging: {
    text: 'Transform ordinary packaging into a memorable experience. Our innovative AR technology adds an interactive and informative layer to product packaging, providing added value and brand connection.',
    cover: 'assets/img/templates/categories/packaging.jpg',
  },
  'immersive-360': {
    text: 'Envelop your users in a 360° AR experience with our Immersive 360. Showcase content all around them and provide access to further material, creating a fully immersive starting point for your audience.',
    cover: 'assets/img/templates/categories/landing-pages.jpg',
  },
  'treasure-hunts': {
    text: 'Embark on exciting Treasure Hunts, with hidden items awaiting for discovery in various locations. Engage with surroundings and unlock exclusive rewards, providing a fun and interactive way to explore.',
    cover: 'assets/img/templates/categories/treasure-hunts.jpg',
  },
  'data-visualization': {
    text: 'Visualize complex information effortlessly with our immersive Data Visualization tool. Dive into interactive charts and graphs that make understanding data more accessible and engaging.',
    cover: 'assets/img/templates/categories/data-visualization.jpg',
  },
  'quizzes-and-surveys': {
    text: 'Gather insights and opinions in a fun and interactive way with our AR Quizzes and Surveys. Engage users with an immersive experience that encourages participation and boosts response rates.',
    cover: 'assets/img/templates/categories/quizzes-and-surveys.jpg',
  },
  'mini-games': {
    text: 'Dive into the world of Mini-Games, where captivating AR experiences await. Our templates offer immersive and challenging gameplay, providing entertainment for users while promoting brand engagement.',
    cover: 'assets/img/templates/categories/minigames.jpg',
  },
  'filters-and-effects': {
    text: 'Celebrate special events with our Filters & Effects, adding a touch of fun to your brand. Enhance user experiences with face filters and interactive elements, perfect for holidays, store openings, and more.',
    cover: 'assets/img/templates/categories/face-filters.jpg',
  },
  avatars: {
    text: 'Engage with your audience through our AR Avatars, bridging the gap between virtual and real world. Learn about products or simply connect with others through interactive storytelling and personalized guidance.',
    cover: 'assets/img/templates/categories/3d-avatars.jpg',
  },
};

export const getMainCategoryContent = (category: string) => {
  return mainCategoryContents[category] || { text: '', cover: '' };
};
