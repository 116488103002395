import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AccountService } from '../account.service';
import { UploadAccount } from './upload-account';

@Injectable({
  providedIn: 'root',
})
export class UploadImageTrackingTriggerService extends UploadAccount {
  constructor(protected httpClient: HttpClient, protected accountService: AccountService) {
    super('triggers', httpClient, accountService);
  }
}

export const UploadImageTrackingTriggerServiceProvider = {
  provide: 'image-tracking-trigger',
  deps: [HttpClient, AccountService],
  useFactory(httpClient: HttpClient, accountService: AccountService) {
    return new UploadImageTrackingTriggerService(httpClient, accountService);
  },
};
