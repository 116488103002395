import { HookMatchCriteria, TransitionHookFn, HookRegOptions, TransitionService } from '@uirouter/core';

export abstract class RouteGuard {
  abstract on:
    | 'onBefore'
    | 'onFinish'
    | 'onRetain'
    | 'onCreate'
    | 'onEnter'
    | 'onError'
    | 'onExit'
    | 'onStart'
    | 'onSuccess';
  abstract hook: HookMatchCriteria;
  abstract callback: TransitionHookFn;
  abstract options?: HookRegOptions;

  applyWith(transitionService: TransitionService) {
    transitionService[this.on](this.hook, this.callback, this.options || {});
  }
}
