import CMSEnvironment from 'src/types/entities/config';
import env from './env';

const environment: CMSEnvironment = {
  env: env.ENVIRONMENT as 'development' | 'staging' | 'production',
  campaignBaseUrl: env.CAMPAIGN_BASE_URL,
  doubleAuthenticationLayer: env.DOUBLE_AUTHENTICATION_LAYER,
  localStorageKey: env.LOCAL_STORAGE_KEY,
  sessionStorageKey: env.SESSION_STORAGE_KEY,
  zendeskBaseUrl: 'https://aryelhq.zendesk.com/api/v2/help_center',
  api: {
    protocol: env.API_PROTOCOL,
    domain: env.API_DOMAIN,
    port: env.API_PORT,
    basePath: env.API_BASEPATH,
    fullPath: null,
    baseUploadsPath: '',
    fullUploadsPath: null,
    path: null,
  },
  stripe: {
    publicKey: env.STRIPE_PUBLIC_KEY,
  },
  recaptcha: {
    v3: env.RECAPTCHA_V3_PUBLIC,
  },
  sentry: {
    dsn: env.SENTRY_DSN,
  },
  embedViewerScriptUrl: env.EMBED_VIEWER_SCRIPT_URL,
  templatesCategoriesIconsBaseUrl: env.TEMPLATES_CATEGORIES_ICONS_BASE_URL,
  customerly: {
    enable: env.CUSTOMERLY_ENABLE,
    key: env.CUSTOMERLY_KEY,
  },
  plugins: {
    prestashop: {
      key: env.ADMIN_PANEL_PLUGIN_PRESTASHOP,
    },
  },
};

const pathBuilder = (config: CMSEnvironment): { path: string; fullPath: string; fullUploadsPath: string } => {
  const { protocol, domain, port, basePath, baseUploadsPath } = config.api;

  const getBasePath = () => (basePath[0] === '/' ? basePath : '/' + basePath);
  const getBaseUploadsPath = () => (baseUploadsPath[0] === '/' ? baseUploadsPath : '/' + baseUploadsPath);

  if (port === 80 || port === 443) {
    config.api.path = `${protocol}://${domain}`;
    config.api.fullPath = `${protocol}://${domain}${getBasePath()}`;
    config.api.fullUploadsPath = `${protocol}://${domain}${getBaseUploadsPath()}`;
  } else {
    config.api.path = `${protocol}://${domain}:${port}`;
    config.api.fullPath = `${protocol}://${domain}:${port}${getBasePath()}`;
    config.api.fullUploadsPath = `${protocol}://${domain}:${port}${getBaseUploadsPath()}`;
  }

  return {
    path: config.api.path,
    fullPath: config.api.fullPath,
    fullUploadsPath: config.api.fullUploadsPath,
  };
};

const paths = pathBuilder(environment);

environment.api = {
  ...environment.api,
  ...paths,
};

export { environment };
