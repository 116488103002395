import { Injectable } from '@angular/core';
import { AccountService } from 'src/app/services/account.service';
import { MeService } from 'src/app/services/me.service';
import {
  isHighRestrictedElement,
  isLowRestrictedElement,
  isPartialRestrictedElement,
  user,
} from '../../compounds/permissions/role-check';

export enum ElementRestriction {
  HIGH = 'high',
  PARTIAL = 'partial',
  LOW = 'low',
  NO = 'no',
}

@Injectable({
  providedIn: 'root',
})
export class PermissionsService {
  constructor(private meService: MeService, private accountService: AccountService) {}

  public isElementRestricted(elementRestriction: ElementRestriction): boolean {
    switch (elementRestriction) {
      case 'high':
        return isHighRestrictedElement(this.meService?.me?._id, this.accountService?.currentAccount);
      case 'partial':
        return isPartialRestrictedElement(this.meService?.me?._id, this.accountService?.currentAccount);
      case 'low':
        return isLowRestrictedElement(this.meService?.me?._id, this.accountService?.currentAccount);
      default:
        return false;
    }
  }

  public retrieveHomePageByRole(): string {
    const role: string = user(this.meService?.me?._id, this.accountService?.currentAccount)?.role;

    switch (role) {
      case 'viewer':
        return 'private.reports';
      case 'author':
        return 'private.campaigns.index';
      default:
        return 'private.home';
    }
  }
}
