export type AryelFeature =
  | 'marker-based'
  | 'image-tracking'
  | 'geolocation'
  | 'surface'
  | 'face'
  | 'face-v2-beta-access'
  | '360'
  | 'scenes-managment'
  | 'multiple-triggers'
  | 'import-export-campaign'
  | 'active-campaigns'
  | 'admin-access'
  | 'views'
  | 'campaign-templates'
  | 'premium-campaign-templates'
  | 'premium-ready-to-use'
  | 'markers-limit'
  | 'custom-markers'
  | 'custom-branding'
  | 'storage'
  | 'custom-domains'
  | 'collaborators'
  | 'knowledge-base'
  | 'onboarding'
  | 'email-standard'
  | 'email-priority'
  | 'basic-reports'
  | 'data-export'
  | 'goal-conversion'
  | 'realtime-analytics'
  | 'white-label-pdf-reports'
  | 'powerups'
  | 'api-access'
  | 'remove-powered-by'
  | 'embed-campaign'
  | 'edit-slug'
  | 'disable-launcher'
  | 'variables'
  | 'brandkits';

export const FEATURES: AryelFeature[] = [
  'marker-based',
  'image-tracking',
  'geolocation',
  'surface',
  'face',
  'face-v2-beta-access',
  '360',
  'scenes-managment',
  'multiple-triggers',
  'import-export-campaign',
  'active-campaigns',
  'admin-access',
  'views',
  'campaign-templates',
  'premium-campaign-templates',
  'premium-ready-to-use',
  'markers-limit',
  'custom-markers',
  'custom-branding',
  'storage',
  'custom-domains',
  'collaborators',
  'knowledge-base',
  'onboarding',
  'email-standard',
  'email-priority',
  'basic-reports',
  'data-export',
  'goal-conversion',
  'realtime-analytics',
  'white-label-pdf-reports',
  'powerups',
  'api-access',
  'remove-powered-by',
  'embed-campaign',
  'edit-slug',
  'disable-launcher',
  'variables',
  'brandkits',
];

export type AryelFeatureType = 'number' | 'boolean';
export const FEATURE_TYPES = ['number', 'boolean'];

export interface AryelFeatureInterface {
  _id: AryelFeature;
  name: string;
  description: string;
  category: string;
  type: AryelFeatureType;
  proration?: boolean;
}
