<div
  *ngIf="isOpen"
  class="modal"
  [class.modal--lock]="isAnimating"
  [class.modal--expand]="isExpanded"
  [class.modal--animations]="animations"
  [ngClass]="['modal--' + type, 'modal--' + accent]"
>
  <div
    [@content]
    (@content.start)="isAnimating = true"
    (@content.done)="isAnimating = false"
    class="modal__content"
    [ngClass]="contentClasses"
    [ngStyle]="contentStyles"
    [class.modal__content--new-size-system]="
      ['l', 's', 'xl', 'm'].includes(size)
    "
    [class.modal__content--heading-disable]="disableHeading"
  >
    <div
      *ngIf="heading; else closerTemplate"
      class="modal__heading grid-x align-middle"
    >
      <div class="cell">
        <h2 [ngClass]="['text-' + headingAlign]">
          {{ heading }}
        </h2>
      </div>
      <ng-container [ngTemplateOutlet]="closerTemplate"></ng-container>
    </div>
    <div class="modal__body" [ngStyle]="{ 'text-align': bodyAlign }">
      <aryel-loading [loading]="isLoading">
        <ng-content></ng-content>
      </aryel-loading>
    </div>
  </div>
  <div
    [@overlay]
    (click)="!isPersistent ? close() : null"
    class="modal__overlay"
    [class.pointer]="!isPersistent"
  ></div>
</div>

<ng-template #closerTemplate>
  <div
    *ngIf="!isClosedDisabled"
    class="modal__closer"
    [ngClass]="'modal__closer--' + closerColor"
    (click)="close()"
  >
    <div></div>
  </div>
</ng-template>
