import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { FormFieldModule } from '../../form/form-field/form-field.module';
import { ModalModule } from '../../layout/modal/modal.module';
import { ButtonModule } from '../../ui/button/button.module';
import { CampaignFromBlankModalComponent } from './campaign-from-blank-modal.component';

@NgModule({
  declarations: [CampaignFromBlankModalComponent],
  imports: [CommonModule, ModalModule, ButtonModule, FormFieldModule, FormsModule],
  exports: [CampaignFromBlankModalComponent],
})
export class CampaignFromBlankModalModule {}
