import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Feature, FeatureItemInterface } from 'src/types/entities/feature';
import { AccountService } from '../account.service';
import { ApiService } from '../api/api.service';
import { Restify } from '../api/restify';
import { LocalStorageService } from '../local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class FeatureService extends Restify<FeatureItemInterface> {
  get availableFeatures(): FeatureItemInterface[] {
    return this.localStorageService.getItem('availableFeatures') || null;
  }

  interpolate() {
    return this._basePath.replace(':account', this.accountService.currentAccount._id);
  }

  constructor(
    protected apiService: ApiService,
    protected accountService: AccountService,
    private localStorageService: LocalStorageService
  ) {
    super('/accounts/:account/features', apiService);
  }

  setAvailableFeatures(): Observable<FeatureItemInterface[]> {
    return this.index().pipe(
      tap((features: FeatureItemInterface[]) => {
        this.localStorageService.setItem('availableFeatures', features);
        return features;
      })
    );
  }

  refreshAvailableFeatures() {
    if (this.accountService.currentAccount) {
      return this.setAvailableFeatures();
    }
    return of(null);
  }

  clearAvailableFeatures() {
    return this.localStorageService.removeItem('availableFeatures');
  }

  feature<T extends number | boolean | string[]>(feature: Feature): T | null {
    return (this.availableFeatures?.find((p) => p.feature === feature)?.value as T) || null;
  }
}
